<template>
  <div style="font-size: 0.9rem">
    <!-- card utama-->
    <div class="row font-weight-bolder">
      <div class="col-xl-12">
        <b-card>
          <b-container fluid>
            <b-row>
              <b-col cols="3">
                <b-form-group label-size="sm" label="Stasiun" label-for="select-station">
                  <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%;"
                    :value="$v.t_station.$model.value" 
                    @change="(value) => ($v.t_station.$model = t_station_opt.find((item) => item.value === value))"
                    id="select-station" :class="{ ...ASelectValidate($v.t_station), std: true }">
                    <a-select-option v-for="opt in t_station_opt" :key="opt.text + opt.value" :value="opt.value"> {{
                      opt.text
                    }} </a-select-option>
                  </a-select>
                </b-form-group>
              </b-col>

              <b-col>
                <b-form-group label-size="sm" label="WMO ID" label-for="input-wmo">
                  <b-form-input id="input-wmo" v-model="t_wmoid" size="sm" class="std" disabled />
                </b-form-group>
              </b-col>

              <b-col>
                <b-form-group label-size="sm" label="Nama Observer" label-for="select-observer">
                  <a-select show-search :filter-option="filterOption" style="width: 100%;"
                    :value="$v.t_observer.$model" @change="(value) => ($v.t_observer.$model = value)"
                    id="select-observer" :class="{ ...ASelectValidate($v.t_observer), std:true }" :loading="fetching">
                    <a-select-option v-for="opt in t_observer_opt" :key="opt.observer + opt.id" :value="opt.id"> {{
                      opt.observer
                    }} </a-select-option>
                    <template #notFoundContent>
                      <SelectNotFoundSlot />
                    </template>
                    <!-- <template #notFoundContent>
                      <span class="d-flex justify-content-center" style="font-weight:600;">Sorry, no matching options.</span>
                    </template> -->
                  </a-select>
                </b-form-group>
              </b-col>

              <b-col>
                <b-form-group label-size="sm" label="Tanggal" label-for="input-datepicker">
                  <b-form-datepicker id="input-datepicker" :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }" locale="id" size="sm" placeholder="Tanggal" :state="validateState($v.tanggal)"
                    v-model="$v.tanggal.$model" :max="max" class="std"/>
                </b-form-group>
              </b-col>

              <b-col>
                <b-form-group label-size="sm" label="Jam" label-for="input-jam">
                  <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%;"
                    :value="$v.jam.$model" @change="(value) => ($v.jam.$model = value)" id="input-jam"
                    :class="{ ...ASelectValidate($v.jam), std:true }">
                    <!-- <a-select-option value="">-</a-select-option> -->
                    <a-select-option :disabled="timeWSOption(0)" value="00:00">00:00</a-select-option>
                    <a-select-option :disabled="timeWSOption(1)" value="01:00">01:00</a-select-option>
                    <a-select-option :disabled="timeWSOption(2)" value="02:00">02:00</a-select-option>
                    <a-select-option :disabled="timeWSOption(3)" value="03:00">03:00</a-select-option>
                    <a-select-option :disabled="timeWSOption(4)" value="04:00">04:00</a-select-option>
                    <a-select-option :disabled="timeWSOption(5)" value="05:00">05:00</a-select-option>
                    <a-select-option :disabled="timeWSOption(6)" value="06:00">06:00</a-select-option>
                    <a-select-option :disabled="timeWSOption(7)" value="07:00">07:00</a-select-option>
                    <a-select-option :disabled="timeWSOption(8)" value="08:00">08:00</a-select-option>
                    <a-select-option :disabled="timeWSOption(9)" value="09:00">09:00</a-select-option>
                    <a-select-option :disabled="timeWSOption(10)" value="10:00">10:00</a-select-option>
                    <a-select-option :disabled="timeWSOption(11)" value="11:00">11:00</a-select-option>
                    <a-select-option :disabled="timeWSOption(12)" value="12:00">12:00</a-select-option>
                    <a-select-option :disabled="timeWSOption(13)" value="13:00">13:00</a-select-option>
                    <a-select-option :disabled="timeWSOption(14)" value="14:00">14:00</a-select-option>
                    <a-select-option :disabled="timeWSOption(15)" value="15:00">15:00</a-select-option>
                    <a-select-option :disabled="timeWSOption(16)" value="16:00">16:00</a-select-option>
                    <a-select-option :disabled="timeWSOption(17)" value="17:00">17:00</a-select-option>
                    <a-select-option :disabled="timeWSOption(18)" value="18:00">18:00</a-select-option>
                    <a-select-option :disabled="timeWSOption(19)" value="19:00">19:00</a-select-option>
                    <a-select-option :disabled="timeWSOption(20)" value="20:00">20:00</a-select-option>
                    <a-select-option :disabled="timeWSOption(21)" value="21:00">21:00</a-select-option>
                    <a-select-option :disabled="timeWSOption(22)" value="22:00">22:00</a-select-option>
                    <a-select-option :disabled="timeWSOption(23)" value="23:00">23:00</a-select-option>
                  </a-select>
                </b-form-group>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </div>
    </div>

    <div v-if="filter_complete">
      <!-- card 1 -->
      <b-row class="mt-n1 mb-1">
        <b-col cols="3">
          <b-card class="h-65">
            <b-row>
              <b-col cols="12">
                <h5 class="mb-1 badge badge-light-dark">ANGIN</h5>
              </b-col>
            </b-row>
            <b-collapse visible id="collapse-row-1">
              <b-row class="mb-1">
                <b-col sm="7">
                  <label>Pengenal Data Angin (iw) <feather-icon icon="HelpCircleIcon" size="12"
                      class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                      title=" menjelaskan pengamatan kecepatan angin dan satuannya" /></label>
                </b-col>
                <b-col sm="5">
                  <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                    :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                    :value="$v.input_data.wind_indicator_iw.$model"
                    @change="(value) => ($v.input_data.wind_indicator_iw.$model = value)"
                    :class="{ ...ASelectValidate($v.input_data.wind_indicator_iw) }"
                    id="wind_indicator_iw">
                    <!-- <a-select-option value="">-</a-select-option> -->
                    <a-select-option v-for="opt in options.wind_indicator_iw" :key="opt.text + opt.value"
                      :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                  </a-select>
                </b-col>
              </b-row>

              <b-row class="mb-1">
                <b-col sm="7" class="pr-0">
                  <label for="wind_dir_deg_dd">Arah Angin (derajat) <feather-icon icon="HelpCircleIcon" size="12"
                      class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                      title="Arah angin dalam derajad penuh, jika calm input dengan '0' " /></label>
                </b-col>
                <b-col sm="5">
                  <b-form-input :class="{ 'text-center': true, rangecheck: rangecheck_status('wind_dir_deg_dd') }"
                    :state="validateState($v.input_data.wind_dir_deg_dd)"
                    v-model.number="$v.input_data.wind_dir_deg_dd.$model" type="number" style="margin-bottom: 5px"
                    size="sm" id="wind_dir_deg_dd" @keydown="formatInteger"></b-form-input>
                  <b-tooltip v-if="form_status('wind_dir_deg_dd').status" target="wind_dir_deg_dd" triggers="hover">
                    {{ form_status("wind_dir_deg_dd").message }}
                  </b-tooltip>
                  <!-- tooltips GC -->
                  <b-tooltip v-if="!form_status('wind_dir_deg_dd').status" :show="validateTooltipStateInput_data('wind_dir_deg_dd')" :triggers="'hover'"
                    target="wind_dir_deg_dd" placement="top" custom-class="validation-tooltip">
                    {{ tooltipMessage("input_data_wind_dir_deg_dd") }}
                  </b-tooltip>
                </b-col>
              </b-row>

              <b-row>
                <b-col sm="7" class="pr-0">
                  <label for="wind_speed_ff">
                    Kecepatan Angin (knot)
                    <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white"
                      v-b-tooltip.hover.v-dark
                      title="kecepatan dalam satuan knot dengan bilangan bulat, jika calm input dengan '0' " /></label>
                </b-col>
                <b-col sm="5">
                  <b-form-input :class="{ 'text-center': true, rangecheck: rangecheck_status('wind_speed_ff') }"
                    :state="validateState($v.input_data.wind_speed_ff)"
                    v-model.number="$v.input_data.wind_speed_ff.$model" type="number" size="sm" id="wind_speed_ff"
                    @keyup="checkValidate($event, 'wind_speed_ff')" @keydown="formatInteger"></b-form-input>
                  <b-tooltip v-if="form_status('wind_speed_ff').status" target="wind_speed_ff" triggers="hover">
                    {{ form_status("wind_speed_ff").message }}
                  </b-tooltip>

                  <b-tooltip v-if="!form_status('wind_speed_ff').status" :show="validateTooltipStateInput_data('wind_speed_ff')" :triggers="'hover'"
                    target="wind_speed_ff" placement="top" custom-class="validation-tooltip">
                    {{ tooltipMessage("input_data_wind_speed_ff") }}
                  </b-tooltip>
                </b-col>
              </b-row>
            </b-collapse>
          </b-card>

          <b-collapse visible id="collapse-row-1" class="h-50">
            <b-card class="">
              <b-row>
                <b-col sm="7">
                  <label for="visibility_vv">Jarak penglihatan mendatar (km)</label>
                </b-col>
                <b-col sm="5">
                  <b-form-input :class="{ 'text-center': true, rangecheck: rangecheck_status('visibility_vv') }"
                    :state="validateState($v.input_data.visibility_vv)"
                    v-model.number="$v.input_data.visibility_vv.$model" type="number" size="sm" id="visibility_vv"
                    v-on:keydown="onKeyCheck($event)"
                    @keyup="checkValidate(formatPersepuluhan($event), 'visibility_vv')" step="0.1" lang="en" />
                  <b-tooltip v-if="form_status('visibility_vv').status" target="visibility_vv" triggers="hover">
                    {{ form_status("visibility_vv").message }}
                  </b-tooltip>

                  <b-tooltip v-if="!form_status('visibility_vv').status" :show="validateTooltipStateInput_data('visibility_vv')" :triggers="'Hover'"
                    target="visibility_vv" placement="top" custom-class="validation-tooltip">
                    {{ tooltipMessage("input_data_visibility_vv") }}
                  </b-tooltip>
                </b-col>
              </b-row>
            </b-card>
          </b-collapse>
        </b-col>

        <!-- end card1 -->
        <!-- card 2 -->
        <b-col cols="3">
          <b-card class="h-100">
            <b-row>
              <b-col cols="10">
                <h5 class="mb-1 badge badge-light-dark">CUACA</h5>
              </b-col>
            </b-row>
            <b-collapse visible id="collapse-row-1">
              <b-row class="mb-1">
                <b-col sm="12">
                  <label for="input-cuaca-ix">Pengenal Data Cuaca (ix) <feather-icon icon="HelpCircleIcon" size="12"
                      class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                      title="Pengenal data cuaca" /></label>
                  <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                    :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                    :value="$v.input_data.weather_indicator_ix.$model"
                    @change="(value) => ($v.input_data.weather_indicator_ix.$model = value)"
                    :class="{ ...ASelectValidate($v.input_data.weather_indicator_ix) }" 
                    disabled
                    :showArrow="false"
                    id="input-cuaca-ix">
                    <!-- <a-select-option value="-">-</a-select-option> -->
                    <a-select-option v-for="opt in options.weather_indicator_ix" :key="opt.text + opt.value"
                      :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                  </a-select>
                </b-col>
              </b-row>
              <b-row class="mb-1">
                <b-col sm="12">
                  <label for="present_weather_ww">
                    Cuaca Saat Pengamatan (ww) <feather-icon icon="HelpCircleIcon" size="12"
                      class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                      title="kejadian cuaca saat pengamatan" /></label>
                  <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                    :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                    :value="$v.input_data.present_weather_ww.$model"
                    @change="(value) => ($v.input_data.present_weather_ww.$model = value)"
                    :class="{ rangecheck: rangecheck_status('present_weather_ww'), ...ASelectValidate($v.input_data.present_weather_ww) }"
                    id="present_weather_ww">
                    <!-- <a-select-option value="-">-</a-select-option> -->
                    <a-select-option v-for="opt in options.present_weather_ww" :key="opt.text + opt.value"
                      :value="opt.value"> {{ padZero(opt.value, 2) }} - {{ opt.text }} </a-select-option>
                  </a-select>
                  <b-tooltip v-if="form_status('present_weather_ww').status" target="present_weather_ww"
                    triggers="hover">
                    {{ form_status("present_weather_ww").message }}
                  </b-tooltip>
                </b-col>
              </b-row>

              <b-row class="mb-1">
                <b-col sm="12">
                  <label for="past_weather_w1">Cuaca yang lalu (W1) <feather-icon icon="HelpCircleIcon" size="12"
                      class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                      title="Kejadian cuaca yang lalu 1" /></label>
                  <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                    :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                    :value="$v.input_data.past_weather_w1.$model"
                    @change="(value) => ($v.input_data.past_weather_w1.$model = value)"
                    :class="{ rangecheck: rangecheck_status('past_weather_w1'), ...ASelectValidate($v.input_data.past_weather_w1) }"
                    id="past_weather_w1">
                    <!-- <a-select-option value="-">-</a-select-option> -->
                    <a-select-option v-for="opt in options.past_weather_w1" :key="opt.text + opt.value"
                      :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                  </a-select>
                  <b-tooltip v-if="form_status('past_weather_w1').status" target="past_weather_w1" triggers="hover">
                    {{ form_status("past_weather_w1").message }}
                  </b-tooltip>
                </b-col>
              </b-row>

              <b-row>
                <b-col sm="12">
                  <label for="past_weather_w2">Cuaca yang lalu (W2) <feather-icon icon="HelpCircleIcon" size="12"
                      class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                      title="Kejadian cuaca yang lalu 2" /></label>
                  <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                    :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                    :value="$v.input_data.past_weather_w2.$model"
                    @change="(value) => ($v.input_data.past_weather_w2.$model = value)"
                    :class="{ rangecheck: rangecheck_status('past_weather_w2'), ...ASelectValidate($v.input_data.past_weather_w2) }"
                    id="past_weather_w2">
                    <!-- <a-select-option value="-">-</a-select-option> -->
                    <a-select-option v-for="opt in options.past_weather_w2" :key="opt.text + opt.value"
                      :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                  </a-select>
                  <b-tooltip v-if="form_status('past_weather_w2').status" target="past_weather_w2" triggers="hover">
                    {{ form_status("past_weather_w2").message }}
                  </b-tooltip>
                </b-col>
              </b-row>
              <b-row class="ml-1 mt-1">
                <h5 class="mb-1 badge badge-light-dark">IR = {{ rainfall_indicator_ir }}</h5>
              </b-row>
            </b-collapse>
          </b-card>
        </b-col>
        <!-- end card2 -->
        <!-- card 3 -->
        <b-col cols="3">
          <b-card class="h-100">
            <b-row>
              <b-col cols="12">
                <h5 class="mb-1 badge badge-light-dark">TEKANAN</h5>
              </b-col>
            </b-row>

            <b-collapse visible id="collapse-row-1">
              <b-row class="mb-1">
                <b-col sm="12">
                  <label for="#" class="font-weight-bolder badge badge-light-primary">Barometer Air Raksa</label>
                </b-col>
              </b-row>

              <b-row class="my-1">
                <b-col sm="7" class="pr-0 small">
                  <label for="input-derajat">Derajat Panas (&#8451;)
                    <feather-icon icon="HelpCircleIcon" size="12"
                      class="bg-primary rounded-circle text-white text-white" v-b-tooltip.hover.v-dark
                      title="Nilai suhu yang ditunjukkan dari thermometer pada barometer air raksa, input nilai sampai persepuluhan" /></label>
                </b-col>
                <b-col sm="5">
                  <b-form-input id="input-derajat" :state="validateState($v.input_data.pressure_temp_c)"
                    v-model.number="$v.input_data.pressure_temp_c.$model" class="text-center" size="sm"
                    :disabled="disabled.pressure_temp_c"
                    @keyup="checkValidate(formatPersepuluhan($event), 'pressure_temp_c')"
                    v-on:keydown="onKeyCheck($event)"></b-form-input>
                </b-col>
              </b-row>

              <b-row class="my-1">
                <b-col sm="7" class="pr-0 small">
                  <label for="input-tekanan">Tekanan dibaca (mb)
                    <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white"
                      v-b-tooltip.hover.v-dark
                      title="Nilai tekanan yang dibaca pada barometer air raksa, input nilai sampai persepuluhan dengan satuan mb" /></label>
                </b-col>
                <b-col sm="5">
                  <b-form-input id="input-tekanan" :state="validateState($v.input_data.pressure_reading_mb)"
                    v-model.number="$v.input_data.pressure_reading_mb.$model" class="text-center" size="sm"
                    :disabled="disabled.pressure_reading_mb" @keyup="formatPersepuluhan"
                    v-on:keydown="onKeyCheck($event)"></b-form-input>
                </b-col>
              </b-row>

              <b-row class="mb-1">
                <b-col sm="12">
                  <label for="#" class="font-weight-bolder badge badge-light-primary">Barometer Digital</label>
                </b-col>
              </b-row>

              <b-row class="my-1">
                <b-col sm="7">
                  <label for="input_qff">
                    Tekanan QFF <feather-icon icon="HelpCircleIcon" size="12"
                      class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                      title="Tekanan udara pada permukaan laut dengan satuan mb, input nilai sampai persepuluhan" /></label>
                </b-col>
                <b-col sm="5">
                  <span id="input_qff_wrapper">
                    <b-form-input id="input_qff" :state="validateState($v.input_data.pressure_qff_mb_derived)"
                      v-model.number="$v.input_data.pressure_qff_mb_derived.$model" class="text-center" size="sm"
                      :disabled="disabled.pressure_qff_mb_derived" @keyup="formatPersepuluhan"
                      v-on:keydown="onKeyCheck($event)"
                      :class="{ stepcheck: !consistency_check_tooltip('pressure_qff_mb_derived').status && stepcheck_status('pressure_qff_mb_derived'), 
                                rangecheck: rangecheck_status('pressure_qff_mb_derived') }">
                    </b-form-input>
                  </span>
                  
                  <!-- <b-tooltip v-if="consistency_check_tooltip('pressure_qff_mb_derived').status"
                    target="input_qff_wrapper" triggers="hover">
                    {{ consistency_check_tooltip("pressure_qff_mb_derived").message }}
                  </b-tooltip>
                  <b-tooltip v-if="!consistency_check_tooltip('pressure_qff_mb_derived').status && !form_status('pressure_qff_mb_derived').status"
                    :show="validateTooltipStateInput_data('input_qff')" :triggers="'hover'" target="input_qff"
                    placement="top" custom-class="validation-tooltip">
                    {{ tooltipMessage("input_data_input_qff") }}
                  </b-tooltip>
                  <b-tooltip v-if="!consistency_check_tooltip('pressure_qff_mb_derived').status && form_status('pressure_qff_mb_derived').status"
                    target="input_qff" triggers="hover">
                    {{ form_status("pressure_qff_mb_derived").message }}
                  </b-tooltip> -->

                  <b-tooltip v-if="!grosscheck_status('pressure_qff_mb_derived').status" target="input_qff" triggers="hover">
                    {{ tooltipMessage("input_data_input_qff") }}
                  </b-tooltip>  
                  <b-tooltip v-else-if="consistencycheck_status('pressure_qff_mb_derived')" target="input_qff" triggers="hover">
                    {{ consistency_check_tooltip("pressure_qff_mb_derived").message }}
                  </b-tooltip>      
                  <b-tooltip v-else-if="rangecheck_status('pressure_qff_mb_derived')"  target="input_qff" triggers="hover">
                    {{ form_status("pressure_qff_mb_derived", "rangecheck").message }}
                  </b-tooltip>        
                  <b-tooltip v-else-if="stepcheck_status('pressure_qff_mb_derived')" target="input_qff" triggers="hover">
                    {{ form_status("pressure_qff_mb_derived", "stepcheck").message }}
                  </b-tooltip>
                  <b-tooltip v-else :triggers="hover" target="input_qff" placement="top" custom-class="validation-tooltip">
                    {{ tooltipMessage("input_data_input_qff") }}
                  </b-tooltip>
                </b-col>
              </b-row>

              <b-row class="my-1">
                <b-col sm="7 ">
                  <label for="input_qfe">
                    Tekanan QFE
                    <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white"
                      v-b-tooltip.hover.v-dark
                      title="Tekanan udara pada permukaan stasiun dengan satuan mb, input nilai sampai persepuluhan" /></label>
                </b-col>
                <b-col sm="5">
                  <span id="input_qfe_wrapper">
                    <b-form-input id="input_qfe" :state="validateState($v.input_data.pressure_qfe_mb_derived)"
                      v-model.number="$v.input_data.pressure_qfe_mb_derived.$model" class="text-center" size="sm"
                      :disabled="disabled.pressure_qfe_mb_derived" @keyup="formatPersepuluhan"
                      v-on:keydown="onKeyCheck($event)"
                      :class="{ stepcheck: !consistency_check_tooltip('pressure_qfe_mb_derived').status && stepcheck_status('pressure_qfe_mb_derived'),
                                rangecheck: rangecheck_status('pressure_qfe_mb_derived') }">
                    </b-form-input>
                  </span>

                  <!-- <b-tooltip v-if="consistency_check_tooltip('pressure_qfe_mb_derived').status"
                    target="input_qfe_wrapper" triggers="hover">
                    {{ consistency_check_tooltip("pressure_qfe_mb_derived").message }}
                  </b-tooltip>
                  <b-tooltip v-if="!consistency_check_tooltip('pressure_qfe_mb_derived').status && !form_status('pressure_qfe_mb_derived').status"
                    :show="validateTooltipStateInput_data('input_qfe')" :triggers="'hover'" target="input_qfe"
                    placement="top" custom-class="validation-tooltip">
                    {{ tooltipMessage("input_data_input_qfe") }}
                  </b-tooltip>
                  <b-tooltip
                    v-if="!consistency_check_tooltip('pressure_qfe_mb_derived').status && form_status('pressure_qfe_mb_derived').status"
                    target="input_qfe" triggers="hover">
                    {{ form_status("pressure_qfe_mb_derived").message }}
                  </b-tooltip> -->

                  <b-tooltip v-if="!grosscheck_status('pressure_qfe_mb_derived').status" target="input_qfe" triggers="hover">
                    {{ tooltipMessage("input_data_input_qfe") }}
                  </b-tooltip>  
                  <b-tooltip v-else-if="consistencycheck_status('pressure_qfe_mb_derived')" target="input_qfe" triggers="hover">
                    {{ consistency_check_tooltip("pressure_qfe_mb_derived").message }}
                  </b-tooltip>      
                  <b-tooltip v-else-if="rangecheck_status('pressure_qfe_mb_derived')"  target="input_qfe" triggers="hover">
                    {{ form_status("pressure_qfe_mb_derived", "rangecheck").message }}
                  </b-tooltip>        
                  <b-tooltip v-else-if="stepcheck_status('pressure_qfe_mb_derived')" target="input_qfe" triggers="hover">
                    {{ form_status("pressure_qfe_mb_derived", "stepcheck").message }}
                  </b-tooltip>
                  <b-tooltip v-else :triggers="hover" target="input_qfe" placement="top" custom-class="validation-tooltip">
                    {{ tooltipMessage("input_data_input_qfe") }}
                  </b-tooltip>
                  
                </b-col>
              </b-row>
            </b-collapse>
          </b-card>
        </b-col>
        <!-- end card3 -->
        <!-- card 4 -->
        <b-col cols="3">
          <b-card class="h-100">
            <b-row>
              <b-col cols="10">
                <h5 class="mb-1 badge badge-light-dark">SUHU</h5>
              </b-col>
              <b-col cols="2">
                <feather-icon v-b-toggle.collapse-row-1 icon="ChevronDownIcon" tabindex="-1"/>
              </b-col>
            </b-row>
            <b-collapse visible id="collapse-row-1">
              <b-row class="mb-1">
                <b-col sm="8">
                  <label for="temp_drybulb_c_tttttt">
                    Suhu Bola Kering (&#8451;) <feather-icon icon="HelpCircleIcon" size="12"
                      class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                      title="Nilai suhu udara bola kering, input nilai sampai persepuluhan" /></label>
                </b-col>
                <b-col sm="4">
                  <b-form-input id="temp_drybulb_c_tttttt" :state="validateState($v.input_data.temp_drybulb_c_tttttt)"
                    v-model.number="$v.input_data.temp_drybulb_c_tttttt.$model" size="sm" class="text-center"
                    @keyup="formatPersepuluhan"
                    :class="{ rangecheck: rangecheck_status('temp_drybulb_c_tttttt'), stepcheck: stepcheck_status('temp_drybulb_c_tttttt') }">
                  </b-form-input>
                  <b-tooltip v-if="!grosscheck_status('temp_drybulb_c_tttttt').status"
                    target="temp_drybulb_c_tttttt" triggers="hover">
                    {{ tooltipMessage("input_data_temp_drybulb_c_tttttt") }}
                  </b-tooltip>  
                  <b-tooltip v-else-if="consistencycheck_status('temp_drybulb_c_tttttt')"
                    target="temp_drybulb_c_tttttt" triggers="hover">
                    {{ consistency_check_tooltip("temp_drybulb_c_tttttt").message }}
                  </b-tooltip>      
                  <b-tooltip v-else-if="rangecheck_status('temp_drybulb_c_tttttt')"  target="temp_drybulb_c_tttttt" triggers="hover">
                    {{ form_status("temp_drybulb_c_tttttt", "rangecheck").message }}
                  </b-tooltip>        
                  <b-tooltip v-else-if="stepcheck_status('temp_drybulb_c_tttttt')" target="temp_drybulb_c_tttttt" triggers="hover">
                    {{ form_status("temp_drybulb_c_tttttt", "stepcheck").message }}
                  </b-tooltip>      
                  <!-- tooltips GC -->
                  <!-- <b-tooltip v-else-if="!form_status('temp_drybulb_c_tttttt').status && !consistency_check_tooltip('temp_drybulb_c_tttttt').status"
                    :show="validateTooltipStateInput_data('temp_drybulb_c_tttttt')" :triggers="'hover'"
                    target="temp_drybulb_c_tttttt" placement="top" custom-class="validation-tooltip">
                    {{ tooltipMessage("input_data_temp_drybulb_c_tttttt") }}
                  </b-tooltip>
                  <b-tooltip v-else-if="form_status('temp_drybulb_c_tttttt').status && !consistency_check_tooltip('temp_drybulb_c_tttttt').status" target="temp_drybulb_c_tttttt"
                    triggers="hover">
                    {{ form_status("temp_drybulb_c_tttttt").message }}
                  </b-tooltip> -->
                  <b-tooltip v-else :triggers="hover" target="temp_drybulb_c_tttttt" placement="top" custom-class="validation-tooltip">
                    {{ tooltipMessage("input_data_temp_drybulb_c_tttttt") }}
                  </b-tooltip>
                </b-col>
              </b-row>

              <b-row class="mb-1">
                <b-col sm="8">
                  <label for="temp_wetbulb_c">
                    Suhu Bola Basah (&#8451;) <feather-icon icon="HelpCircleIcon" size="12"
                      class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                      title="Nilai suhu udara bola basah, input nilai sampai persepuluhan" /></label>
                </b-col>
                <b-col sm="4">
                  <b-form-input id="temp_wetbulb_c" :state="validateState($v.input_data.temp_wetbulb_c)"
                    v-model.number="$v.input_data.temp_wetbulb_c.$model" size="sm" class="text-center"
                    @keyup="formatPersepuluhan"
                    :class="{ rangecheck: rangecheck_status('temp_wetbulb_c'), stepcheck: stepcheck_status('temp_wetbulb_c') }">
                  </b-form-input>
                  
                  <b-tooltip v-if="!grosscheck_status('temp_wetbulb_c').status"
                    target="temp_wetbulb_c" triggers="hover">
                    {{ tooltipMessage("input_data_temp_wetbulb_c") }}
                  </b-tooltip>  
                  <b-tooltip v-else-if="consistencycheck_status('temp_wetbulb_c')"
                    target="temp_wetbulb_c" triggers="hover">
                    {{ consistency_check_tooltip("temp_wetbulb_c").message }}
                  </b-tooltip>      
                  <b-tooltip v-else-if="rangecheck_status('temp_wetbulb_c')"  target="temp_wetbulb_c" triggers="hover">
                    {{ form_status("temp_wetbulb_c", "rangecheck").message }}
                  </b-tooltip>        
                  <b-tooltip v-else-if="stepcheck_status('temp_wetbulb_c')" target="temp_wetbulb_c" triggers="hover">
                    {{ form_status("temp_wetbulb_c", "stepcheck").message }}
                  </b-tooltip>
                  <!-- <b-tooltip v-if="consistency_check_tooltip('temp_wetbulb_c').status" target="temp_wetbulb_c"
                    triggers="hover">
                    {{ consistency_check_tooltip("temp_wetbulb_c").message }}
                  </b-tooltip>
                  <b-tooltip v-if="!form_status('temp_wetbulb_c').status && !consistency_check_tooltip('temp_wetbulb_c').status"
                    :show="validateTooltipStateInput_data('temp_wetbulb_c')" :triggers="'hover'" target="temp_wetbulb_c"
                    placement="top" custom-class="validation-tooltip">
                    {{ tooltipMessage("input_data_temp_wetbulb_c") }}
                  </b-tooltip>
                  <b-tooltip v-if="form_status('temp_wetbulb_c').status && !consistency_check_tooltip('temp_wetbulb_c').status" target="temp_wetbulb_c" triggers="hover">
                    {{ form_status("temp_wetbulb_c").message }}
                  </b-tooltip> -->
                  <b-tooltip v-else :triggers="hover" target="temp_wetbulb_c" placement="top" custom-class="validation-tooltip">
                    {{ tooltipMessage("input_data_temp_wetbulb_c") }}
                  </b-tooltip>
                </b-col>
              </b-row>

              <b-row class="mb-1">
                <b-col sm="8">
                  <label for="temp_max_c_txtxtx">
                    Suhu Maksimum (&#8451;)
                    <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white"
                      v-b-tooltip.hover.v-dark
                      title="Nilai suhu maksimum diinput hanya pada jam 12 utc, input nilai sampai persepuluhan" /></label>
                </b-col>
                <b-col sm="4">
                  <b-form-input id="temp_max_c_txtxtx" :state="validateState($v.input_data.temp_max_c_txtxtx)"
                    v-model.number="$v.input_data.temp_max_c_txtxtx.$model"
                    :class="{ 'text-center': true,
                      ...ASelectValidate($v.input_data.temp_max_c_txtxtx), 
                      rangecheck: rangecheck_status('temp_max_c_txtxtx') && !consistencycheck_status('temp_max_c_txtxtx')}" size="sm"
                    :disabled="disabled.temp_max_c_txtxtx" @keyup="formatPersepuluhan"></b-form-input>
                  <b-tooltip v-if="form_status('temp_max_c_txtxtx').status" target="temp_max_c_txtxtx" triggers="hover">
                    {{ form_status("temp_max_c_txtxtx").message }}
                  </b-tooltip>
                  <!-- <b-tooltip v-if="consistency_check_tooltip('temp_max_c_txtxtx').status" target="temp_max_c_txtxtx"
                    triggers="hover">
                    {{ consistency_check_tooltip("temp_max_c_txtxtx").message }}
                  </b-tooltip>
                  <b-tooltip v-if="consistency_check_tooltip('maxTT').status" target="temp_max_c_txtxtx"
                    triggers="hover">
                    {{ consistency_check_tooltip("maxTT").message }}
                  </b-tooltip>
                  <b-tooltip :show="validateTooltipStateInput_data('temp_max_c_txtxtx')" :triggers="'hover'"
                    target="temp_max_c_txtxtx" placement="top" custom-class="validation-tooltip">
                    {{ tooltipMessage("input_data_temp_max_c_txtxtx") }}
                  </b-tooltip> -->
                </b-col>
              </b-row>

              <b-row>
                <b-col sm="8">
                  <label for="temp_min_c_tntntn">
                    Suhu Minimum (&#8451;)
                    <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white"
                      v-b-tooltip.hover.v-dark
                      title="Nilai suhu minimum diinput hanya pada jam 00 utc, input nilai sampai persepuluhan" /></label>
                </b-col>
                <b-col sm="4">
                  <b-form-input id="temp_min_c_tntntn" :state="validateState($v.input_data.temp_min_c_tntntn)"
                    v-model.number="$v.input_data.temp_min_c_tntntn.$model"
                    :class="{ 'text-center': true, 
                      ...ASelectValidate($v.input_data.temp_min_c_tntntn), 
                      rangecheck: rangecheck_status('temp_min_c_tntntn') && !consistencycheck_status('temp_min_c_tntntn') }" size="sm"
                    :disabled="disabled.temp_min_c_tntntn" @keyup="formatPersepuluhan"></b-form-input>
                  <b-tooltip v-if="form_status('temp_min_c_tntntn').status" target="temp_min_c_tntntn" triggers="hover">
                    {{ form_status("temp_min_c_tntntn").message }}
                  </b-tooltip>
                  <!--  <b-tooltip :show="validateTooltipStateInput_data('temp_min_c_tntntn')" :triggers="'hover'"
                    target="temp_min_c_tntntn" placement="top" custom-class="validation-tooltip">
                    {{ tooltipMessage("input_data_temp_min_c_tntntn") }}
                  </b-tooltip>
                 <b-tooltip v-if="consistency_check_tooltip('temp_min_c_tntntn').status" target="temp_min_c_tntntn"
                    triggers="hover" placement="bottom">
                    {{ consistency_check_tooltip("temp_min_c_tntntn").message }}
                  </b-tooltip>
                  <b-tooltip v-if="consistency_check_tooltip('minTT').status" target="temp_min_c_tntntn"
                    triggers="hover" placement="bottom">
                    {{ consistency_check_tooltip("minTT").message }}
                  </b-tooltip>-->
                </b-col>
                <b-col sm="6" class="mb-5">
                  <!-- margin buttom -->
                </b-col>
              </b-row>
            </b-collapse>
          </b-card>
        </b-col>
      </b-row>
      <!-- end card4 -->

      <!-- card 5 -->
      <b-row class="mb-n1">
        <b-col cols="12">
          <b-card no-body class="bg-light-primary" style="border: 1px solid #acc2ef">
            <b-container fluid>
              <b-row class="my-1 justify-content-center">
                <b-col sm="2" class="my-auto d-flex justify-content-end">
                  <label for="cloud_cover_oktas_m">
                    Bagian Langit Tertutup Awan (oktas)
                    <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white"
                      v-b-tooltip.hover.v-dark
                      title="Bagian langit yang tertutup awan dalam oktas, tanpa melihat jenisnya" /></label>
                </b-col>
                <b-col sm="2" class="my-auto">
                  <a-select id="cloud_cover_oktas_m" show-search :filter-option="filterOption" :not-found-content="null"
                    style="width: 100%" :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                    :value="$v.input_data.cloud_cover_oktas_m.$model"
                    @change="(value) => ($v.input_data.cloud_cover_oktas_m.$model = value)"
                    :class="{ rangecheck: rangecheck_status('cloud_cover_oktas_m'), ...ASelectValidate($v.input_data.cloud_cover_oktas_m) }">
                    <!-- <a-select-option value="">-</a-select-option> -->
                    <a-select-option v-for="opt in options.cloud_cover_oktas_m" :key="opt.text + opt.value"
                      :value="opt.value">
                      {{ opt.text }}
                    </a-select-option>
                  </a-select>
                  <b-tooltip v-if="form_status('cloud_cover_oktas_m').status" target="cloud_cover_oktas_m"
                    triggers="hover">
                    {{ form_status("cloud_cover_oktas_m").message }}
                  </b-tooltip>
                </b-col>

                <b-col sm="1" class="my-auto d-flex justify-content-end">
                  <label for="rainfall_last_mm">Hujan takaran terakhir (mm)
                    <feather-icon icon="HelpCircleIcon" size="10" class="bg-primary rounded-circle text-white"
                      v-b-tooltip.hover.v-dark
                      title="jumlah curah hujan takaran terakhir atau 3 jam yang lalu dalam milimeter, input nilai sampai persepuluhan" /></label>
                </b-col>
                <b-col sm="2" class="my-auto">
                  <b-form-input :state="validateState($v.input_data.rainfall_last_mm)"
                    v-model.number="$v.input_data.rainfall_last_mm.$model"
                    :class="{ 'text-center': true, rangecheck: rangecheck_status('rainfall_last_mm') }" size="sm"
                    :disabled="disabled.rainfall_last_mm" id="rainfall_last_mm"
                    @keyup="checkValidate(formatPersepuluhan($event), 'rainfall_last_mm')"
                    v-on:keydown="onKeyCheck($event)"></b-form-input>
                  <b-tooltip v-if="form_status('rainfall_last_mm').status" target="rainfall_last_mm" triggers="hover">
                    {{ form_status("rainfall_last_mm").message }}
                  </b-tooltip>
                  <!-- tooltips GC -->
                  <b-tooltip :show="validateTooltipStateInput_data('rainfall_last_mm')" :triggers="'hover'"
                    target="rainfall_last_mm" placement="top" custom-class="validation-tooltip">
                    {{ tooltipMessage("input_data_rainfall_last_mm") }}
                  </b-tooltip>
                </b-col>

                <!-- AN : Vertical Visibility -->
                <b-col sm="2" class="my-auto d-flex justify-content-end">
                  <label for="vertical_vis">Jarak Penglihatan Vertikal (m)
                    <feather-icon icon="HelpCircleIcon" size="10" class="bg-primary rounded-circle text-white"
                      v-b-tooltip.hover.v-dark
                      title="jarak penglihatan vertikal terhadap medium yang menghalangi" /></label>
                </b-col>
                <b-col sm="2" class="my-auto">
                  <b-form-input :state="validateState($v.input_data.cloud_vertical_vis)"
                    v-model.number="$v.input_data.cloud_vertical_vis.$model"
                    type="number"
                    :class="{ 'text-center': true }" size="sm"
                    :disabled="disabled.cloud_vertical_vis" id="cloud_vertical_vis"
                    @keyup="checkValidate($event, 'cloud_vertical_vis')"
                    @keydown="formatInteger"
                    v-on:keydown="onKeyCheck($event)"></b-form-input>
                  <b-tooltip v-if="form_status('cloud_vertical_vis').status" target="cloud_vertical_vis"
                    triggers="hover">
                    {{ form_status("cloud_vertical_vis").message }}
                  </b-tooltip>
                  <b-tooltip v-if="!form_status('cloud_vertical_vis').status" 
                    :show="validateTooltipStateInput_data('cloud_vertical_vis')" :triggers="'hover'"
                    target="cloud_vertical_vis" placement="top" custom-class="validation-tooltip">
                    {{ tooltipMessage("input_data_cloud_vertical_vis") }}
                  </b-tooltip>
                </b-col>
              </b-row>
            </b-container>
          </b-card>
        </b-col>
      </b-row>
      <!-- end card5 -->

      <!-- card 6 -->
      <b-row cols="mt-n1">
        <b-col cols="4" class="mb-1">
          <b-card class="h-100">
            <b-row>
              <b-col cols="12">
                <h5 class="mb-1 badge badge-light-dark">AWAN RENDAH</h5>
              </b-col>
            </b-row>
            <b-collapse visible id="collapse-row-2">

              <b-row  class="">
                <b-col cols="3">
                  <b-row class="pr-1 pl-1 align-items-center" style="height:32px;">
                      <label for="cloud_low_type_cl" class="mb-0">CL <feather-icon icon="HelpCircleIcon" size="12"
                      class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                      title="Jenis awan rendah" /></label>
                  </b-row>

                  <b-row class="pr-1 pl-1 align-items-center mt-1" style="height:32px;">
                    <label for="cloud_low_cover_oktas" class="mb-0">NCL <feather-icon icon="HelpCircleIcon" size="12"
                      class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                      title="Jumlah awan rendah yang menutupi langit dalam oktas" /></label>
                  </b-row>

                  <b-row class="pr-1 pl-1 align-items-center mt-1" style="height:32px;">
                    <label for="#" class="mb-0">Jenis <feather-icon icon="HelpCircleIcon" size="12"
                      class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                      title="Jenis awan C untuk jenis awan rendah" /></label>
                  </b-row>

                  <b-row class="pr-1 pl-1 align-items-center mt-1" style="height:32px;">
                    <label for="#" class="mb-0">Jumlah <feather-icon icon="HelpCircleIcon" size="12"
                      class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                      title="Jumlah awan rendah untuk jenis awan C yang dipilih dalam oktas" /></label>
                  </b-row>

                  <b-row class="pr-1 pl-1 align-items-center mt-1" style="height:32px;">
                    <label for="#" class="mb-0">Tinggi Dasar (m) <feather-icon icon="HelpCircleIcon" size="12"
                      class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                      title="Tinggi dasar untuk jenis awan C yang dipilih dalam meter" /></label>
                  </b-row>

                  <b-row class="pr-1 pl-1 align-items-center mt-1" style="height:32px;">
                    <label for="#" class="mb-0">Tinggi Puncak (m)<feather-icon icon="HelpCircleIcon" size="12"
                      class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                      title="Tinggi puncak untuk jenis awan rendah (Cu atau Cb) yang dipilih dalam meter" /></label>
                  </b-row>

                  <b-row class="pr-1 pl-1 align-items-center mt-1" style="height:32px;">
                    <label for="#" class="mb-0">Arah Gerak<feather-icon icon="HelpCircleIcon" size="12"
                      class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                      title="Arah dari mana awan CL bergerak" /></label>
                  </b-row>

                  <b-row class="pr-1 pl-1 align-items-center mt-1" style="height:32px;">
                    <label for="#" class="mb-0">Sudut Elevasi <feather-icon icon="HelpCircleIcon" size="12"
                      class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                      title="Sudut elevasi puncak awan CL (Cu atau Cb)" /></label>
                  </b-row>

                  <b-row class="pr-1 pl-1 align-items-center mt-1" style="height:32px;">
                    <label for="#" class="mb-0">Arah Sebenarnya <feather-icon icon="HelpCircleIcon" size="12"
                      class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                      title="Arah sebenarnya dari awan orografik atau awan konvektif terlihat" /></label>
                  </b-row>
                </b-col>

                <b-col cols="3" class="">
                  <b-row class="pr-1">
                    <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                      :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                      :value="$v.awan_rendah.CL.$model" @change="(value) => ($v.awan_rendah.CL.$model = value)"
                      :class="{ rangecheck: rangecheck_status('cloud_low_type_cl'), ...ASelectValidate($v.awan_rendah.CL) }"
                      id="cloud_low_type_cl"
                      :disabled="disabled.is_cloud_cover_block">
                      <!-- <a-select-option value="-">-</a-select-option> -->
                      <a-select-option v-for="opt in options.awan_rendah.CL" :key="opt.text + opt.value"
                        :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                    </a-select>
                    <b-tooltip v-if="form_status('cloud_low_type_cl').status" target="cloud_low_type_cl"
                      triggers="hover">
                      {{ form_status("cloud_low_type_cl").message }}
                    </b-tooltip>
                  </b-row>

                  <b-row class="mt-1 pr-1">
                    <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                      :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                      :value="$v.awan_rendah.NCL.$model" @change="(value) => ($v.awan_rendah.NCL.$model = value)"
                      :class="{ rangecheck: rangecheck_status('cloud_low_cover_oktas'), ...ASelectValidate($v.awan_rendah.NCL) }"
                      :disabled="disabled.awan_rendah.NCL || disabled.is_cloud_cover_block"
                      id="cloud_low_cover_oktas">
                      <!-- <a-select-option value="-">-</a-select-option> -->
                      <a-select-option v-for="opt in options.awan_rendah.NCL" :key="opt.text + opt.value"
                        :value="opt.value">
                        {{ opt.text }}
                      </a-select-option>
                    </a-select>
                    <b-tooltip v-if="form_status('cloud_low_cover_oktas').status" target="cloud_low_cover_oktas"
                      triggers="hover">
                      {{ form_status("cloud_low_cover_oktas").message }}
                    </b-tooltip>
                  </b-row>

                  <!-- AN Edit : clear puncak for any cloud except 8/9-->
                  <!-- @change="(value) => ($v.awan_rendah.jenis[0].$model = value)" -->
                  <b-row class="mt-1 pr-1">
                    <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                      :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                      :value="$v.awan_rendah.jenis[0].$model"
                      @change="(value) => {
                          $v.awan_rendah.jenis[0].$model = value;
                          if(value != 9 && value != 8) {
                            $v.awan_rendah.tinggi_puncak[0].$model = '';
                            $v.awan_rendah.sudut[0].$model = '';
                          }
                        }"
                      :disabled="disabled.awan_rendah.cloud_1 || disabled.is_cloud_cover_block"
                      :class="{ ...ASelectValidate($v.awan_rendah.jenis[0]) }">
                      <!-- <a-select-option value="-">-</a-select-option> -->
                      <a-select-option v-for="opt in options.awan_rendah.jenis" :key="opt.text + opt.value"
                        :value="opt.value" > {{ opt.value }} - {{ opt.text }} </a-select-option>
                    </a-select>
                  </b-row>

                  <b-row class="mt-1 pr-1">
                    <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                      :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                      :value="$v.awan_rendah.jumlah[0].$model"
                      @change="(value) => ($v.awan_rendah.jumlah[0].$model = value)"
                      :disabled="disabled.awan_rendah.cloud_1 || disabled.is_cloud_cover_block"
                      :class="{ ...ASelectValidate($v.awan_rendah.jumlah[0]) }">
                      <!-- <a-select-option value="-">-</a-select-option> -->
                      <a-select-option v-for="opt in options.awan_rendah.jumlah" :key="opt.text + opt.value"
                        :value="opt.value" > {{ opt.text }} </a-select-option>
                    </a-select>
                  </b-row>

                  <b-row class="mt-1 pr-1">
                    <b-form-input :state="validateState($v.awan_rendah.tinggi_dasar[0])"
                      v-model.number="$v.awan_rendah.tinggi_dasar[0].$model" type="number"
                      :class="{ 'text-center': true, rangecheck: rangecheck_status('cloud_low_base_1') }"
                      size="sm" 
                      :disabled="disabled.awan_rendah.cloud_1 || disabled.is_cloud_cover_block" 
                      id="cloud_low_base_1"
                      @keyup="checkValidate($event, 'cloud_low_base_1')" @keydown="formatInteger"
                      
                      style="height:32px;"></b-form-input>
                    <b-tooltip v-if="form_status('cloud_low_base_1').status" target="cloud_low_base_1"
                      triggers="hover">
                      {{ form_status("cloud_low_base_1").message }}
                    </b-tooltip>
                    <b-tooltip v-if="!form_status('cloud_low_base_1').status" :show="validateTooltipStateInput_data('cloud_low_base_1')" :triggers="'hover'"
                      target="cloud_low_base_1" placement="top" custom-class="validation-tooltip">
                      {{ tooltipMessage("input_data_cloud_low_base_1") }}
                    </b-tooltip>
                  </b-row>

                  <b-row class="mt-1 pr-1">
                    <b-form-input :state="validateState($v.awan_rendah.tinggi_puncak[0])"
                      v-model.number="$v.awan_rendah.tinggi_puncak[0].$model" type="number"
                      :class="{ 'text-center': true, rangecheck: rangecheck_status('cloud_low_peak_1') }" size="sm"
                      :disabled="disabled.awan_rendah.tinggi_puncak[0] || disabled.is_cloud_cover_block" 
                      id="cloud_low_peak_1"
                      @keyup="checkValidate($event, 'cloud_low_peak_1')" v-on:keydown="onKeyCheck($event)"
                      
                      style="height:32px;"></b-form-input>
                      <!-- tooltips GC -->
                    <b-tooltip v-if="!form_status('cloud_low_peak_1').status"
                      :show="validateTooltipStateInput_data('cloud_low_peak_1')" :triggers="hover" target="cloud_low_peak_1" placement="top" custom-class="validation-tooltip">
                      {{ tooltipMessage("input_data_cloud_low_peak_1") }}
                    </b-tooltip>
                    <b-tooltip v-if="form_status('cloud_low_peak_1').status"  target="cloud_low_peak_1" :triggers="hover" >
                      {{ form_status("cloud_low_peak_1").message }}
                    </b-tooltip>
                    <!-- <b-tooltip v-if="form_status('cloud_low_peak_1').status" target="cloud_low_peak_1"
                      triggers="hover">
                      {{ form_status("cloud_low_peak_1").message }}
                    </b-tooltip>
                    <b-tooltip :show="validateTooltipStateInput_data('cloud_low_peak_1')" :triggers="'hover'"
                      target="cloud_low_peak_1" placement="top" custom-class="validation-tooltip">
                      {{ tooltipMessage("input_data_cloud_low_peak_1") }}
                    </b-tooltip> -->
                  </b-row>

                  <b-row class="mt-1 pr-1">
                    <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                      :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                      :value="$v.awan_rendah.arah[0].$model"
                      @change="(value) => ($v.awan_rendah.arah[0].$model = value)"
                      :disabled="disabled.awan_rendah.cloud_1 || disabled.is_cloud_cover_block"
                      :class="{ ...ASelectValidate($v.awan_rendah.arah[0]) }">
                      <!-- <a-select-option value="-">-</a-select-option> -->
                      <a-select-option v-for="opt in options.awan_rendah.arah" :key="opt.text + opt.value"
                        :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                    </a-select>
                  </b-row>

                  <!-- AN Edit -->
                  <!-- :disabled="disabled.awan_rendah.sudut[0]" -->
                  <b-row class="mt-1 pr-1">
                    <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                      :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                      :value="$v.awan_rendah.sudut[0].$model"
                      @change="(value) => ($v.awan_rendah.sudut[0].$model = value)"
                      :disabled="disabled.cloud_elevation_1_angle_ec || disabled.is_cloud_cover_block"
                      :class="{ ...ASelectValidate($v.awan_rendah.sudut[0]) }"
                      id="cloud_elevation_1_angle_ec"
                      :allowClear="true">
                      <!-- <a-select-option value="-">-</a-select-option> -->
                      <a-select-option v-for="opt in options.awan_rendah.sudut" :key="opt.text + opt.value"
                        :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                    </a-select>
                    <b-tooltip v-if="form_status('cloud_elevation_1_angle_ec').status" target="cloud_elevation_1_angle_ec"
                      :triggers="hover">
                      {{ form_status("cloud_elevation_1_angle_ec").message }}
                  </b-tooltip>
                  </b-row>

                  <b-row class="mt-1 pr-1">
                    <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                      :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                      :value="$v.awan_rendah.da[0].$model"
                      @change="(value) => ($v.awan_rendah.da[0].$model = value)"
                      :disabled="disabled.awan_rendah.tinggi_puncak[0]"
                      :class="{ ...ASelectValidate($v.awan_rendah.da[0]) }"
                      :allowClear="true">
                      <a-select-option v-for="opt in options.awan_rendah.arah" :key="opt.text + opt.value"
                        :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                    </a-select>
                  </b-row>
                </b-col>

                <b-col cols="3" class="">
                  <b-row class="">

                  </b-row>

                  <b-row class="mt-1">

                  </b-row>

                  <!-- @change="(value) => ($v.awan_rendah.jenis[1].$model = value)" -->
                  <b-row class="mt-5 pr-1">
                    <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%; margin-top:22px"
                      :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                      :value="$v.awan_rendah.jenis[1].$model"
                      @change="(value) => {
                          $v.awan_rendah.jenis[1].$model = value;
                          if(value != 9 && value != 8) {
                            $v.awan_rendah.tinggi_puncak[1].$model = '';
                            $v.awan_rendah.sudut[1].$model = '';
                          }
                        }"
                      :disabled="disabled.awan_rendah.cloud_2"
                      :class="{ ...ASelectValidate($v.awan_rendah.jenis[1]) }">
                      >
                      <!-- <a-select-option value="-">-</a-select-option> -->
                      <a-select-option v-for="opt in options.awan_rendah.jenis" :key="opt.text + opt.value"
                        :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                    </a-select>
                  </b-row>

                  <b-row class="mt-1 pr-1">
                    <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                      :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                      :value="$v.awan_rendah.jumlah[1].$model"
                      @change="(value) => ($v.awan_rendah.jumlah[1].$model = value)"
                      :disabled="disabled.awan_rendah.cloud_2"
                      :class="{ ...ASelectValidate($v.awan_rendah.jumlah[1]) }">
                      <!-- <a-select-option value="-">-</a-select-option> -->
                      <a-select-option v-for="opt in options.awan_rendah.jumlah" :key="opt.text + opt.value"
                        :value="opt.value"> {{ opt.text }} </a-select-option>
                    </a-select>
                  </b-row>

                  <b-row class="mt-1 pr-1">
                    <b-form-input :state="validateState($v.awan_rendah.tinggi_dasar[1])"
                      v-model.number="$v.awan_rendah.tinggi_dasar[1].$model" type="number"
                      :class="{ 'text-center': true, rangecheck: rangecheck_status('cloud_low_base_2') }" size="sm"
                      :disabled="disabled.awan_rendah.cloud_2" id="cloud_low_base_2"
                      @keyup="checkValidate($event, 'cloud_low_base_2')" @keydown="formatInteger"
                      style="height:32px;"></b-form-input>
                    <!-- <b-tooltip v-if="form_status('cloud_low_base_2').status" target="cloud_low_base_2"
                      triggers="hover">
                      {{ form_status("cloud_low_base_2").message }}
                    </b-tooltip> -->
                    <b-tooltip v-if="form_status('cloud_low_base_2').status" target="cloud_low_base_2"
                      :triggers="hover">
                      {{ form_status("cloud_low_base_2").message }}
                    </b-tooltip>
                    <b-tooltip v-if="!form_status('cloud_low_base_2').status" :show="validateTooltipStateInput_data('cloud_low_base_2')" :triggers="'hover'"
                      target="cloud_low_base_2" placement="top" custom-class="validation-tooltip">
                      {{ tooltipMessage("input_data_cloud_low_base_2") }}
                    </b-tooltip>
                  </b-row>

                  <b-row class="mt-1 pr-1">
                    <b-form-input :state="validateState($v.awan_rendah.tinggi_puncak[1])"
                      v-model.number="$v.awan_rendah.tinggi_puncak[1].$model" type="number"
                      :class="{ 'text-center': true, rangecheck: rangecheck_status('cloud_low_peak_2') }" size="sm"
                      :disabled="disabled.awan_rendah.tinggi_puncak[1]" id="cloud_low_peak_2"
                      @keyup="checkValidate($event, 'cloud_low_peak_2')"
                      v-on:keydown="onKeyCheck($event)"
                      style="height:32px;"></b-form-input>
                    <!-- <b-tooltip v-if="form_status('cloud_low_peak_2').status" target="cloud_low_peak_2"
                      triggers="hover">
                      {{ form_status("cloud_low_peak_2").message }}
                    </b-tooltip> -->
                    <b-tooltip v-if="!form_status('cloud_low_peak_2').status"
                      :show="validateTooltipStateInput_data('cloud_low_peak_2')" :triggers="hover" target="cloud_low_peak_2" placement="top" custom-class="validation-tooltip">
                      {{ tooltipMessage("input_data_cloud_low_peak_2") }}
                    </b-tooltip>
                    <b-tooltip v-if="form_status('cloud_low_peak_2').status"  target="cloud_low_peak_2" :triggers="hover" >
                      {{ form_status("cloud_low_peak_2").message }}
                    </b-tooltip>
                  </b-row>

                  <b-row class="mt-1 pr-1">
                    <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                      :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                      :value="$v.awan_rendah.arah[1].$model"
                      @change="(value) => ($v.awan_rendah.arah[1].$model = value)"
                      :disabled="disabled.awan_rendah.cloud_2"
                      :class="{ ...ASelectValidate($v.awan_rendah.arah[1]) }">
                      <!-- <a-select-option value="-">-</a-select-option> -->
                      <a-select-option v-for="opt in options.awan_rendah.arah" :key="opt.text + opt.value"
                        :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                    </a-select>
                  </b-row>

                  <!-- :disabled="disabled.awan_rendah.sudut[1]" -->
                  <b-row class="mt-1 pr-1">
                    <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                      :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                      :value="$v.awan_rendah.sudut[1].$model"
                      @change="(value) => ($v.awan_rendah.sudut[1].$model = value)"
                      :disabled="disabled.cloud_elevation_2_angle_ec"
                      :class="{ ...ASelectValidate($v.awan_rendah.sudut[1]) }"
                      id="cloud_elevation_2_angle_ec"
                      :allowClear="true">
                      <!-- <a-select-option value="-">-</a-select-option> -->
                      <a-select-option v-for="opt in options.awan_rendah.sudut" :key="opt.text + opt.value"
                        :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                    </a-select>
                    <b-tooltip v-if="form_status('cloud_elevation_2_angle_ec').status" target="cloud_elevation_1_angle_ec"
                      :triggers="hover">
                      {{ form_status("cloud_elevation_2_angle_ec").message }}
                    </b-tooltip>
                  </b-row>
                
                  <b-row class="mt-1 pr-1">
                    <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                      :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                      :value="$v.awan_rendah.da[1].$model"
                      @change="(value) => ($v.awan_rendah.da[1].$model = value)"
                      :disabled="disabled.awan_rendah.tinggi_puncak[1]"
                      :class="{ ...ASelectValidate($v.awan_rendah.da[1]) }"
                      :allowClear="true">
                      <a-select-option v-for="opt in options.awan_rendah.arah" :key="opt.text + opt.value"
                        :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                    </a-select>
                  </b-row>

                  <b-row class="mt-1">
                    
                  </b-row>

                  <b-row>
                    <b-col  class="d-flex flex-row align-items-center justify-items-center">
                      <b-form-checkbox id="775" class="custom-control-primary ml-1" name="check-button" switch
                        v-model="awan_rendah.cloud_2" :disabled="disabled.is_cloud_cover_block" tabindex="-1">
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" tabindex="-1" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" tabindex="-1"/>
                        </span>
                      </b-form-checkbox>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col cols="3" class="pr-1" >
                  <b-row class="">

                  </b-row>

                  <b-row class="mt-1">
                    
                  </b-row>

                  <b-row class="mt-5 pr-1">
                    <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%; margin-top:22px;"
                      :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                      :value="$v.awan_rendah.jenis[2].$model"
                      @change="(value) => ($v.awan_rendah.jenis[2].$model = value)"
                      :disabled="disabled.awan_rendah.cloud_3"
                      :class="{ ...ASelectValidate($v.awan_rendah.jenis[2]) }">
                      >
                      <!-- <a-select-option value="-">-</a-select-option> -->
                      <a-select-option v-for="opt in options.awan_rendah.jenis" :key="opt.text + opt.value"
                        :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                    </a-select>
                  </b-row>

                  <b-row class="mt-1 pr-1">
                    <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                      :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                      :value="$v.awan_rendah.jumlah[2].$model"
                      @change="(value) => ($v.awan_rendah.jumlah[2].$model = value)"
                      :disabled="disabled.awan_rendah.cloud_3"
                      :class="{ ...ASelectValidate($v.awan_rendah.jumlah[2]) }">
                      <!-- <a-select-option value="-">-</a-select-option> -->
                      <a-select-option v-for="opt in options.awan_rendah.jumlah" :key="opt.text + opt.value"
                        :value="opt.value"> {{ opt.text }} </a-select-option>
                    </a-select>
                  </b-row>

                  <b-row class="mt-1 pr-1">
                    <b-form-input :state="validateState($v.awan_rendah.tinggi_dasar[2])"
                      v-model.number="$v.awan_rendah.tinggi_dasar[2].$model" type="number"
                      :class="{ 'text-center': true, rangecheck: rangecheck_status('cloud_low_base_3') }" size="sm"
                      :disabled="disabled.awan_rendah.cloud_3" id="cloud_low_base_3"
                      @keyup="checkValidate($event, 'cloud_low_base_3')" @keydown="formatInteger"
                      style="height:32px;"></b-form-input>
                    <!-- <b-tooltip v-if="form_status('cloud_low_base_3').status" target="cloud_low_base_3"
                      triggers="hover">
                      {{ form_status("cloud_low_base_3").message }}
                    </b-tooltip> -->
                    <b-tooltip v-if="form_status('cloud_low_base_3').status" target="cloud_low_base_3"
                      :triggers="hover">
                      {{ form_status("cloud_low_base_3").message }}
                    </b-tooltip>
                    <b-tooltip v-if="!form_status('cloud_low_base_3').status" :show="validateTooltipStateInput_data('cloud_low_base_3')" :triggers="'hover'"
                      target="cloud_low_base_3" placement="top" custom-class="validation-tooltip">
                      {{ tooltipMessage("input_data_cloud_low_base_3") }}
                    </b-tooltip>
                  </b-row>

                  <b-row class="mt-1">
                    
                  </b-row>

                  <b-row class="mt-1 pr-1">
                    <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%; margin-top:32px; "
                      :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                      :value="$v.awan_rendah.arah[2].$model"
                      @change="(value) => ($v.awan_rendah.arah[2].$model = value)"
                      :disabled="disabled.awan_rendah.cloud_3"
                      :class="{ ...ASelectValidate($v.awan_rendah.arah[2]) }">
                      <!-- <a-select-option value="-">-</a-select-option> -->
                      <a-select-option v-for="opt in options.awan_rendah.arah" :key="opt.text + opt.value"
                        :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                    </a-select>
                  </b-row>

                  <b-row class="mt-1">
                    
                  </b-row>

                  <b-row>
                    <b-col  class="d-flex flex-row align-items-center justify-items-center">
                      <b-form-checkbox id="776" class="custom-control-primary ml-1" name="check-button" switch
                        v-model="awan_rendah.cloud_3" :disabled="disabled.is_cloud_cover_block" tabindex="-1">
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" tabindex="-1"/>
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon"  tabindex="-1"/>
                        </span>
                      </b-form-checkbox>
                      <feather-icon icon="HelpCircleIcon" size="14" class="bg-primary rounded-circle text-white"
                        v-b-tooltip.hover.v-dark
                        title="Untuk menambahkan jenis awan rendah, silahkan klik simbol di bagian kiri" 
                        tabindex="-1"/>
                    </b-col>
                  </b-row>

                </b-col>
              </b-row>


              <!-- <b-row>
                <b-col>
                  <b-row class="my-1">
                    <b-col sm="3">
                      <label for="cloud_low_type_cl">CL <feather-icon icon="HelpCircleIcon" size="12"
                          class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                          title="Jenis awan rendah" /></label>
                    </b-col>
                    <b-col sm="3" class="pr-1 pl-0">
                      <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                        :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                        :value="$v.awan_rendah.CL.$model" @change="(value) => ($v.awan_rendah.CL.$model = value)"
                        :class="{ rangecheck: rangecheck_status('cloud_low_type_cl'), ...ASelectValidate($v.awan_rendah.CL) }"
                        id="cloud_low_type_cl">
                        <a-select-option value="-">-</a-select-option>
                        <a-select-option v-for="opt in options.awan_rendah.CL" :key="opt.text + opt.value"
                          :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                      </a-select>
                      <b-tooltip v-if="form_status('cloud_low_type_cl').status" target="cloud_low_type_cl"
                        triggers="hover">
                        {{ form_status("cloud_low_type_cl").message }}
                      </b-tooltip>
                    </b-col>
                  </b-row>

                  <b-row class="my-1">
                    <b-col sm="3">
                      <label for="cloud_low_cover_oktas">NCL <feather-icon icon="HelpCircleIcon" size="12"
                          class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                          title="Jumlah awan rendah yang menutupi langit dalam oktas" /></label>
                    </b-col>
                    <b-col sm="3" class="pr-1 pl-0">
                      <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                        :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                        :value="$v.awan_rendah.NCL.$model" @change="(value) => ($v.awan_rendah.NCL.$model = value)"
                        :class="{ rangecheck: rangecheck_status('cloud_low_cover_oktas'), ...ASelectValidate($v.awan_rendah.NCL) }"
                        id="cloud_low_cover_oktas">
                        <a-select-option value="-">-</a-select-option>
                        <a-select-option v-for="opt in options.awan_rendah.NCL" :key="opt.text + opt.value"
                          :value="opt.value">
                          {{ opt.text }}
                        </a-select-option>
                      </a-select>
                      <b-tooltip v-if="form_status('cloud_low_cover_oktas').status" target="cloud_low_cover_oktas"
                        triggers="hover">
                        {{ form_status("cloud_low_cover_oktas").message }}
                      </b-tooltip>
                    </b-col>
                  </b-row>

                  <b-row class="mb-1">
                    <b-col sm="3">
                      <label for="#">Jenis <feather-icon icon="HelpCircleIcon" size="12"
                          class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                          title="Jenis awan C untuk jenis awan rendah" /></label>
                    </b-col>
                    <b-col sm="3" class="pr-1 pl-0">
                      <a-select tabindex="1" show-search 
                      :filter-option="filterOption" 
                      :not-found-content="null"
                        style="width: 100%" 
                        :dropdownMatchSelectWidth="false" 
                        :dropdownStyle="{ width: '25vw' }"
                        :value="$v.awan_rendah.jenis[0].$model"
                        @change="(value) => ($v.awan_rendah.jenis[0].$model = value)"
                        :disabled="disabled.awan_rendah.cloud_1"
                        :class="{ ...ASelectValidate($v.awan_rendah.jenis[0]) }">
                        <a-select-option value="-">-1</a-select-option>
                        <a-select-option v-for="opt in options.awan_rendah.jenis" :key="opt.text + opt.value"
                          :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                      </a-select>
                    </b-col>
                    <b-col sm="3" class="pr-1 pl-0">
                      <a-select show-search 
                      :filter-option="filterOption" 
                      :not-found-content="null" 
                      style="width: 100%"
                        :dropdownMatchSelectWidth="false" 
                        :dropdownStyle="{ width: '25vw' }"
                        :value="$v.awan_rendah.jenis[1].$model"
                        @change="(value) => ($v.awan_rendah.jenis[1].$model = value)"
                        :disabled="disabled.awan_rendah.cloud_2"
                        :class="{ ...ASelectValidate($v.awan_rendah.jenis[1]) }">
                        >
                        <a-select-option value="-">-</a-select-option>
                        <a-select-option 
                        v-for="opt in options.awan_rendah.jenis" 
                        :key="opt.text + opt.value"
                          :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                      </a-select>
                    </b-col>
                    <b-col sm="3" class="pr-1 pl-0">
                      <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                        :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                        :value="$v.awan_rendah.jenis[2].$model"
                        @change="(value) => ($v.awan_rendah.jenis[2].$model = value)"
                        :disabled="disabled.awan_rendah.cloud_3"
                        :class="{ ...ASelectValidate($v.awan_rendah.jenis[2]) }">
                        >
                        <a-select-option value="-">-</a-select-option>
                        <a-select-option v-for="opt in options.awan_rendah.jenis" :key="opt.text + opt.value"
                          :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                      </a-select>
                    </b-col>
                  </b-row>

                  <b-row class="mb-1">
                    <b-col sm="3">
                      <label for="#">Jumlah <feather-icon icon="HelpCircleIcon" size="12"
                          class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                          title="Jumlah awan rendah untuk jenis awan C yang dipilih dalam oktas" /></label>
                    </b-col>
                    <b-col sm="3" class="pr-1 pl-0">
                      <a-select tabindex="2" show-search :filter-option="filterOption" :not-found-content="null"
                        style="width: 100%" :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                        :value="$v.awan_rendah.jumlah[0].$model"
                        @change="(value) => ($v.awan_rendah.jumlah[0].$model = value)"
                        :disabled="disabled.awan_rendah.cloud_1"
                        :class="{ ...ASelectValidate($v.awan_rendah.jumlah[0]) }">
                        <a-select-option value="-">-2</a-select-option>
                        <a-select-option v-for="opt in options.awan_rendah.jumlah" :key="opt.text + opt.value"
                          :value="opt.value"> {{ opt.text }} </a-select-option>
                      </a-select>
                    </b-col>
                    <b-col sm="3" class="pr-1 pl-0">
                      <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                        :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                        :value="$v.awan_rendah.jumlah[1].$model"
                        @change="(value) => ($v.awan_rendah.jumlah[1].$model = value)"
                        :disabled="disabled.awan_rendah.cloud_2"
                        :class="{ ...ASelectValidate($v.awan_rendah.jumlah[1]) }">
                        <a-select-option value="-">-</a-select-option>
                        <a-select-option v-for="opt in options.awan_rendah.jumlah" :key="opt.text + opt.value"
                          :value="opt.value"> {{ opt.text }} </a-select-option>
                      </a-select>
                    </b-col>
                    <b-col sm="3" class="pr-1 pl-0">
                      <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                        :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                        :value="$v.awan_rendah.jumlah[2].$model"
                        @change="(value) => ($v.awan_rendah.jumlah[2].$model = value)"
                        :disabled="disabled.awan_rendah.cloud_3"
                        :class="{ ...ASelectValidate($v.awan_rendah.jumlah[2]) }">
                        <a-select-option value="-">-</a-select-option>
                        <a-select-option v-for="opt in options.awan_rendah.jumlah" :key="opt.text + opt.value"
                          :value="opt.value"> {{ opt.text }} </a-select-option>
                      </a-select>
                    </b-col>
                  </b-row>

                  <b-row class="mb-1">
                    <b-col sm="3">
                      <label for="#">Tinggi Dasar (m) <feather-icon icon="HelpCircleIcon" size="12"
                          class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                          title="Tinggi dasar untuk jenis awan C yang dipilih dalam meter" /></label>
                    </b-col>
                    <b-col sm="3" class="pr-1 pl-0">
                      <b-form-input :state="validateState($v.awan_rendah.tinggi_dasar[0])"
                        v-model.number="$v.awan_rendah.tinggi_dasar[0].$model" type="number"
                        :class="{ 'text-center': true, rangecheck: rangecheck_status('cloud_layer_1_height_m_hshs') }"
                        size="sm" :disabled="disabled.awan_rendah.cloud_1" id="cloud_low_base_1"
                        @keyup="checkValidate($event, 'cloud_layer_1_height_m_hshs')" @keydown="formatInteger"
                        tabindex="3"></b-form-input>
                      <b-tooltip v-if="form_status('cloud_layer_1_height_m_hshs').status" target="cloud_low_base_1"
                        triggers="hover">
                        {{ form_status("cloud_layer_1_height_m_hshs").message }}
                      </b-tooltip>
                      <b-tooltip :show="validateTooltipStateInput_data('cloud_low_base_1')" :triggers="'hover'"
                        target="cloud_low_base_1" placement="top" custom-class="validation-tooltip">
                        {{ tooltipMessage("input_data_cloud_low_base_1") }}
                      </b-tooltip>
                    </b-col>
                    <b-col sm="3" class="pr-1 pl-0">
                      <b-form-input :state="validateState($v.awan_rendah.tinggi_dasar[1])"
                        v-model.number="$v.awan_rendah.tinggi_dasar[1].$model" type="number"
                        :class="{ 'text-center': true, rangecheck: rangecheck_status('cloud_low_base_2') }" size="sm"
                        :disabled="disabled.awan_rendah.cloud_2" id="cloud_low_base_2"
                        @keyup="checkValidate($event, 'cloud_low_base_2')" @keydown="formatInteger"></b-form-input>
                      <b-tooltip v-if="form_status('cloud_low_base_2').status" target="cloud_low_base_2"
                        triggers="hover">
                        {{ form_status("cloud_low_base_2").message }}
                      </b-tooltip>
                    </b-col>
                    <b-col sm="3" class="pr-1 pl-0">
                      <b-form-input :state="validateState($v.awan_rendah.tinggi_dasar[2])"
                        v-model.number="$v.awan_rendah.tinggi_dasar[2].$model" type="number"
                        :class="{ 'text-center': true, rangecheck: rangecheck_status('cloud_low_base_3') }" size="sm"
                        :disabled="disabled.awan_rendah.cloud_3" id="cloud_low_base_3"
                        @keyup="checkValidate($event, 'cloud_low_base_3')" @keydown="formatInteger"></b-form-input>
                      <b-tooltip v-if="form_status('cloud_low_base_3').status" target="cloud_low_base_3"
                        triggers="hover">
                        {{ form_status("cloud_low_base_3").message }}
                      </b-tooltip>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="3">
                      <label for="#">Tinggi Puncak (m)<feather-icon icon="HelpCircleIcon" size="12"
                          class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                          title="Tinggi puncak untuk jenis awan rendah (Cu atau Cb) yang dipilih dalam meter" /></label>
                    </b-col>
                    <b-col sm="3" class="pr-1 pl-0">
                      <b-form-input :state="validateState($v.awan_rendah.tinggi_puncak[0])"
                        v-model.number="$v.awan_rendah.tinggi_puncak[0].$model" type="number"
                        :class="{ 'text-center': true, rangecheck: rangecheck_status('cloud_low_peak_1') }" size="sm"
                        :disabled="disabled.awan_rendah.tinggi_puncak[0]" id="cloud_low_peak_1"
                        @keyup="checkValidate($event, 'cloud_low_peak_1')" v-on:keydown="onKeyCheck($event)"
                        tabindex="4"></b-form-input>
                      <b-tooltip v-if="form_status('cloud_low_peak_1').status" target="cloud_low_peak_1"
                        triggers="hover">
                        {{ form_status("cloud_low_peak_1").message }}
                      </b-tooltip>
                      <b-tooltip :show="validateTooltipStateInput_data('cloud_low_peak_1')" :triggers="'hover'"
                        target="cloud_low_peak_1" placement="top" custom-class="validation-tooltip">
                        {{ tooltipMessage("input_data_cloud_low_peak_1") }}
                      </b-tooltip>
                    </b-col>
                    <b-col sm="3" class="pr-1 pl-0">
                      <b-form-input :state="validateState($v.awan_rendah.tinggi_puncak[1])"
                        v-model.number="$v.awan_rendah.tinggi_puncak[1].$model" type="number"
                        :class="{ 'text-center': true, rangecheck: rangecheck_status('cloud_low_peak_2') }" size="sm"
                        :disabled="disabled.awan_rendah.tinggi_puncak[1]" id="cloud_low_peak_2"
                        @keyup="checkValidate($event, 'cloud_low_peak_2')"
                        v-on:keydown="onKeyCheck($event)"></b-form-input>
                      <b-tooltip v-if="form_status('cloud_low_peak_2').status" target="cloud_low_peak_2"
                        triggers="hover">
                        {{ form_status("cloud_low_peak_2").message }}
                      </b-tooltip>
                    </b-col>
                    <b-col sm="3" class="pr-1 pl-0"> </b-col>
                  </b-row>

                  <b-row class="mb-1">
                    <b-col sm="3">
                      <label for="#">Arah <feather-icon icon="HelpCircleIcon" size="12"
                          class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                          title="Arah dari mana awan CL bergerak" /></label>
                    </b-col>
                    <b-col sm="3" class="pr-1 pl-0">
                      <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                        :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                        :value="$v.awan_rendah.arah[0].$model"
                        @change="(value) => ($v.awan_rendah.arah[0].$model = value)"
                        :disabled="disabled.awan_rendah.cloud_1"
                        :class="{ ...ASelectValidate($v.awan_rendah.arah[0]) }">
                        <a-select-option value="-">-</a-select-option>
                        <a-select-option v-for="opt in options.awan_rendah.arah" :key="opt.text + opt.value"
                          :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                      </a-select>
                    </b-col>
                    <b-col sm="3" class="pr-1 pl-0">
                      <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                        :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                        :value="$v.awan_rendah.arah[1].$model"
                        @change="(value) => ($v.awan_rendah.arah[1].$model = value)"
                        :disabled="disabled.awan_rendah.cloud_2"
                        :class="{ ...ASelectValidate($v.awan_rendah.arah[1]) }">
                        <a-select-option value="-">-</a-select-option>
                        <a-select-option v-for="opt in options.awan_rendah.arah" :key="opt.text + opt.value"
                          :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                      </a-select>
                    </b-col>
                    <b-col sm="3" class="pr-1 pl-0">
                      <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                        :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                        :value="$v.awan_rendah.arah[2].$model"
                        @change="(value) => ($v.awan_rendah.arah[2].$model = value)"
                        :disabled="disabled.awan_rendah.cloud_3"
                        :class="{ ...ASelectValidate($v.awan_rendah.arah[2]) }">
                        <a-select-option value="-">-</a-select-option>
                        <a-select-option v-for="opt in options.awan_rendah.arah" :key="opt.text + opt.value"
                          :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                      </a-select>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col sm="3" class="">
                      <label for="#">Sudut Elevasi <feather-icon icon="HelpCircleIcon" size="12"
                          class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                          title="Sudut elevasi puncak awan CL (Cu atau Cb)" /></label>
                    </b-col>
                    <b-col sm="3" class="pr-1 pl-0">
                      <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                        :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                        :value="$v.awan_rendah.sudut[0].$model"
                        @change="(value) => ($v.awan_rendah.sudut[0].$model = value)"
                        :disabled="disabled.awan_rendah.sudut[0]"
                        :class="{ ...ASelectValidate($v.awan_rendah.sudut[0]) }">
                        <a-select-option value="-">-</a-select-option>
                        <a-select-option v-for="opt in options.awan_rendah.sudut" :key="opt.text + opt.value"
                          :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                      </a-select>
                    </b-col>
                    <b-col sm="3" class="pr-1 pl-0">
                      <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                        :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                        :value="$v.awan_rendah.sudut[1].$model"
                        @change="(value) => ($v.awan_rendah.sudut[1].$model = value)"
                        :disabled="disabled.awan_rendah.sudut[1]"
                        :class="{ ...ASelectValidate($v.awan_rendah.sudut[1]) }">
                        <a-select-option value="-">-</a-select-option>
                        <a-select-option v-for="opt in options.awan_rendah.sudut" :key="opt.text + opt.value"
                          :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                      </a-select>
                    </b-col>
                    <b-col sm="3" class="pr-1 pl-0"> </b-col>
                  </b-row>

                  <b-row class="mt-1">
                    <b-col sm="3"> </b-col>
                    <b-col sm="3"> </b-col>
                    <b-col sm="3" class="d-flex flex-row align-items-center justify-items-center">
                      <b-form-checkbox class="custom-control-primary" name="check-button" switch
                        v-model="awan_rendah.cloud_2">
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                      </b-form-checkbox>
                    </b-col>
                    <b-col sm="3" class="d-flex flex-row align-items-center justify-items-center">
                      <b-form-checkbox class="custom-control-primary" name="check-button" switch
                        v-model="awan_rendah.cloud_3">
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                      </b-form-checkbox>
                      <feather-icon icon="HelpCircleIcon" size="14" class="bg-primary rounded-circle text-white"
                        v-b-tooltip.hover.v-dark
                        title="Untuk menambahkan jenis awan rendah, silahkan klik simbol di bagian kiri" />
                    </b-col>
                  </b-row>
                </b-col>
              </b-row> -->
            </b-collapse>
          </b-card>
        </b-col>
        <!-- end card6 -->
        <!-- card 7 -->
        <b-col cols="4" class="mb-1">
          <b-card class="h-100">
            <b-row>
              <b-col cols="12">
                <h5 class="mb-1 badge badge-light-dark">AWAN MENENGAH</h5>
              </b-col>
            </b-row>
            <b-collapse visible id="collapse-row-2">

              <b-row  class="">
                <b-col cols="4" style="margin-top:8px">
                  <b-row class="pr-1 pl-1">
                    <label for="cloud_med_type_cm" class="">CM <feather-icon icon="HelpCircleIcon" size="12"
                      class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                      title="Jenis awan menengah" /></label>
                  </b-row>

                  <b-row class="pr-1 pl-1 mt-2">
                    <label for="cloud_med_cover_oktas" style="margin-top:3px">NCM <feather-icon icon="HelpCircleIcon" size="12"
                      class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                      title="Jumlah awan menengah yang menutupi langit dalam oktas" /></label>
                  </b-row>

                  <b-row class="pr-1 pl-1 mt-2">
                    <label for="#" style="margin-top:3px">Jenis <feather-icon icon="HelpCircleIcon" size="12" 
                      class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                      title="Jenis awan C untuk jenis awan menengah"  /></label>
                  </b-row>

                  <b-row class="pr-1 pl-1 mt-2">
                    <label for="#" style="margin-top:3px">Jumlah <feather-icon icon="HelpCircleIcon" size="12"
                      class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                      title="Jumlah awan menengah untuk jenis awan C yang dipilih dalam oktas" /></label>
                  </b-row>

                  <b-row class="pr-1 pl-1 mt-2">
                    <label style="margin-top:3px">Tinggi Dasar (m)<feather-icon icon="HelpCircleIcon" size="12"
                      class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                      title="Tinggi dasar untuk jenis awan C yang dipilih dalam meter" /></label>
                  </b-row>

                  <b-row class="pr-1 pl-1 mt-2">
                    <label for="#">Arah<feather-icon icon="HelpCircleIcon" size="12"
                      class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                      title="Arah dari mana awan CH bergerak" /></label>
                  </b-row>
                </b-col>

                <b-col cols="4" class="">
                  <b-row class="pr-1">
                    <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                      :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                      :value="$v.awan_menengah.CM.$model" @change="(value) => ($v.awan_menengah.CM.$model = value)"
                      :class="{ rangecheck: rangecheck_status('cloud_med_type_cm'), ...ASelectValidate($v.awan_menengah.CM) }"
                      id="cloud_med_type_cm"
                      :disabled="disabled.is_cloud_cover_block">
                      <!-- <a-select-option value="-">-</a-select-option> -->
                      <a-select-option v-for="opt in options.awan_menengah.CM" :key="opt.text + opt.value"
                        :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                    </a-select>
                    <b-tooltip v-if="form_status('cloud_med_type_cm').status" target="cloud_med_type_cm"
                      :triggers="hover">
                      {{ form_status("cloud_med_type_cm").message }}
                    </b-tooltip>
                  </b-row>

                  <b-row class="mt-1 pr-1">
                    <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                      :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                      :value="$v.awan_menengah.NCM.$model" @change="(value) => ($v.awan_menengah.NCM.$model = value)"
                      :class="{ rangecheck: rangecheck_status('cloud_med_cover_oktas'), ...ASelectValidate($v.awan_menengah.NCM) }"
                      :disabled="disabled.awan_menengah.NCM || disabled.is_cloud_cover_block"
                      id="cloud_med_cover_oktas">
                      <!-- <a-select-option value="-">-</a-select-option> -->
                      <a-select-option v-for="opt in options.awan_menengah.NCM" :key="opt.text + opt.value"
                        :value="opt.value">
                        {{ opt.text }}
                      </a-select-option>
                    </a-select>
                    <b-tooltip v-if="form_status('cloud_med_cover_oktas').status" target="cloud_med_cover_oktas"
                      :triggers="hover">
                      {{ form_status("cloud_med_cover_oktas").message }}
                    </b-tooltip>
                  </b-row>

                  <b-row class="mt-1 pr-1">
                    <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                      :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                      :value="$v.awan_menengah.jenis[0].$model"
                      @change="(value) => ($v.awan_menengah.jenis[0].$model = value)"
                      :disabled="disabled.awan_menengah.cloud_1 || disabled.is_cloud_cover_block"
                      :class="{ ...ASelectValidate($v.awan_menengah.jenis[0]) }">
                      <!-- <a-select-option value="-">-</a-select-option> -->
                      <a-select-option v-for="opt in options.awan_menengah.jenis" :key="opt.text + opt.value"
                        :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                    </a-select>
                  </b-row>

                  <b-row class="mt-1 pr-1">
                    <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                      :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                      :value="$v.awan_menengah.jumlah[0].$model"
                      @change="(value) => ($v.awan_menengah.jumlah[0].$model = value)"
                      :disabled="disabled.awan_menengah.cloud_1 || disabled.is_cloud_cover_block"
                      :class="{ ...ASelectValidate($v.awan_menengah.jumlah[0]) }">
                      <!-- <a-select-option value="-">-</a-select-option> -->
                      <a-select-option v-for="opt in options.awan_menengah.jumlah" :key="opt.text + opt.value"
                        :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                    </a-select>
                  </b-row>

                  <b-row class="mt-1 pr-1">
                    <b-form-input :state="validateState($v.awan_menengah.tinggi_dasar[0])"
                      v-model.number="$v.awan_menengah.tinggi_dasar[0].$model" type="number"
                      :class="{ 'text-center': true, rangecheck: rangecheck_status('cloud_med_base_1') }" size="sm"
                      :disabled="disabled.awan_menengah.cloud_1 || disabled.is_cloud_cover_block" id="cloud_med_base_1"
                      @keyup="checkValidate($event, 'cloud_med_base_1')" @keydown="formatInteger"></b-form-input>
                    <!-- <b-tooltip v-if="form_status('cloud_med_base_1').status" target="cloud_med_base_1"
                      triggers="hover">
                      {{ form_status("cloud_med_base_1").message }}
                    </b-tooltip>
                    <b-tooltip :show="validateTooltipStateInput_data('cloud_med_base_1')" :triggers="'hover'"
                      target="cloud_med_base_1" placement="top" custom-class="validation-tooltip">
                      {{ tooltipMessage("input_data_cloud_med_base_1") }}
                    </b-tooltip> -->
                    <b-tooltip v-if="form_status('cloud_med_base_1').status" target="cloud_med_base_1"
                      :triggers="hover">
                      {{ form_status("cloud_med_base_1").message }}
                    </b-tooltip>
                    <b-tooltip v-if="!form_status('cloud_med_base_1').status" :show="validateTooltipStateInput_data('cloud_med_base_1')" :triggers="'hover'"
                      target="cloud_med_base_1" placement="top" custom-class="validation-tooltip">
                      {{ tooltipMessage("input_data_cloud_med_base_1") }}
                    </b-tooltip>
                  </b-row>

                  <b-row class="mt-1 pr-1">
                    <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                      :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                      :value="$v.awan_menengah.arah[0].$model"
                      @change="(value) => ($v.awan_menengah.arah[0].$model = value)"
                      :disabled="disabled.awan_menengah.cloud_1 || disabled.is_cloud_cover_block"
                      :class="{ ...ASelectValidate($v.awan_menengah.arah[0]) }">
                      <!-- <a-select-option value="-">-</a-select-option> -->
                      <a-select-option v-for="opt in options.awan_menengah.arah" :key="opt.text + opt.value"
                        :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                    </a-select>
                  </b-row>
                </b-col>

                <b-col cols="4" class="pr-1" >
                  <b-row class="">

                  </b-row>

                  <b-row class="mt-1">
                    
                  </b-row>

                  <b-row class="mt-5 pr-1">
                    <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%; margin-top:22px"
                      :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                      :value="$v.awan_menengah.jenis[1].$model"
                      @change="(value) => ($v.awan_menengah.jenis[1].$model = value)"
                      :disabled="disabled.awan_menengah.cloud_2"
                      :class="{ ...ASelectValidate($v.awan_menengah.jenis[1]) }">
                      <!-- <a-select-option value="-">-</a-select-option> -->
                      <a-select-option v-for="opt in options.awan_menengah.jenis" :key="opt.text + opt.value"
                        :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                    </a-select>
                  </b-row>

                  <b-row class="mt-1 pr-1">
                    <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                      :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                      :value="$v.awan_menengah.jumlah[1].$model"
                      @change="(value) => ($v.awan_menengah.jumlah[1].$model = value)"
                      :disabled="disabled.awan_menengah.cloud_2"
                      :class="{ ...ASelectValidate($v.awan_menengah.jumlah[1]) }">
                      <!-- <a-select-option value="-">-</a-select-option> -->
                      <a-select-option v-for="opt in options.awan_menengah.jumlah" :key="opt.text + opt.value"
                        :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                    </a-select>
                  </b-row>

                  <b-row class="mt-1 pr-1">
                    <b-form-input :state="validateState($v.awan_menengah.tinggi_dasar[1])"
                      v-model.number="$v.awan_menengah.tinggi_dasar[1].$model" type="number"
                      :class="{ 'text-center': true, rangecheck: rangecheck_status('cloud_med_base_2') }" size="sm"
                      :disabled="disabled.awan_menengah.cloud_2" id="cloud_med_base_2"
                      @keyup="checkValidate($event, 'cloud_med_base_2')" @keydown="formatInteger"></b-form-input>
                    <!-- <b-tooltip v-if="form_status('cloud_med_base_2').status" target="cloud_med_base_2"
                      triggers="hover">
                      {{ form_status("cloud_med_base_2").message }}
                    </b-tooltip> -->
                    <b-tooltip v-if="form_status('cloud_med_base_2').status" target="cloud_med_base_2"
                      :triggers="hover">
                      {{ form_status("cloud_med_base_2").message }}
                    </b-tooltip>
                    <b-tooltip v-if="!form_status('cloud_med_base_2').status" :show="validateTooltipStateInput_data('cloud_med_base_2')" :triggers="'hover'"
                      target="cloud_med_base_2" placement="top" custom-class="validation-tooltip">
                      {{ tooltipMessage("input_data_cloud_med_base_2") }}
                    </b-tooltip>
                  </b-row>

                  <b-row class="mt-1 pr-1">
                    <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                      :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                      :value="$v.awan_menengah.arah[1].$model"
                      @change="(value) => ($v.awan_menengah.arah[1].$model = value)"
                      :disabled="disabled.awan_menengah.cloud_2"
                      :class="{ ...ASelectValidate($v.awan_menengah.arah[1]) }">
                      <!-- <a-select-option value="-">-</a-select-option> -->
                      <a-select-option v-for="opt in options.awan_menengah.arah" :key="opt.text + opt.value"
                        :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                    </a-select>
                  </b-row>

                  <b-row class="mt-1">

                  </b-row>

                  <b-row>
                    <b-col class="d-flex flex-row align-items-center justify-items-center">
                      <b-form-checkbox id="777" class="custom-control-primary ml-3" name="check-button" switch
                        v-model="awan_menengah.cloud_2" :disabled="disabled.is_cloud_cover_block" focusable="false" tabindex="-1">
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" tabindex="-1"/>
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" tabindex="-1"/>
                        </span>
                      </b-form-checkbox>
                      <feather-icon icon="HelpCircleIcon" size="14" class="bg-primary rounded-circle text-white"
                        v-b-tooltip.hover.v-dark
                        title="untuk menambahkan jenis awan menengah, silahkan klik simbol di bagian kiri" 
                        tabindex="-1"/>
                    </b-col>
                  </b-row>
                </b-col>
                
              </b-row>

              <!-- <b-row>
                <b-col>
                  <b-row class="my-1">
                    <b-col sm="4">
                      <label for="cloud_med_type_cm" class="">CM <feather-icon icon="HelpCircleIcon" size="12"
                          class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                          title="Jenis awan menengah" /></label>
                    </b-col>
                    <b-col sm="4" style="font-size: smaller" class="pr-1 pl-0">
                      <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                        :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                        :value="$v.awan_menengah.CM.$model" @change="(value) => ($v.awan_menengah.CM.$model = value)"
                        :class="{ rangecheck: rangecheck_status('cloud_med_type_cm'), ...ASelectValidate($v.awan_menengah.CM) }"
                        id="cloud_med_type_cm">
                        <a-select-option value="-">-</a-select-option>
                        <a-select-option v-for="opt in options.awan_menengah.CM" :key="opt.text + opt.value"
                          :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                      </a-select>
                      <b-tooltip v-if="form_status('cloud_med_type_cm').status" target="cloud_med_type_cm"
                        triggers="hover">
                        {{ form_status("cloud_med_type_cm").message }}
                      </b-tooltip>
                    </b-col>
                  </b-row>

                  <b-row class="my-1">
                    <b-col sm="4">
                      <label for="cloud_med_cover_oktas">NCM <feather-icon icon="HelpCircleIcon" size="12"
                          class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                          title="Jumlah awan menengah yang menutupi langit dalam oktas" /></label>
                    </b-col>
                    <b-col sm="4" class="pr-1 pl-0">
                      <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                        :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                        :value="$v.awan_menengah.NCM.$model" @change="(value) => ($v.awan_menengah.NCM.$model = value)"
                        :class="{ rangecheck: rangecheck_status('cloud_med_cover_oktas'), ...ASelectValidate($v.awan_menengah.NCM) }"
                        id="cloud_med_cover_oktas">
                        <a-select-option value="-">-</a-select-option>
                        <a-select-option v-for="opt in options.awan_menengah.NCM" :key="opt.text + opt.value"
                          :value="opt.value">
                          {{ opt.text }}
                        </a-select-option>
                      </a-select>
                      <b-tooltip v-if="form_status('cloud_med_cover_oktas').status" target="cloud_med_cover_oktas"
                        triggers="hover">
                        {{ form_status("cloud_med_cover_oktas").message }}
                      </b-tooltip>
                    </b-col>
                  </b-row>

                  <b-row class="my-1">
                    <b-col sm="4">
                      <label for="#">Jenis <feather-icon icon="HelpCircleIcon" size="12"
                          class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                          title="Jenis awan C untuk jenis awan menengah" /></label>
                    </b-col>
                    <b-col sm="4" class="pr-1 pl-0">
                      <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                        :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                        :value="$v.awan_menengah.jenis[0].$model"
                        @change="(value) => ($v.awan_menengah.jenis[0].$model = value)"
                        :disabled="disabled.awan_menengah.cloud_1"
                        :class="{ ...ASelectValidate($v.awan_menengah.jenis[0]) }">
                        <a-select-option value="-">-</a-select-option>
                        <a-select-option v-for="opt in options.awan_menengah.jenis" :key="opt.text + opt.value"
                          :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                      </a-select>
                    </b-col>
                    <b-col sm="4" class="pr-1 pl-0">
                      <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                        :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                        :value="$v.awan_menengah.jenis[1].$model"
                        @change="(value) => ($v.awan_menengah.jenis[1].$model = value)"
                        :disabled="disabled.awan_menengah.cloud_2"
                        :class="{ ...ASelectValidate($v.awan_menengah.jenis[1]) }">
                        <a-select-option value="-">-</a-select-option>
                        <a-select-option v-for="opt in options.awan_menengah.jenis" :key="opt.text + opt.value"
                          :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                      </a-select>
                    </b-col>
                  </b-row>

                  <b-row class="my-1">
                    <b-col sm="4">
                      <label for="#">Jumlah <feather-icon icon="HelpCircleIcon" size="12"
                          class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                          title="Jumlah awan menengah untuk jenis awan C yang dipilih dalam oktas" /></label>
                    </b-col>
                    <b-col sm="4" class="pr-1 pl-0">
                      <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                        :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                        :value="$v.awan_menengah.jumlah[0].$model"
                        @change="(value) => ($v.awan_menengah.jumlah[0].$model = value)"
                        :disabled="disabled.awan_menengah.cloud_1"
                        :class="{ ...ASelectValidate($v.awan_menengah.jumlah[0]) }">
                        <a-select-option value="-">-</a-select-option>
                        <a-select-option v-for="opt in options.awan_menengah.jumlah" :key="opt.text + opt.value"
                          :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                      </a-select>
                    </b-col>
                    <b-col sm="4" class="pr-1 pl-0">
                      <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                        :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                        :value="$v.awan_menengah.jumlah[1].$model"
                        @change="(value) => ($v.awan_menengah.jumlah[1].$model = value)"
                        :disabled="disabled.awan_menengah.cloud_2"
                        :class="{ ...ASelectValidate($v.awan_menengah.jumlah[1]) }">
                        <a-select-option value="-">-</a-select-option>
                        <a-select-option v-for="opt in options.awan_menengah.jumlah" :key="opt.text + opt.value"
                          :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                      </a-select>
                    </b-col>
                  </b-row>

                  <b-row class="my-1" style="flex-wrap: nowrap">
                    <b-col sm="4">
                      <label>Tinggi Dasar (m)<feather-icon icon="HelpCircleIcon" size="12"
                          class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                          title="Tinggi dasar untuk jenis awan C yang dipilih dalam meter" /></label>
                    </b-col>
                    <b-col sm="4" class="pr-1 pl-0">
                      <b-form-input :state="validateState($v.awan_menengah.tinggi_dasar[0])"
                        v-model.number="$v.awan_menengah.tinggi_dasar[0].$model" type="number"
                        :class="{ 'text-center': true, rangecheck: rangecheck_status('cloud_med_base_1') }" size="sm"
                        :disabled="disabled.awan_menengah.cloud_1" id="cloud_med_base_1"
                        @keyup="checkValidate($event, 'cloud_med_base_1')" @keydown="formatInteger"></b-form-input>
                      <b-tooltip v-if="form_status('cloud_med_base_1').status" target="cloud_med_base_1"
                        triggers="hover">
                        {{ form_status("cloud_med_base_1").message }}
                      </b-tooltip>
                      <b-tooltip :show="validateTooltipStateInput_data('cloud_med_base_1')" :triggers="'hover'"
                        target="cloud_med_base_1" placement="top" custom-class="validation-tooltip">
                        {{ tooltipMessage("input_data_cloud_med_base_1") }}
                      </b-tooltip>
                    </b-col>
                    <b-col sm="4" class="pr-1 pl-0">
                      <b-form-input :state="validateState($v.awan_menengah.tinggi_dasar[1])"
                        v-model.number="$v.awan_menengah.tinggi_dasar[1].$model" type="number"
                        :class="{ 'text-center': true, rangecheck: rangecheck_status('cloud_med_base_2') }" size="sm"
                        :disabled="disabled.awan_menengah.cloud_2" id="cloud_med_base_2"
                        @keyup="checkValidate($event, 'cloud_med_base_2')" @keydown="formatInteger"></b-form-input>
                      <b-tooltip v-if="form_status('cloud_med_base_2').status" target="cloud_med_base_2"
                        triggers="hover">
                        {{ form_status("cloud_med_base_2").message }}
                      </b-tooltip>
                    </b-col>
                  </b-row>

                  <b-row class="my-1" style="flex-wrap: nowrap">
                    <b-col sm="4">
                      <label for="#">Arah<feather-icon icon="HelpCircleIcon" size="12"
                          class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                          title="Arah dari mana awan CH bergerak" /></label>
                    </b-col>
                    <b-col sm="4" class="pr-1 pl-0">
                      <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                        :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                        :value="$v.awan_menengah.arah[0].$model"
                        @change="(value) => ($v.awan_menengah.arah[0].$model = value)"
                        :disabled="disabled.awan_menengah.cloud_1"
                        :class="{ ...ASelectValidate($v.awan_menengah.arah[0]) }">
                        <a-select-option value="-">-</a-select-option>
                        <a-select-option v-for="opt in options.awan_menengah.arah" :key="opt.text + opt.value"
                          :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                      </a-select>
                    </b-col>
                    <b-col sm="4" class="pr-1 pl-0">
                      <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                        :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                        :value="$v.awan_menengah.arah[1].$model"
                        @change="(value) => ($v.awan_menengah.arah[1].$model = value)"
                        :disabled="disabled.awan_menengah.cloud_2"
                        :class="{ ...ASelectValidate($v.awan_menengah.arah[1]) }">
                        <a-select-option value="-">-</a-select-option>
                        <a-select-option v-for="opt in options.awan_menengah.arah" :key="opt.text + opt.value"
                          :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                      </a-select>
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col sm="3" class=""> </b-col>
                    <b-col sm="3" class=""> </b-col>
                    <b-col sm="3" class=""> </b-col>

                    <b-col sm="3" class="d-flex flex-row align-items-center justify-items-center">
                      <b-form-checkbox class="custom-control-primary" name="check-button" switch
                        v-model="awan_menengah.cloud_2">
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                      </b-form-checkbox>
                      <feather-icon icon="HelpCircleIcon" size="14" class="bg-primary rounded-circle text-white"
                        v-b-tooltip.hover.v-dark
                        title="untuk menambahkan jenis awan menengah, silahkan klik simbol di bagian kiri" />
                    </b-col>
                  </b-row>
                </b-col>
              </b-row> -->
            </b-collapse>
          </b-card>
        </b-col>

        <!-- end card7 -->
        <!-- card 8 -->
        <b-col cols="4" class="mb-1">
          <b-card class="h-100">
            <b-row>
              <b-col cols="10">
                <h5 class="mb-1 badge badge-light-dark">AWAN TINGGI</h5>
              </b-col>
              <b-col cols="2">
                <feather-icon v-b-toggle.collapse-row-2 icon="ChevronDownIcon" tabindex="-1" />
              </b-col>
            </b-row>
            <b-collapse visible id="collapse-row-2">
              <b-row  class="">
                <b-col cols="4" style="margin-top:8px">
                  <b-row class="pr-1 pl-1">
                    <label for="cloud_high_type_ch" class="" >CH <feather-icon icon="HelpCircleIcon" size="12"
                      class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                      title="Jenis awan tinggi" /></label>
                  </b-row>

                  <b-row class="pr-1 pl-1 mt-2">
                    <label for="cloud_high_cover_oktas" style="margin-top:3px">NCH <feather-icon icon="HelpCircleIcon" size="12"
                      class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                      title="Jumlah awan tinggi yang menutupi langit dalam oktas" /></label>
                  </b-row>

                  <b-row class="pr-1 pl-1 mt-2">
                    <label for="#" style="margin-top:3px">Jenis <feather-icon icon="HelpCircleIcon" size="12"
                      class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                      title="Jenis awan C untuk jenis awan tinggi" /></label>
                  </b-row>

                  <b-row class="pr-1 pl-1 mt-2">
                    <label for="#" style="margin-top:3px">Jumlah <feather-icon icon="HelpCircleIcon" size="12"
                      class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                      title="Jumlah awan tinggi untuk jenis awan C yang dipilih dalam oktas" /></label>
                  </b-row>

                  <b-row class="pr-1 pl-1 mt-2">
                    <label style="margin-top:3px">Tinggi Dasar (m) <feather-icon icon="HelpCircleIcon" size="12"
                      class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                      title="Tinggi dasar untuk jenis awan C yang dipilih dalam meter" /></label>
                  </b-row>

                  <b-row class="pr-1 pl-1 mt-2">
                    <label for="#" >Arah <feather-icon icon="HelpCircleIcon" size="12"
                      class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                      title="Arah dari mana awan CH bergerak" /></label>
                  </b-row>
                </b-col>

                <b-col cols="4" class="">
                  <b-row class="pr-1">
                    <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                      :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '18vw' }"
                      :value="$v.awan_tinggi.CH.$model" @change="(value) => ($v.awan_tinggi.CH.$model = value)"
                      :class="{ rangecheck: rangecheck_status('cloud_high_type_ch'), ...ASelectValidate($v.awan_tinggi.CH) }"
                      id="cloud_high_type_ch"
                      :disabled="disabled.is_cloud_cover_block">
                      <!-- <a-select-option value="-">-</a-select-option> -->
                      <a-select-option v-for="opt in options.awan_tinggi.CH" :key="opt.text + opt.value"
                        :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                    </a-select>
                    <b-tooltip v-if="form_status('cloud_high_type_ch').status" target="cloud_high_type_ch"
                      triggers="hover">
                      {{ form_status("cloud_high_type_ch").message }}
                    </b-tooltip>
                  </b-row>

                  <b-row class="mt-1 pr-1">
                    <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                      :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '18vw' }"
                      :value="$v.awan_tinggi.NCH.$model" @change="(value) => ($v.awan_tinggi.NCH.$model = value)"
                      :class="{ rangecheck: rangecheck_status('cloud_high_cover_oktas'), ...ASelectValidate($v.awan_tinggi.NCH) }"
                      :disabled="disabled.awan_tinggi.NCH || disabled.is_cloud_cover_block"
                      id="cloud_high_cover_oktas">
                      <!-- <a-select-option value="-">-</a-select-option> -->
                      <a-select-option v-for="opt in options.awan_tinggi.NCH" :key="opt.text + opt.value"
                        :value="opt.value"> {{ opt.text }} </a-select-option>
                    </a-select>
                    <b-tooltip v-if="form_status('cloud_high_cover_oktas').status" target="cloud_high_cover_oktas"
                      triggers="hover">
                      {{ form_status("cloud_high_cover_oktas").message }}
                    </b-tooltip>
                  </b-row>

                  <b-row class="mt-1 pr-1">
                    <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                      :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                      :value="$v.awan_tinggi.jenis[0].$model"
                      @change="(value) => ($v.awan_tinggi.jenis[0].$model = value)"
                      :disabled="disabled.awan_tinggi.cloud_1 || disabled.is_cloud_cover_block"
                      :class="{ ...ASelectValidate($v.awan_tinggi.jenis[0]) }">
                      <!-- <a-select-option value="-">-</a-select-option> -->
                      <a-select-option v-for="opt in options.awan_tinggi.jenis" :key="opt.text + opt.value"
                        :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                    </a-select>
                  </b-row>

                  <b-row class="mt-1 pr-1">
                    <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                      :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                      :value="$v.awan_tinggi.jumlah[0].$model"
                      @change="(value) => ($v.awan_tinggi.jumlah[0].$model = value)"
                      :disabled="disabled.awan_tinggi.cloud_1 || disabled.is_cloud_cover_block"
                      :class="{ ...ASelectValidate($v.awan_tinggi.jumlah[0]) }">
                      <!-- <a-select-option value="-">-</a-select-option> -->
                      <a-select-option v-for="opt in options.awan_tinggi.jumlah" :key="opt.text + opt.value"
                        :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                    </a-select>
                  </b-row>

                  <b-row class="mt-1 pr-1">
                    <b-form-input :state="validateState($v.awan_tinggi.tinggi_dasar[0])"
                      v-model.number="$v.awan_tinggi.tinggi_dasar[0].$model" type="number"
                      :class="{ 'text-center': true, rangecheck: rangecheck_status('cloud_high_base_1') }" size="sm"
                      :disabled="disabled.awan_tinggi.cloud_1 || disabled.is_cloud_cover_block" id="cloud_high_base_1"
                      @keyup="checkValidate($event, 'cloud_high_base_1')" @keydown="formatInteger"></b-form-input>
                    <!-- <b-tooltip v-if="form_status('cloud_high_base_1').status" target="cloud_high_base_1"
                      triggers="hover">
                      {{ form_status("cloud_high_base_1").message }}
                    </b-tooltip>
                    <b-tooltip :show="validateTooltipStateInput_data('cloud_high_base_1')" :triggers="'hover'"
                      target="cloud_high_base_1" placement="top" custom-class="validation-tooltip">
                      {{ tooltipMessage("input_data_cloud_high_base_1") }}
                    </b-tooltip> -->
                    <b-tooltip v-if="form_status('cloud_high_base_1').status" target="cloud_high_base_1"
                      triggers="hover">
                      {{ form_status("cloud_high_base_1").message }}
                    </b-tooltip>
                    <b-tooltip v-if="!form_status('cloud_high_base_1').status" :show="validateTooltipStateInput_data('cloud_high_base_1')" :triggers="'hover'"
                      target="cloud_high_base_1" placement="top" custom-class="validation-tooltip">
                      {{ tooltipMessage("input_data_cloud_high_base_1") }}
                    </b-tooltip>
                  </b-row>

                  <b-row class="mt-1 pr-1">
                    <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                      :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                      :value="$v.awan_tinggi.arah[0].$model"
                      @change="(value) => ($v.awan_tinggi.arah[0].$model = value)"
                      :disabled="disabled.awan_tinggi.cloud_1 || disabled.is_cloud_cover_block"
                      :class="{ ...ASelectValidate($v.awan_tinggi.arah[0]) }">
                      <!-- <a-select-option value="-">-</a-select-option> -->
                      <a-select-option v-for="opt in options.awan_tinggi.arah" :key="opt.text + opt.value"
                        :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                    </a-select>
                  </b-row>
                </b-col>

                <b-col cols="4" class="pr-1" >
                  <b-row class="">

                  </b-row>

                  <b-row class="mt-1">
                    
                  </b-row>

                  <b-row class="mt-5 pr-1">
                    <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%; margin-top:22px"
                      :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                      :value="$v.awan_tinggi.jenis[1].$model"
                      @change="(value) => ($v.awan_tinggi.jenis[1].$model = value)"
                      :disabled="disabled.awan_tinggi.cloud_2 || disabled.is_cloud_cover_block"
                      :class="{ ...ASelectValidate($v.awan_tinggi.jenis[1]) }">
                      <!-- <a-select-option value="-">-</a-select-option> -->
                      <a-select-option v-for="opt in options.awan_tinggi.jenis" :key="opt.text + opt.value"
                        :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                    </a-select>
                  </b-row>

                  <b-row class="mt-1 pr-1">
                    <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                      :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                      :value="$v.awan_tinggi.jumlah[1].$model"
                      @change="(value) => ($v.awan_tinggi.jumlah[1].$model = value)"
                      :disabled="disabled.awan_tinggi.cloud_2 || disabled.is_cloud_cover_block"
                      :class="{ ...ASelectValidate($v.awan_tinggi.jumlah[1]) }">
                      <!-- <a-select-option value="-">-</a-select-option> -->
                      <a-select-option v-for="opt in options.awan_tinggi.jumlah" :key="opt.text + opt.value"
                        :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                    </a-select>
                  </b-row>

                  <b-row class="mt-1 pr-1">
                    <b-form-input :state="validateState($v.awan_tinggi.tinggi_dasar[1])"
                      v-model.number="$v.awan_tinggi.tinggi_dasar[1].$model" type="number"
                      :class="{ 'text-center': true, rangecheck: rangecheck_status('cloud_high_base_2') }" size="sm"
                      :disabled="disabled.awan_tinggi.cloud_2 || disabled.is_cloud_cover_block" id="cloud_high_base_2"
                      @keyup="checkValidate($event, 'cloud_high_base_2')" @keydown="formatInteger"></b-form-input>
                    <!-- <b-tooltip v-if="form_status('cloud_high_base_2').status" target="cloud_high_base_2"
                      triggers="hover">
                      {{ form_status("cloud_high_base_2").message }}
                    </b-tooltip> -->
                    <b-tooltip v-if="form_status('cloud_high_base_2').status" target="cloud_high_base_2"
                      triggers="hover">
                      {{ form_status("cloud_high_base_2").message }}
                    </b-tooltip>
                    <b-tooltip v-if="!form_status('cloud_high_base_2').status" :show="validateTooltipStateInput_data('cloud_high_base_2')" :triggers="'hover'"
                      target="cloud_high_base_2" placement="top" custom-class="validation-tooltip">
                      {{ tooltipMessage("input_data_cloud_high_base_2") }}
                    </b-tooltip>
                  </b-row>

                  <b-row class="mt-1 pr-1">
                    <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                      :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                      :value="$v.awan_tinggi.arah[1].$model"
                      @change="(value) => ($v.awan_tinggi.arah[1].$model = value)"
                      :disabled="disabled.awan_tinggi.cloud_2 || disabled.is_cloud_cover_block"
                      :class="{ ...ASelectValidate($v.awan_tinggi.arah[1]) }">
                      <!-- <a-select-option value="-">-</a-select-option> -->
                      <a-select-option v-for="opt in options.awan_tinggi.arah" :key="opt.text + opt.value"
                        :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                    </a-select>
                  </b-row>

                  <b-row class="mt-1 ">

                  </b-row>

                  <b-row class="">
                    <b-col sm="3" class="d-flex flex-row align-items-center justify-items-center">
                      <b-form-checkbox id=778 class="custom-control-primary ml-3" name="check-button" switch
                        v-model="awan_tinggi.cloud_2" :disabled="disabled.is_cloud_cover_block" tabindex="-1">
                        <span class="switch-icon-left"> </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" tabindex="-1"/>
                        </span>
                      </b-form-checkbox>
                      <feather-icon icon="HelpCircleIcon" size="14" class="bg-primary rounded-circle text-white"
                        v-b-tooltip.hover.v-dark
                        title="Untuk menambahkan jenis awan tinggi, silahkan klik simbol di bagian kiri" 
                        tabindex="-1"/>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <!-- <b-row>
                <b-col>
                  <b-row class="my-1">
                    <b-col sm="4">
                      <label for="cloud_high_type_ch" class="">CH <feather-icon icon="HelpCircleIcon" size="12"
                          class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                          title="Jenis awan tinggi" /></label>
                    </b-col>
                    <b-col sm="4" style="font-size: smaller" class="pr-1 pl-0">
                      <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                        :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '18vw' }"
                        :value="$v.awan_tinggi.CH.$model" @change="(value) => ($v.awan_tinggi.CH.$model = value)"
                        :class="{ rangecheck: rangecheck_status('cloud_high_type_ch'), ...ASelectValidate($v.awan_tinggi.CH) }"
                        id="cloud_high_type_ch">
                        <a-select-option value="-">-</a-select-option>
                        <a-select-option v-for="opt in options.awan_tinggi.CH" :key="opt.text + opt.value"
                          :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                      </a-select>
                      <b-tooltip v-if="form_status('cloud_high_type_ch').status" target="cloud_high_type_ch"
                        triggers="hover">
                        {{ form_status("cloud_high_type_ch").message }}
                      </b-tooltip>
                    </b-col>
                  </b-row>

                  <b-row class="my-1">
                    <b-col sm="4">
                      <label for="cloud_high_cover_oktas">NCH <feather-icon icon="HelpCircleIcon" size="12"
                          class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                          title="Jumlah awan tinggi yang menutupi langit dalam oktas" /></label>
                    </b-col>
                    <b-col sm="4" class="pr-1 pl-0">
                      <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                        :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '18vw' }"
                        :value="$v.awan_tinggi.NCH.$model" @change="(value) => ($v.awan_tinggi.NCH.$model = value)"
                        :class="{ rangecheck: rangecheck_status('cloud_high_cover_oktas'), ...ASelectValidate($v.awan_tinggi.NCH) }"
                        id="cloud_high_cover_oktas">
                        <a-select-option value="-">-</a-select-option>
                        <a-select-option v-for="opt in options.awan_tinggi.NCH" :key="opt.text + opt.value"
                          :value="opt.value"> {{ opt.text }} </a-select-option>
                      </a-select>
                      <b-tooltip v-if="form_status('cloud_high_cover_oktas').status" target="cloud_high_cover_oktas"
                        triggers="hover">
                        {{ form_status("cloud_high_cover_oktas").message }}
                      </b-tooltip>
                    </b-col>
                  </b-row>

                  <b-row class="my-1">
                    <b-col sm="4">
                      <label for="#">Jenis <feather-icon icon="HelpCircleIcon" size="12"
                          class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                          title="Jenis awan C untuk jenis awan tinggi" /></label>
                    </b-col>
                    <b-col sm="4" class="pr-1 pl-0">
                      <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                        :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                        :value="$v.awan_tinggi.jenis[0].$model"
                        @change="(value) => ($v.awan_tinggi.jenis[0].$model = value)"
                        :disabled="disabled.awan_tinggi.cloud_1"
                        :class="{ ...ASelectValidate($v.awan_tinggi.jenis[0]) }">
                        <a-select-option value="-">-</a-select-option>
                        <a-select-option v-for="opt in options.awan_tinggi.jenis" :key="opt.text + opt.value"
                          :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                      </a-select>
                    </b-col>
                    <b-col sm="4" class="pr-1 pl-0">
                      <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                        :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                        :value="$v.awan_tinggi.jenis[1].$model"
                        @change="(value) => ($v.awan_tinggi.jenis[1].$model = value)"
                        :disabled="disabled.awan_tinggi.cloud_2"
                        :class="{ ...ASelectValidate($v.awan_tinggi.jenis[1]) }">
                        <a-select-option value="-">-</a-select-option>
                        <a-select-option v-for="opt in options.awan_tinggi.jenis" :key="opt.text + opt.value"
                          :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                      </a-select>
                    </b-col>
                  </b-row>

                  <b-row class="my-1">
                    <b-col sm="4">
                      <label for="#">Jumlah <feather-icon icon="HelpCircleIcon" size="12"
                          class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                          title="Jumlah awan tinggi untuk jenis awan C yang dipilih dalam oktas" /></label>
                    </b-col>
                    <b-col sm="4" class="pr-1 pl-0">
                      <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                        :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                        :value="$v.awan_tinggi.jumlah[0].$model"
                        @change="(value) => ($v.awan_tinggi.jumlah[0].$model = value)"
                        :disabled="disabled.awan_tinggi.cloud_1"
                        :class="{ ...ASelectValidate($v.awan_tinggi.jumlah[0]) }">
                        <a-select-option value="-">-</a-select-option>
                        <a-select-option v-for="opt in options.awan_tinggi.jumlah" :key="opt.text + opt.value"
                          :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                      </a-select>
                    </b-col>
                    <b-col sm="4" class="pr-1 pl-0">
                      <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                        :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                        :value="$v.awan_tinggi.jumlah[1].$model"
                        @change="(value) => ($v.awan_tinggi.jumlah[1].$model = value)"
                        :disabled="disabled.awan_tinggi.cloud_2"
                        :class="{ ...ASelectValidate($v.awan_tinggi.jumlah[1]) }">
                        <a-select-option value="-">-</a-select-option>
                        <a-select-option v-for="opt in options.awan_tinggi.jumlah" :key="opt.text + opt.value"
                          :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                      </a-select>
                    </b-col>
                  </b-row>

                  <b-row class="my-1" style="flex-wrap: nowrap">
                    <b-col sm="4">
                      <label>Tinggi Dasar (m) <feather-icon icon="HelpCircleIcon" size="12"
                          class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                          title="Tinggi dasar untuk jenis awan C yang dipilih dalam meter" /></label>
                    </b-col>
                    <b-col sm="4" class="pr-1 pl-0">
                      <b-form-input :state="validateState($v.awan_tinggi.tinggi_dasar[0])"
                        v-model.number="$v.awan_tinggi.tinggi_dasar[0].$model" type="number"
                        :class="{ 'text-center': true, rangecheck: rangecheck_status('cloud_high_base_1') }" size="sm"
                        :disabled="disabled.awan_tinggi.cloud_1" id="cloud_high_base_1"
                        @keyup="checkValidate($event, 'cloud_high_base_1')" @keydown="formatInteger"></b-form-input>
                      <b-tooltip v-if="form_status('cloud_high_base_1').status" target="cloud_high_base_1"
                        triggers="hover">
                        {{ form_status("cloud_high_base_1").message }}
                      </b-tooltip>
                      <b-tooltip :show="validateTooltipStateInput_data('cloud_high_base_1')" :triggers="'hover'"
                        target="cloud_high_base_1" placement="top" custom-class="validation-tooltip">
                        {{ tooltipMessage("input_data_cloud_high_base_1") }}
                      </b-tooltip>
                    </b-col>
                    <b-col sm="4" class="pr-1 pl-0">
                      <b-form-input :state="validateState($v.awan_tinggi.tinggi_dasar[1])"
                        v-model.number="$v.awan_tinggi.tinggi_dasar[1].$model" type="number"
                        :class="{ 'text-center': true, rangecheck: rangecheck_status('cloud_high_base_2') }" size="sm"
                        :disabled="disabled.awan_tinggi.cloud_2" id="cloud_high_base_2"
                        @keyup="checkValidate($event, 'cloud_high_base_2')" @keydown="formatInteger"></b-form-input>
                      <b-tooltip v-if="form_status('cloud_high_base_2').status" target="cloud_high_base_2"
                        triggers="hover">
                        {{ form_status("cloud_high_base_2").message }}
                      </b-tooltip>
                    </b-col>
                  </b-row>

                  <b-row class="my-1" style="flex-wrap: nowrap">
                    <b-col sm="4">
                      <label for="#">Arah <feather-icon icon="HelpCircleIcon" size="12"
                          class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                          title="Arah dari mana awan CH bergerak" /></label>
                    </b-col>
                    <b-col sm="4" class="pr-1 pl-0">
                      <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                        :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                        :value="$v.awan_tinggi.arah[0].$model"
                        @change="(value) => ($v.awan_tinggi.arah[0].$model = value)"
                        :disabled="disabled.awan_tinggi.cloud_1"
                        :class="{ ...ASelectValidate($v.awan_tinggi.arah[0]) }">
                        <a-select-option value="-">-</a-select-option>
                        <a-select-option v-for="opt in options.awan_tinggi.arah" :key="opt.text + opt.value"
                          :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                      </a-select>
                    </b-col>
                    <b-col sm="4" class="pr-1 pl-0">
                      <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                        :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '25vw' }"
                        :value="$v.awan_tinggi.arah[1].$model"
                        @change="(value) => ($v.awan_tinggi.arah[1].$model = value)"
                        :disabled="disabled.awan_tinggi.cloud_2"
                        :class="{ ...ASelectValidate($v.awan_tinggi.arah[1]) }">
                        <a-select-option value="-">-</a-select-option>
                        <a-select-option v-for="opt in options.awan_tinggi.arah" :key="opt.text + opt.value"
                          :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                      </a-select>
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col sm="3"> </b-col>
                    <b-col sm="3"> </b-col>
                    <b-col sm="3"> </b-col>

                    <b-col sm="3" class="d-flex flex-row align-items-center justify-items-center">
                      <b-form-checkbox class="custom-control-primary" name="check-button" switch
                        v-model="awan_tinggi.cloud_2">
                        <span class="switch-icon-left"> </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                      </b-form-checkbox>
                      <feather-icon icon="HelpCircleIcon" size="14" class="bg-primary rounded-circle text-white"
                        v-b-tooltip.hover.v-dark
                        title="Untuk menambahkan jenis awan tinggi, silahkan klik simbol di bagian kiri" />
                    </b-col>
                  </b-row>
                </b-col>
              </b-row> -->
            </b-collapse>
          </b-card>
        </b-col>
      </b-row>
      <!-- end card9 -->
      <!-- tess -->
      <b-row>
        <b-col cols="12" class="mb-1">
          <b-card class="small h-100">
            <b-row class="mb-1">
              <b-col sm="4">
                <label for="#" class="font-weight-bolder text-dark mb-1 badge badge-light-dark">PENGUAPAN</label>
              </b-col>
              <b-col sm="4">
                <label for="#" class="font-weight-bolder text-dark mb-1 badge badge-light-dark">MATAHARI</label>
              </b-col>
              <b-col sm="4">
                <label for="#" class="font-weight-bolder text-dark mb-1 badge badge-light-dark">TANAH</label>
              </b-col>
            </b-row>

            <b-row class="my-0">
              <b-col sm="2" >
                <label for="evaporation_24hours_mm_eee" >Penguapan (mm)
                  <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white"
                    v-b-tooltip.hover.v-dark
                    title="Jumlah penguapan selama 24 jam yang lalu dalam milimeter, input nilai dalam persepuluhan" /></label>
              </b-col>
              <b-col sm="2" style="margin-bottom: 4px">
                <b-form-input :state="validateState($v.input_data.evaporation_24hours_mm_eee)"
                  v-model.number="$v.input_data.evaporation_24hours_mm_eee.$model" type="number" size="sm"
                  :disabled="disabled.evaporation_24hours_mm_eee"
                  :class="{ 'text-center': true, rangecheck: rangecheck_status('evaporation_24hours_mm_eee') }"
                  id="evaporation_24hours_mm_eee"
                  @keyup="checkValidate(formatPersepuluhan($event), 'evaporation_24hours_mm_eee')"
                  v-on:keydown="onKeyCheck($event)"></b-form-input>
                <b-tooltip v-if="form_status('evaporation_24hours_mm_eee').status" target="evaporation_24hours_mm_eee"
                  triggers="hover">
                  {{ form_status("evaporation_24hours_mm_eee").message }}
                </b-tooltip>
                <!-- tooltips GC-->
                <b-tooltip :show="validateTooltipStateInput_data('evaporation_24hours_mm_eee')" :triggers="'hover'"
                  target="evaporation_24hours_mm_eee" placement="top" custom-class="validation-tooltip">
                  {{ tooltipMessage("input_data_evaporation_24hours_mm_eee") }}
                </b-tooltip>
              </b-col>
              <b-col sm="2">
                <label for="solar_rad_24h_jcm2_f24">Radiasi Matahari (Joule/cm2)
                  <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white"
                    v-b-tooltip.hover.v-dark
                    title="Radiasi total selama 24 jam yang lalu dalam Joule/cm2, input nilai dalam persepuluhan" /></label>
              </b-col>
              <b-col sm="2">
                <b-form-input :state="validateState($v.input_data.solar_rad_24h_jcm2_f24)"
                  v-model.number="$v.input_data.solar_rad_24h_jcm2_f24.$model" type="number" size="sm"
                  :disabled="disabled.solar_rad_24h_jcm2_f24"
                  :class="{ 'text-center': true, rangecheck: rangecheck_status('solar_rad_24h_jcm2_f24') }"
                  id="solar_rad_24h_jcm2_f24"
                  @keyup="checkValidate(formatPersepuluhan($event), 'solar_rad_24h_jcm2_f24')"
                  v-on:keydown="onKeyCheck($event)"></b-form-input>
                <b-tooltip v-if="form_status('solar_rad_24h_jcm2_f24').status" target="solar_rad_24h_jcm2_f24"
                  triggers="hover">
                  {{ form_status("solar_rad_24h_jcm2_f24").message }}
                </b-tooltip>
                <!-- tooltips GC-->
                <b-tooltip :show="validateTooltipStateInput_data('solar_rad_24h_jcm2_f24')" :triggers="'hover'"
                  target="solar_rad_24h_jcm2_f24" placement="top" custom-class="validation-tooltip">
                  {{ tooltipMessage("input_data_solar_rad_24h_jcm2_f24") }}
                </b-tooltip>
              </b-col>

              <b-col sm="2">
                <label for="land_cond">Keadaan Tanah <feather-icon icon="HelpCircleIcon" size="12"
                    class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                    title="Menjelaskan keadaan tanah saat pengamatan" /></label>
              </b-col>

              <b-col sm="2">
                <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                  :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '18vw' }"
                  :value="$v.input_data.land_cond.$model" @change="(value) => ($v.input_data.land_cond.$model = value)"
                  :class="{ ...ASelectValidate($v.input_data.land_cond) }" :disabled="disabled.land_cond"
                  id="land_cond">
                  <!-- <a-select-option value="">-</a-select-option> -->
                  <a-select-option v-for="opt in options.land_cond" :key="opt.text + opt.value" :value="opt.value"> {{
                    opt.value
                  }} - {{ opt.text }} </a-select-option>
                </a-select>
              </b-col>
            </b-row>

            <b-row>
              <b-col sm="2">
                <label for="evaporation_eq_indicator_ie">Pengenal Data Penguapan(IE) <feather-icon icon="HelpCircleIcon"
                    size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark
                    title="Pengenal data untuk alat penguapan" /></label>
              </b-col>
              <b-col sm="2">
                <a-select show-search :filter-option="filterOption" :not-found-content="null" style="width: 100%"
                  :dropdownMatchSelectWidth="false" :dropdownStyle="{ width: '18vw' }"
                  :value="$v.input_data.evaporation_eq_indicator_ie.$model"
                  @change="(value) => ($v.input_data.evaporation_eq_indicator_ie.$model = value)"
                  :class="{ ...ASelectValidate($v.input_data.evaporation_eq_indicator_ie) }"
                  :disabled="disabled.evaporation_eq_indicator_ie" id="evaporation_eq_indicator_ie">
                  <!-- <a-select-option value="">-</a-select-option> -->
                  <a-select-option v-for="opt in options.evaporation_eq_indicator_ie" :key="opt.text + opt.value"
                    :value="opt.value"> {{ opt.value }} - {{ opt.text }} </a-select-option>
                </a-select>
              </b-col>

              <b-col sm="2">
                <label for="sunshine_h_sss">Lama Penyinaran Matahari (jam)
                  <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white"
                    v-b-tooltip.hover.v-dark
                    title="Jumlah lamanya matahari selama 24 jam yang lalu dalam jam, input data dalam persepuluhan" /></label>
              </b-col>
              <b-col sm="2">
                <b-form-input :state="validateState($v.input_data.sunshine_h_sss)"
                  v-model.number="$v.input_data.sunshine_h_sss.$model" type="number" size="sm"
                  :disabled="disabled.sunshine_h_sss"
                  :class="{ 'text-center': true, rangecheck: rangecheck_status('sunshine_h_sss') }" id="sunshine_h_sss"
                  @keyup="checkValidate(formatPersepuluhan($event), 'sunshine_h_sss')"
                  v-on:keydown="onKeyCheck($event)"></b-form-input>
                <b-tooltip v-if="form_status('sunshine_h_sss').status" target="sunshine_h_sss" triggers="hover">
                  {{ form_status("sunshine_h_sss").message }}
                </b-tooltip>
                <!-- tooltips GC-->
                <b-tooltip :show="validateTooltipStateInput_data('sunshine_h_sss')" :triggers="'hover'"
                  target="sunshine_h_sss" placement="top" custom-class="validation-tooltip">
                  {{ tooltipMessage("input_data_sunshine_h_sss") }}
                </b-tooltip>
              </b-col>
              <b-col sm="2">
                <label for="land_note">Catatan
                  <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white"
                    v-b-tooltip.hover.v-dark
                    title="Catatan untuk hal - hal yang ekstrim terkait keadaan tanah, misalnya air bah, banjir dan lainnya" /></label>
              </b-col>
              <b-col sm="2">
                <b-form-input v-model="input_data.land_note" id="land_note" size="sm"></b-form-input>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <!-- end tess -->
      <!-- card10 button -->
      <b-row>
        <b-col cols="12">
          <b-card>
            <b-row class="my-1">
              <b-col cols="2" class="d-flex align-items-center">
                <label for="textarea-sandi" class="font-weight-bolder badge badge-light-dark">SANDI</label>
              </b-col>
              <b-col cols="10" class="d-flex justify-content-end">
                <b-row align-v="center" align-h="end" class="flex-row-reverse">
                  <!-- <b-button variant="primary" class="mr-1 float-right" size="sm" @click="submitForm()" :disabled="disabled.submit">
                    <b-icon icon="cursor-fill"></b-icon>
                    {{ isEdit ? "Update" : "Send" }}
                  </b-button> -->
                  <b-button variant="primary" class="mr-1" size="sm" @click="sendGtsMessage()"
                    :disabled="disabled.submit" >
                    Send
                  </b-button>
                  <b-button variant="primary" class="mr-1" size="sm" @click="submitForm()"
                    :disabled="disabled.submit || !roles.isRoleEdit" v-if="isEdit">
                    <!-- <b-icon icon="cursor-fill"></b-icon> -->
                    {{ isEdit ? "Update" : "Submit" }}
                  </b-button>
                  <b-button variant="primary" class="mr-1" size="sm" @click="submitForm()"
                    :disabled="disabled.submit || !roles.isRoleAdd" v-if="!isEdit">
                    <!-- <b-icon icon="cursor-fill"></b-icon> -->
                    {{ isEdit ? "Update" : "Submit" }}
                  </b-button>
                  <b-button variant="warning" class="mr-1" size="sm" @click="showPreview()">
                    <!-- <b-icon icon="eye-fill"></b-icon> -->
                    Preview
                  </b-button>
                  <b-form-checkbox
                    class="mr-1 float-right"
                    v-model="sandiEditable"
                    value=1
                  >
                    Edit Sandi
                  </b-form-checkbox>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-textarea id="textarea-sandi" placeholder="Sandi" v-model="sandi_with_header" :disabled="sandiEditable == 0"
                  class="font-weight-bolder text-dark textsandi" rows="4"></b-form-textarea>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <!-- end button -->
    </div>

    <!--end:card utama-->
    <!-- MODAL START -->
    <b-modal id="modal-preview" title="Preview" centered size="xl" button-size="sm" ok-only>
      <b-table-simple bordered small responsive id="table-preview">
        <b-thead>
          <b-tr>
            <b-th colspan="3">Angin - Penglihatan - Keadaan Cuaca</b-th>
            <b-th colspan="2">Tekanan Udara</b-th>
            <b-th colspan="2">Suhu Udara</b-th>
            <b-th colspan="1">Suhu Ekstrim</b-th>
            <b-th colspan="1">Endapan</b-th>
            <b-th colspan="9">Perawanan</b-th>
            <b-th colspan="3">Lapisan Awan</b-th>
            <b-th colspan="2">Penyinaran Matahari</b-th>
            <b-th colspan="1">Tanah/Catatan</b-th>
            <b-th colspan="1">Observer</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <!-- ROW 1 START -->
          <b-tr class="table-preview-label">
            <b-td>
              <label> Pengenal Data Angin (iw) </label>
            </b-td>
            <b-td rowspan="4">
              <label class="text-vertical"> Penglihatan Mendatar (km) </label>
            </b-td>
            <b-td>
              <label> Pengenal Data Cuaca (ix) </label>
            </b-td>
            <b-td>
              <label> Derajat Panas (ºC) </label>
            </b-td>
            <b-td>
              <label> QFF (mb) </label>
            </b-td>
            <b-td rowspan="2">
              <label> Bola Kering (ºC) </label>
            </b-td>
            <b-td rowspan="2">
              <label> Titik Embun (ºC) </label>
            </b-td>
            <b-td rowspan="2">
              <label> Maksimum (ºC) </label>
            </b-td>
            <b-td>
              <label> Pengenal Data Hujan (iR) </label>
            </b-td>
            <b-td rowspan="2" colspan="3">
              <label> Awan Rendah (jenis) CL </label>
            </b-td>
            <!-- COL 10 -->
            <!-- COL 11 -->
            <b-td>
              <label> Arah CL1 (derajat) </label>
            </b-td>
            <b-td>
              <label> Sudut Elevasi CL1 (derajat) </label>
            </b-td>
            <b-td rowspan="2">
              <label> Awan Menengah (banyaknya) </label>
            </b-td>
            <b-td rowspan="2">
              <label> Awan Menengah (jenis) CM </label>
            </b-td>
            <b-td>
              <label> Arah (derajat) </label>
            </b-td>
            <b-td rowspan="4">
              <label>
                Bagian langit yang tertutup awan (okta)
              </label>
            </b-td>
            <b-td>
              <label> Jenis C1 </label>
            </b-td>
            <b-td>
              <label> Tingginya hshs1 </label>
            </b-td>
            <b-td>
              <label> Banyaknya (okta) NS1 </label>
            </b-td>
            <b-td>
              <label> Pengenal data alat penguapan (iE) </label>
            </b-td>
            <b-td>
              <label>
                Jumlah penguapan selama 24 jam yang lalu (mm)
              </label>
            </b-td>
            <b-td rowspan="2">
              <label> Keadaan Tanah </label>
            </b-td>
            <b-td rowspan="4">
              <label> Nama Terang </label>
            </b-td>
          </b-tr>
          <!-- ROW 1 END -->
          <!-- ROW 2 START -->
          <b-tr class="table-preview-label">
            <b-td>
              <label> Arah Angin (derajat) </label>
            </b-td>
            <!-- COL 2 -->
            <b-td>
              <label> Keadaan Cuaca Waktu Pengamatan </label>
            </b-td>
            <b-td>
              <label> Tekanan Dibaca (mb) </label>
            </b-td>
            <b-td>
              <label> QFE (mb) </label>
            </b-td>
            <!-- COL 6 -->
            <!-- COL 7 -->
            <!-- COL 8 -->
            <b-td>
              <label> Hujan Sejak Takaran Terakhir (mm) </label>
            </b-td>
            <!-- COL 10 -->
            <!-- COL 11 -->
            <b-td>
              <label> Arah CL2 (derajat) </label>
            </b-td>
            <b-td>
              <label> Sudut Elevasi CL2 (derajat) </label>
            </b-td>
            <!-- COL 14 -->
            <!-- COL 15 -->
            <b-td>
              <label> Tingginya (m) </label>
            </b-td>
            <!-- COL 17 -->
            <b-td>
              <label> Jenis C2 </label>
            </b-td>
            <b-td>
              <label> Tingginya hshs2 </label>
            </b-td>
            <b-td>
              <label> Banyaknya (okta) NS2 </label>
            </b-td>
            <b-td colspan="2">
              <label>
                Radiasi total selama 24 jam yang lalu (joule/cm²)
              </label>
            </b-td>
            <!-- COL 22 -->
            <!-- COL 23 -->
            <!-- COL 24 -->
          </b-tr>
          <!-- ROW 2 END -->
          <!-- ROW 3 START -->
          <b-tr class="table-preview-label">
            <b-td rowspan="2">
              <label> Kecepatan Angin (knot) </label>
            </b-td>
            <!-- COL 2 -->
            <b-td>
              <label> Keadaan Cuaca Waktu yang lalu 1 </label>
            </b-td>
            <b-td rowspan="2">
              <label> Perubahan Tekanan Udara 3 jam (mb) </label>
            </b-td>
            <b-td rowspan="2">
              <label> Perubahan Tekanan Udara 24 jam (mb) </label>
            </b-td>
            <b-td rowspan="2">
              <label> Bola Basah (ºC) </label>
            </b-td>
            <b-td rowspan="2">
              <label> Lembab Nisbi (%) </label>
            </b-td>
            <b-td rowspan="2">
              <label> Minimum (ºC) </label>
            </b-td>
            <b-td>
              <label> Hujan sejak 6 jam yang lalu (mm) </label>
            </b-td>
            <b-td>
              <label> Tinggi Dasar CL1 (m) h </label>
            </b-td>
            <b-td>
              <label> Tinggi Dasar CL2 (m) h </label>
            </b-td>
            <b-td>
              <label> Tinggi Dasar CL3 (m) h </label>
            </b-td>
            <b-td colspan="2" rowspan="2">
              <label> Banyaknya (okta)</label>
            </b-td>
            <!-- COL 13 -->
            <b-td rowspan="2">
              <label> Awan Tinggi (banyaknya) </label>
            </b-td>
            <b-td rowspan="2">
              <label> Awan Tinggi (jenis) CH </label>
            </b-td>
            <b-td>
              <label> Arah (derajat) </label>
            </b-td>
            <!-- COL 17 -->
            <b-td>
              <label> Jenis C3 </label>
            </b-td>
            <b-td>
              <label> Tingginya hshs3 </label>
            </b-td>
            <b-td>
              <label> Banyaknya (okta) NS3 </label>
            </b-td>
            <b-td rowspan="2" colspan="2">
              <label> Penyinaran Matahari (jam) </label>
            </b-td>
            <!-- COL 22 -->
            <b-td rowspan="2">
              <label> Catatan </label>
            </b-td>
          </b-tr>
          <!-- ROW 3 END -->
          <!-- ROW 4 START -->
          <b-tr class="table-preview-label">
            <!-- COL 1 -->
            <!-- COL 2 -->
            <b-td>
              <label> Keadaan Cuaca Waktu yang lalu 2 </label>
            </b-td>
            <!-- COL 4 -->
            <!-- COL 5 -->
            <!-- COL 6 -->
            <!-- COL 7 -->
            <!-- COL 8 -->
            <b-td>
              <label> Hujan sejak 24 jam yang lalu (mm) </label>
            </b-td>
            <b-td>
              <label> Tinggi Puncak CL1 (m) </label>
            </b-td>
            <b-td>
              <label> Tinggi Puncak CL2 (m) </label>
            </b-td>
            <b-td> </b-td>
            <!-- COL 12 -->
            <!-- COL 13 -->
            <!-- COL 14 -->
            <!-- COL 15 -->
            <b-td>
              <label> Tingginya (m) </label>
            </b-td>
            <!-- COL 17 -->
            <b-td>
              <label> Jenis C4 </label>
            </b-td>
            <b-td>
              <label> Tingginya hshs4 </label>
            </b-td>
            <b-td>
              <label> Banyaknya (okta) NS4 </label>
            </b-td>
            <!-- COL 21 -->
            <!-- COL 22 -->
            <!-- COL 23 -->
            <!-- COL 24 -->
          </b-tr>
          <!-- ROW 4 END -->
          <!-- TEMPLATE SANDI -->
          <b-tr>
            <b-td colspan="25" class="text-sandi">
              MIMIMJMJ YYGGiw Iiiii iRixhVV Nddff 1SnTTT 2SnTdTdTd 3PoPoPoPo 4PPPP 5appp 6RRRtR 7wwW1W2 8NhClCmCh 333
              (1SnTxTxTx) (2SnTnTnTn) (5EEEiE) (55SSS) (55508) (5F24F24F24F24) (56DLDMDH) (57CDaec) (58P24P24P24)
              (59P24P24P24) (6RRRtR) 
              (8NsChshs) (80Chshs)
            </b-td>
            <!-- <b-td colspan="25" class="text-sandi">
              MIMIMJMJ YYGGiw Iiiii iRixhVV Nddff 1SnTTT 2SnTdTdTd 3PoPoPoPo 4PPPP 5appp 6RRRtR 7wwW1W2 8NhClCmCh 333
              (1SnTxTxTx) (2SnTnTnTn) (5EEEiE) (55SSS) (55508) (5F24F24F24F24) (56DLDMDH) (57CDaec) (58P24P24P24)
              (59P24P24P24) (6RRRtR) (7R24R24R24R24)
              (8NsChshs) (80Chshs)
            </b-td> -->
          </b-tr>
          <!-- SECTION SHOW INPUT DATA -->
          <!-- ROW 1 START -->
          <b-tr>
            <b-td>
              <label> {{ input_data.wind_indicator_iw }} </label>
            </b-td>
            <b-td rowspan="4">
              <label> {{ input_data.visibility_vv }} </label>
            </b-td>
            <b-td>
              <label> {{ input_data.weather_indicator_ix }} </label>
            </b-td>
            <b-td>
              <label> {{ input_data.pressure_temp_c }} </label>
            </b-td>
            <b-td>
              <label> {{ input_data.pressure_qff_mb_derived }} </label>
            </b-td>
            <b-td rowspan="2">
              <label> {{ input_data.temp_drybulb_c_tttttt }} </label>
            </b-td>
            <b-td rowspan="2">
              <label> {{ temp_dewpoint_c_tdtdtd }} </label>
            </b-td>
            <b-td rowspan="2">
              <label> {{ input_data.temp_max_c_txtxtx }} </label>
            </b-td>
            <b-td>
              <label> {{ rainfall_indicator_ir }} </label>
            </b-td>
            <b-td rowspan="2" colspan="3">
              <!-- <label> {{ preview_ref.awan_rendah[awan_rendah.CL] }} </label> -->
              <label> {{ low_cloud_type_list }} </label>
            </b-td>
            <!-- COL 10 -->
            <!-- COL 11 -->
            <b-td>
              <!-- Awan rendah - Arah--->
              <!-- <label> {{ options.awan_rendah.arah[awan_rendah.arah[0]] && options.awan_rendah.arah[awan_rendah.arah[0]].text }} </label> -->
              <!-- <label>{{ awan_rendah.arah[0] == 0 ? "STNR" : awan_rendah.arah[0] }} </label> -->
              <label>{{ perawanan_arah(awan_rendah.arah[0], awan_rendah.CL, awan_rendah.NCL) }} </label>
            </b-td>
            <b-td>
              <label>{{ perawanan_elevasi(awan_rendah.sudut[0], awan_rendah.CL, awan_rendah.NCL) }} </label>
            </b-td>
            <b-td rowspan="2">
              <label> {{ perawanan_banyaknya(awan_menengah.NCM) }} </label>
            </b-td>
            <b-td rowspan="2">
              <!-- <label> {{ preview_ref.awan_menengah[awan_menengah.CM] }} </label> -->
              <label> {{ medium_cloud_type_list }} </label>
            </b-td>
            <b-td>
              <!-- <label> {{ options.awan_menengah.arah[awan_menengah.arah[0]] && options.awan_menengah.arah[awan_menengah.arah[0]].text }} </label> -->
              <!-- <label> {{ awan_menengah.arah[0] == 0 ? "STNR" : awan_menengah.arah[0] }} </label> -->
              <label> {{ perawanan_arah(awan_menengah.arah, awan_menengah.CM, awan_menengah.NCM, multi=true) }} </label>
            </b-td>
            <b-td rowspan="4">
              <label> {{ perawanan_banyaknya(input_data.cloud_cover_oktas_m) }} </label>
            </b-td>
            <b-td>
              <label> {{ preview_ref.lapisan_awan[cloud_layer_1_type_c] }} </label>
            </b-td>
            <b-td>
              <label> {{ cloud_layer_1_height_m_hshs }} </label>
            </b-td>
            <b-td>
              <label> {{ cloud_layer_1_amt_oktas_ns }} </label>
            </b-td>
            <b-td>
              <label> {{ input_data.evaporation_eq_indicator_ie }} </label>
            </b-td>
            <b-td>
              <label> {{ input_data.evaporation_24hours_mm_eee }} </label>
            </b-td>
            <b-td rowspan="2">
              <label> {{ input_data.land_cond }} </label>
            </b-td>
            <b-td rowspan="4">
              <label> {{
                t_observer_opt.find((el) => el.id === t_observer) ? t_observer_opt.find((el) => el.id ===
                  t_observer).observer : ""
              }}</label>
            </b-td>
          </b-tr>
          <!-- ROW 1 END -->
          <!-- ROW 2 START -->
          <b-tr>
            <b-td>
              <!-- <label> {{ input_data.wind_dir_deg_dd == 0 ? "STNR" : input_data.wind_dir_deg_dd }} </label> -->
              <label> {{ input_data.wind_dir_deg_dd == 0 ? "0" : input_data.wind_dir_deg_dd }} </label>
            </b-td>
            <!-- COL 2 -->
            <b-td>
              <label> {{ preview_ref.present_weather_ww[input_data.present_weather_ww] }} </label>
            </b-td>
            <b-td>
              <label> {{ input_data.pressure_reading_mb }} </label>
            </b-td>
            <b-td>
              <label> {{ input_data.pressure_qfe_mb_derived }} </label>
            </b-td>
            <!-- COL 6 -->
            <!-- COL 7 -->
            <!-- COL 8 -->
            <b-td>
              <label> {{ rainfall_jumlah_terakhir == 8888 ? "TTU" : rainfall_jumlah_terakhir }} </label>
            </b-td>
            <!-- COL 10 -->
            <!-- COL 11 -->
            <b-td>
              <!-- <label> {{ options.awan_rendah.arah[awan_rendah.arah[1]] && options.awan_rendah.arah[awan_rendah.arah[1]].text }} </label> -->
              <label> {{ perawanan_arah(awan_rendah.arah[1], awan_rendah.CL, awan_rendah.NCL) }} </label>
            </b-td>
            <b-td>
              <label> {{ perawanan_elevasi(awan_rendah.sudut[1], awan_rendah.CL, awan_rendah.NCL) }} </label>
            </b-td>
            <!-- COL 14 -->
            <!-- COL 15 -->
            <b-td>
              <label> {{ perawanan_tinggi(awan_menengah.tinggi_dasar, awan_menengah.CM, awan_menengah.NCM, multi=true) }} </label>
            </b-td>
            <!-- COL 17 -->
            <b-td>
              <label> {{ preview_ref.lapisan_awan[cloud_layer_2_type_c] }} </label>
            </b-td>
            <b-td>
              <label> {{ cloud_layer_2_height_m_hshs }} </label>
            </b-td>
            <b-td>
              <label> {{ cloud_layer_2_amt_oktas_ns }} </label>
            </b-td>
            <b-td colspan="2">
              <label> {{ input_data.solar_rad_24h_jcm2_f24 }} </label>
            </b-td>
            <!-- COL 22 -->
            <!-- COL 23 -->
            <!-- COL 24 -->
          </b-tr>
          <!-- ROW 2 END -->
          <!-- ROW 3 START -->
          <b-tr>
            <b-td rowspan="2">
              <label> {{ input_data.wind_speed_ff }} </label>
            </b-td>
            <!-- COL 2 -->
            <b-td>
              <label> {{ preview_ref.past_weather_w1[input_data.past_weather_w1] }} </label>
            </b-td>
            <b-td rowspan="2">
              <!-- <label> {{ selisih_QFF_3h }} </label> -->
              <label> {{ selisih_QFF_3h }} </label>
            </b-td>
            <b-td rowspan="2">
              <label> {{ selisih_QFF_24h }} </label>
            </b-td>
            <b-td rowspan="2">
              <label> {{ input_data.temp_wetbulb_c }} </label>
            </b-td>
            <b-td rowspan="2">
              <label> {{ relative_humidity_pc }} </label>
            </b-td>
            <b-td rowspan="2">
              <label> {{ input_data.temp_min_c_tntntn }} </label>
            </b-td>
            <b-td>
              <label> {{ rainfall_jumlah_6h == 8888 ? 'TTU' : rainfall_jumlah_6h }} </label>
            </b-td>
            <b-td>
              <label> {{ perawanan_tinggi(awan_rendah.tinggi_dasar[0], awan_rendah.CL, awan_rendah.NCL) }} </label>
            </b-td>
            <b-td>
              <label> {{ perawanan_tinggi(awan_rendah.tinggi_dasar[1], awan_rendah.CL, awan_rendah.NCL) }} </label>
            </b-td>
            <b-td>
              <label> {{ perawanan_tinggi(awan_rendah.tinggi_dasar[2], awan_rendah.CL, awan_rendah.NCL) }} </label>
            </b-td>
            <b-td colspan="2" rowspan="2">
              <!-- <label> {{ nh_me48 }}</label> -->
              <label> {{ perawanan_banyaknya(awan_rendah.NCL) }} </label>
            </b-td>
            <!-- COL 13 -->
            <b-td rowspan="2">
              <label> {{ perawanan_banyaknya(awan_tinggi.NCH) }} </label>
            </b-td>
            <b-td rowspan="2">
              <!-- <label> {{ preview_ref.awan_tinggi[awan_tinggi.CH] }} </label> -->
              <label> {{ high_cloud_type_list }} </label>
            </b-td>
            <b-td>
              <label> {{ perawanan_arah(awan_tinggi.arah, awan_tinggi.CH, awan_tinggi.NCH, multi=true) }} </label>
            </b-td>
            <!-- COL 17 -->
            <b-td>
              <label> {{ preview_ref.lapisan_awan[cloud_layer_3_type_c] }} </label>
            </b-td>
            <b-td>
              <label> {{ cloud_layer_3_height_m_hshs }} </label>
            </b-td>
            <b-td>
              <label> {{ cloud_layer_3_amt_oktas_ns }} </label>
            </b-td>
            <b-td rowspan="2" colspan="2">
              <label> {{ input_data.sunshine_h_sss }} </label>
            </b-td>
            <!-- COL 22 -->
            <b-td rowspan="2">
              <label> {{ input_data.land_note }} </label>
            </b-td>
          </b-tr>
          <!-- ROW 3 END -->
          <!-- ROW 4 START -->
          <b-tr>
            <!-- COL 1 -->
            <!-- COL 2 -->
            <b-td>
              <label> {{ preview_ref.past_weather_w2[input_data.past_weather_w2] }} </label>
            </b-td>
            <!-- COL 4 -->
            <!-- COL 5 -->
            <!-- COL 6 -->
            <!-- COL 7 -->
            <!-- COL 8 -->
            <b-td>
              <label> {{ rainfall_jumlah_24h == 8888 ? 'TTU' : rainfall_jumlah_24h }} </label>
            </b-td>
            <b-td>
              <label> {{ awan_rendah.tinggi_puncak[0] }} </label>
            </b-td>
            <b-td>
              <label> {{ awan_rendah.tinggi_puncak[1] }} </label>
            </b-td>
            <b-td> </b-td>
            <!-- COL 12 -->
            <!-- COL 13 -->
            <!-- COL 14 -->
            <!-- COL 15 -->
            <b-td>
              <label> {{ perawanan_tinggi(awan_tinggi.tinggi_dasar, awan_tinggi.CH, awan_tinggi.NCH, multi=true) }} </label>
            </b-td>
            <!-- COL 17 -->
            <b-td>
              <label> {{ preview_ref.lapisan_awan[cloud_layer_4_type_c] }} </label>
            </b-td>
            <b-td>
              <label> {{ cloud_layer_4_height_m_hshs }} </label>
            </b-td>
            <b-td>
              <label> {{ cloud_layer_4_amt_oktas_ns }} </label>
            </b-td>
            <!-- COL 21 -->
            <!-- COL 22 -->
            <!-- COL 23 -->
            <!-- COL 24 -->
          </b-tr>
          <!-- ROW 4 END -->
          <!-- PREVIEW SANDI -->
          <b-tr>
            <b-td colspan="25" class="text-sandi">
              {{ sandi }}
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-modal>
    <!-- MODAL END -->

    <b-modal
			id="send-gts-message"
			ref="send-gts-message"
			title="SEND GTS MESSAGES"
			button-size="md"
			size="lg"
			centered
			no-close-on-backdrop
		>
      <b-row class="mb-1">
        <b-col cols="12">
          <span style="font-style: italic;">
            <strong style="font-style: normal;">Notes</strong>: Jika stasiun anda tidak termasuk GBON maka 
            tidak perlu mengirim diluar jam Utama (Main) dan Antara (Intermediate)</span>
        </b-col>
        <b-col cols="12" v-if="!enable_send_gts" style="margin-top:10px;">
          <span style="font-style: italic;">
            Konfigurasi header tidak ditemukan, mohon set terlebih dahulu pada <strong>Metadata</strong> sebelum mengirim</span>
        </b-col>
      </b-row>
			<b-row class="mb-1">
        <b-col cols="12">
          <b-form-textarea id="textarea-sandi-gts" 
            placeholder="Sandi" 
            v-model="sandi_gts_message" 
            class="font-weight-bolder textsandi"
            rows="9">
          </b-form-textarea>
        </b-col>
      </b-row>
      <template #modal-footer>
				<b-button size="sm" @click="onCancel">
				Cancel
				</b-button>
				<b-button
          :disabled="!enable_send_gts || showLoading"
					size="sm"
					variant="gradient-primary"
					@click="onSendBmkgSwitching"
				>
				Send to INASwitching
				</b-button>
			</template>
    </b-modal>

    <b-overlay :show="showLoading" rounded="sm" no-wrap></b-overlay>
  </div>
</template>

<script>
import { VueAutosuggest } from "vue-autosuggest";
import axios from "axios";
import Ripple from "vue-ripple-directive";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import Helper from "@/api/helper";
import general_helper from "@/helper/helper";
import metadata from "@/api/metadata";
import qc_helper from "@/api/qc_helper";
import error_handler from "@/api/error_handler";
import awsdigi from "@/api/awsdigi";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Params from "@/attr/metadata/params";
import RC_helper from "@/validators/RC_helper";
import SC_helper from "@/validators/SC_helper";
import DataHelper from "@/api/dummy/store/DataHelper";
import {
  GC_WIND_DIR_DEG_DD,
  GC_WIND_SPEED_FF,
  GC_VISIBILITY_VV,
  GC_PRESENT_WEATHER_WW,
  GC_PAST_WEATHER,
  GC_PRESSURE_QFF_MB_DERIVED,
  GC_PRESSURE_QFE_MB_DERIVED,
  GC_TEMP_DRYBULB_C_TTTTTT,
  GC_TEMP_WETBULB_C,
  GC_TEMP_MAX_C_TXTXTX,
  GC_TEMP_MIN_C_TNTNTN,
  GC_RAINFALL_LAST_MM,
  GC_CLOUD_LOW_BASE,
  GC_CLOUD_MED_BASE,
  GC_CLOUD_HIGH_BASE,
  GC_CLOUD_ELEVATION_ANGLE_EC,
  GC_CLOUD_LOW_PEAK,
  GC_EVAPORATION_24HOURS_MM_EEE,
  GC_SOLAR_RAD_24H_JCM2_F24,
  GC_SUNSHINE_H_SSS,
  GC_CLOUD_VERTICAL_VIS,
  RC_SINOP,
  GC_SINOP,
} from "@/validators/sinopvalidator.js";
import {
  BCard,
  BCardHeader,
  BContainer,
  BRow,
  BCol,
  BFormSelect,
  BForm,
  BButton,
  BButtonGroup,
  BFormGroup,
  BFormDatepicker,
  BFormInput,
  BFormTimepicker,
  BFormTextarea,
  BIcon,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
  BFormCheckbox,
  BCollapse,
  VBToggle,
  VBTooltip,
  BOverlay,
  BTooltip,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import minValue from "vuelidate/lib/validators/minValue";
import FormLayoutDatalistHelperVue from "@/views/forms/form-layout/FormLayoutDatalistHelper.vue";

import SelectNotFoundSlot from "../../../components/bmkgsatu/select/SelectNotFoundSlot.vue";
import {
  is_3hourly_obs,
  is_main_hour,
  is_intermediate_hour,
  counter_clockwise_range,
  get_combination_clouds,
  get_available_cloud,
  get_lowest_cloud,
  get_source_nh,
  get_RRRtr_section1,
  get_RRRtr_section3,
  get_pressure_difference_and_tendency
} from "@/helper/synop_helper.js"
import GenHelper from "@/helper/helper";

export default {
  components: {
    VueAutosuggest,
    BCardActions,
    BCard,
    BCardHeader,
    BContainer,
    BRow,
    BCol,
    BFormSelect,
    BForm,
    BButton,
    BButtonGroup,
    BFormGroup,
    BFormDatepicker,
    BFormInput,
    BFormTimepicker,
    BFormTextarea,
    BIcon,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BFormCheckbox,
    BCollapse,
    ToastificationContent,
    vSelect,
    BOverlay,
    BTooltip,
    SelectNotFoundSlot
  },
  mixins: [validationMixin],
  validations() {
    return {
      t_station: { required },
      t_observer: { required },
      tanggal: { required },
      jam: { required },
      input_data: {
        wind_indicator_iw: { required },
        wind_dir_deg_dd: { required, GC_WIND_DIR_DEG_DD, consistency: (val) => !this.CC.wind_dir_deg_dd.status },
        wind_speed_ff: { required, GC_WIND_SPEED_FF, consistency: (val) => !this.CC.wind_speed_ff.status },
        visibility_vv: { required, GC_VISIBILITY_VV, consistency: (val) => !this.CC.visibility_vv.status },
        weather_indicator_ix: { required },
        present_weather_ww: { required, GC_PRESENT_WEATHER_WW, consistency: (val) => !this.CC.present_weather_ww.status },
        past_weather_w1: { required, GC_PAST_WEATHER, consistency: (val) => !this.CC.past_weather_w1.status },
        past_weather_w2: { required, GC_PAST_WEATHER, consistency: (val) => !this.CC.past_weather_w2.status },
        pressure_temp_c: {
          ...(true ? {} : { required }),
        },
        pressure_reading_mb: {
          ...(true ? {} : { required }),
        },
        pressure_qff_mb_derived: {
          ...(true ? {} : { required }),
          GC_PRESSURE_QFF_MB_DERIVED,
          consistency(val) {
            if (this.input_data.pressure_qfe_mb_derived) {
              return val >= this.input_data.pressure_qfe_mb_derived;
            }
            return true;
          },
        },
        pressure_qfe_mb_derived: {
          ...(true ? {} : { required }),
          GC_PRESSURE_QFE_MB_DERIVED,
          consistency(val) {
            if (this.input_data.pressure_qff_mb_derived) {
              return this.input_data.pressure_qff_mb_derived >= val;
            }
            return true;
          },
        },
        temp_drybulb_c_tttttt: {
          required,
          GC_TEMP_DRYBULB_C_TTTTTT,
          consistency(val) {
            // console.log('consistency temp_drybulb_c_tttttt', val, this.input_data.temp_wetbulb_c)
            if (this.input_data.temp_wetbulb_c) {
              return val >= this.input_data.temp_wetbulb_c;
            }
            return true;
          },
        },
        temp_wetbulb_c: {
          required,
          GC_TEMP_WETBULB_C,
          consistency(val) {
            if (this.input_data.temp_drybulb_c_tttttt) {
              return val <= this.input_data.temp_drybulb_c_tttttt;
            }
            return true;
          },
        },
        temp_max_c_txtxtx: {
          ...(this.disabled.temp_max_c_txtxtx ? {} : { required }),
          GC_TEMP_MAX_C_TXTXTX,
          consistency: (val) => !this.CC.temp_max_c_txtxtx.status 
          // consistencyTxTn(val) {
          //   // console.log('consistencyTxTn', val, this.temp_min_c_tntntn_00)
          //   if (val && this.temp_min_c_tntntn_00 && this.temp_min_c_tntntn_00 >= 0 &&
          //     val != 9999 && this.temp_min_c_tntntn_00 != 9999
          //   ) {
          //     return val >= this.temp_min_c_tntntn_00;
          //   }
          //   return true;
          // },
          // consistencyMaxTT(val) {
          //   // console.log('consistencyMaxTT', val, this.temp_drybulb_c_tttttt_max)
          //   if (val && this.temp_drybulb_c_tttttt_max && this.temp_drybulb_c_tttttt_max >= 0 &&
          //     val != 9999 && this.temp_drybulb_c_tttttt_max != 9999
          //   ) {
          //     return val <= +this.temp_drybulb_c_tttttt_max + 3;
          //   }
          //   return true;
          // },
        },
        temp_min_c_tntntn: {
          ...(this.disabled.temp_min_c_tntntn ? {} : { required }),
          GC_TEMP_MIN_C_TNTNTN,
          consistency: (val) => !this.CC.temp_min_c_tntntn.status 
          // consistencyTxTn(val) {
          //   if (val && this.temp_max_c_txtxtx_12 && this.temp_max_c_txtxtx_12 >= 0 &&
          //     val != 9999 && this.temp_max_c_txtxtx_12 != 9999
          //   ) {
          //     return this.temp_max_c_txtxtx_12 >= val;
          //   }
          //   return true;
          // },
          // consistencyMinTT(val) {
          //   if (val && this.temp_drybulb_c_tttttt_min && this.temp_drybulb_c_tttttt_min >= 0 &&
          //     val != 9999 && this.temp_drybulb_c_tttttt_min != 9999
          //   ) {
          //     return val >= this.temp_drybulb_c_tttttt_min - 3;
          //   }
          //   return true;
          // },
        },
        cloud_cover_oktas_m: { required, consistency: (val) => !this.CC.cloud_cover_oktas_m.status },
        rainfall_last_mm: {
          ...(this.disabled.rainfall_last_mm ? {} : { required }),
          GC_RAINFALL_LAST_MM,
        },
        evaporation_24hours_mm_eee: {
          ...(this.disabled.evaporation_24hours_mm_eee ? {} : { required }),
          GC_EVAPORATION_24HOURS_MM_EEE,
        },
        evaporation_eq_indicator_ie: {
          ...(this.disabled.evaporation_eq_indicator_ie ? {} : { required }),
        },
        solar_rad_24h_jcm2_f24: {
          GC_SOLAR_RAD_24H_JCM2_F24,
        },
        sunshine_h_sss: {
          ...(this.disabled.sunshine_h_sss ? {} : { required }),
          GC_SUNSHINE_H_SSS,
        },
        land_cond: { required },
        cloud_vertical_vis: {
          ...(this.disabled.cloud_vertical_vis ? {} : { required }),
          GC_CLOUD_VERTICAL_VIS,
        },
      },
      awan_rendah: {
        CL: { ...(this.disabled.is_cloud_cover_block ? {} : { required })
          , consistency: (val) => !this.CC.cloud_low_type_cl.status },
        NCL: { ...(this.disabled.is_cloud_cover_block ? {} : { required })
          , consistency: (val) => !this.CC.cloud_low_cover_oktas.status },
        jenis: {
          [0]: { ...(this.disabled.awan_rendah.cloud_1 || this.disabled.is_cloud_cover_block ? {} : { required }) },
          [1]: { ...(this.disabled.awan_rendah.cloud_2 || this.disabled.is_cloud_cover_block ? {} : { required }) },
          [2]: { ...(this.disabled.awan_rendah.cloud_3 || this.disabled.is_cloud_cover_block ? {} : { required }) },
        },
        jumlah: {
          [0]: { ...(this.disabled.awan_rendah.cloud_1 || this.disabled.is_cloud_cover_block ? {} : { required }) },
          [1]: { ...(this.disabled.awan_rendah.cloud_2 || this.disabled.is_cloud_cover_block ? {} : { required }) },
          [2]: { ...(this.disabled.awan_rendah.cloud_3 || this.disabled.is_cloud_cover_block ? {} : { required }) },
        },
        tinggi_dasar: {
          [0]: {
            ...(this.disabled.awan_rendah.cloud_1 || this.disabled.is_cloud_cover_block ? {} : { required }),
            GC_CLOUD_LOW_BASE,
          },
          [1]: {
            ...(this.disabled.awan_rendah.cloud_2 || this.disabled.is_cloud_cover_block ? {} : { required }),
            GC_CLOUD_LOW_BASE,
          },
          [2]: {
            ...(this.disabled.awan_rendah.cloud_3 || this.disabled.is_cloud_cover_block ? {} : { required }),
            GC_CLOUD_LOW_BASE,
          },
        },
        tinggi_puncak: {
          [0]: {
            // ...(this.disabled.awan_rendah.tinggi_puncak[0] ? {} : { required }),
            GC_CLOUD_LOW_PEAK,
          },
          [1]: {
            // ...(this.disabled.awan_rendah.tinggi_puncak[1] ? {} : { required }),
            GC_CLOUD_LOW_PEAK,
          },
        },
        arah: {
          [0]: { ...(this.disabled.awan_rendah.cloud_1 || this.disabled.is_cloud_cover_block ? {} : { required }) },
          [1]: { ...(this.disabled.awan_rendah.cloud_2 || this.disabled.is_cloud_cover_block ? {} : { required }) },
          [2]: { ...(this.disabled.awan_rendah.cloud_3 || this.disabled.is_cloud_cover_block ? {} : { required }) },
        },
        sudut: {
          // [0]: { ...(this.disabled.awan_rendah.sudut[0] ? {} : { required }) },
          // [1]: { ...(this.disabled.awan_rendah.sudut[1] ? {} : { required }) },

          // AN
          [0]: {...(this.disabled.cloud_elevation_1_angle_ec 
            || this.awan_rendah.tinggi_puncak[0] === null || this.awan_rendah.tinggi_puncak[0] === ""
            || this.disabled.is_cloud_cover_block? 
            {} : { required }), 
            consistency: (val) => !this.CC.cloud_elevation_1_angle_ec.status},
          [1]: {...(this.disabled.cloud_elevation_2_angle_ec 
            || this.awan_rendah.tinggi_puncak[1] === null || this.awan_rendah.tinggi_puncak[1] === "" 
            || this.disabled.is_cloud_cover_block? 
            {} : { required }), consistency: (val) => !this.CC.cloud_elevation_2_angle_ec.status},
        },
        da: {
          [0]: { ...(this.awan_rendah.arah[0] !== "" && this.awan_rendah.arah[0] !== null
            && this.awan_rendah.sudut[0] !== "" && this.awan_rendah.sudut[0] !== null
            && !this.awan_rendah.sudut[0] !== true
            ? {required} : { }) },
          [1]: { ...(this.awan_rendah.arah[1] !== "" && this.awan_rendah.arah[1] !== null
            && this.awan_rendah.sudut[1] !== "" && this.awan_rendah.sudut[1] !== null 
            && !this.awan_rendah.sudut[1] !== true
            ? {required} : { }) }
        },
      },
      awan_menengah: {
        CM: { ...(this.disabled.is_cloud_cover_block || this.disabled.is_cloud_cover_block? {} : { required })
          , consistency: (val) => !this.CC.cloud_med_type_cm.status },
        NCM: { ...(this.disabled.is_cloud_cover_block || this.disabled.is_cloud_cover_block? {} : { required })
          ,  consistency: (val) => !this.CC.cloud_med_cover_oktas.status },
        jenis: {
          [0]: { ...(this.disabled.awan_menengah.cloud_1 || this.disabled.is_cloud_cover_block? {} : { required }) },
          [1]: { ...(this.disabled.awan_menengah.cloud_2 || this.disabled.is_cloud_cover_block? {} : { required }) },
        },
        jumlah: {
          [0]: { ...(this.disabled.awan_menengah.cloud_1 || this.disabled.is_cloud_cover_block? {} : { required }) },
          [1]: { ...(this.disabled.awan_menengah.cloud_2 || this.disabled.is_cloud_cover_block? {} : { required }) },
        },
        tinggi_dasar: {
          [0]: {
            ...(this.disabled.awan_menengah.cloud_1 || this.disabled.is_cloud_cover_block? {} : { required }),
            GC_CLOUD_MED_BASE,
          },
          [1]: {
            ...(this.disabled.awan_menengah.cloud_2 || this.disabled.is_cloud_cover_block? {} : { required }),
            GC_CLOUD_MED_BASE,
          },
        },
        arah: {
          [0]: { ...(this.disabled.awan_menengah.cloud_1 || this.disabled.is_cloud_cover_block? {} : { required }) },
          [1]: { ...(this.disabled.awan_menengah.cloud_2 || this.disabled.is_cloud_cover_block? {} : { required }) },
        },
      },
      awan_tinggi: {
        CH: { ...(this.disabled.is_cloud_cover_block ? {} : { required })
          , consistency: (val) => !this.CC.cloud_high_type_ch.status },
        NCH: { ...(this.disabled.is_cloud_cover_block ? {} : { required })
          , consistency: (val) => !this.CC.cloud_high_cover_oktas.status },
        jenis: {
          [0]: { ...(this.disabled.awan_tinggi.cloud_1 || this.disabled.is_cloud_cover_block ? {} : { required }) },
          [1]: { ...(this.disabled.awan_tinggi.cloud_2 || this.disabled.is_cloud_cover_block ? {} : { required }) },
        },
        jumlah: {
          [0]: { ...(this.disabled.awan_tinggi.cloud_1 || this.disabled.is_cloud_cover_block ? {} : { required }) },
          [1]: { ...(this.disabled.awan_tinggi.cloud_2 || this.disabled.is_cloud_cover_block ? {} : { required }) },
        },
        tinggi_dasar: {
          [0]: {
            ...(this.disabled.awan_tinggi.cloud_1 || this.disabled.is_cloud_cover_block ? {} : { required }),
            GC_CLOUD_HIGH_BASE,
          },
          [1]: {
            ...(this.disabled.awan_tinggi.cloud_2 || this.disabled.is_cloud_cover_block ? {} : { required }),
            GC_CLOUD_HIGH_BASE,
          },
        },
        arah: {
          [0]: { ...(this.disabled.awan_tinggi.cloud_1 || this.disabled.is_cloud_cover_block ? {} : { required }) },
          [1]: { ...(this.disabled.awan_tinggi.cloud_2 || this.disabled.is_cloud_cover_block ? {} : { required }) },
        },
      },
    };
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
    Ripple,
  },

  props: ["roles"],

  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const maxDate = new Date(today);

    return {
      fetching: false,
      last_qc_histories: null,
      last_sc_qc_histories: null,
      is_data_ready_to_filled: false,
      t_operating_hours: 0,
      t_start_hour: 0,
      t_end_hour: 0,
      t_mid_start_hour: 0,
      t_mid_end_hour: 0,
      t_barometer_air_raksa: true,
      t_barometer_digital: false,
      t_entry_first_date: null,
      RC_helper: RC_helper,
      SC_helper: SC_helper,
      max: maxDate,
      donePreview: false,
      showLoading: false,
      isEdit: false,
      t_station: "",
      t_station_opt: [],
      t_station_id: "",
      t_observer: "",
      t_observer_opt: [],
      t_wmoid: "",
      t_path: "",
      tanggal: "",
      jam: "",
      cuaca_bermakna_WW: [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
        53,
        54,
        55,
        56,
        57,
        58,
        59,
        60,
        61,
        62,
        63,
        64,
        65,
        66,
        67,
        68,
        69,
        70,
        71,
        72,
        73,
        74,
        75,
        76,
        77,
        78,
        79,
        80,
        81,
        82,
        83,
        84,
        85,
        86,
        87,
        88,
        89,
        90,
        91,
        92,
        93,
        94,
        95,
        96,
        97,
        98,
        99,
      ],
      cuaca_bermakna: [3, 4, 5, 6, 7, 8, 9],
      input_data: {
        wind_indicator_iw: "",
        wind_dir_deg_dd: "",
        wind_speed_ff: "",
        visibility_vv: "",
        weather_indicator_ix: "",
        present_weather_ww: "",
        past_weather_w1: "",
        past_weather_w2: "",
        pressure_temp_c: "",
        pressure_reading_mb: "",
        pressure_qff_mb_derived: "",
        pressure_qfe_mb_derived: "",
        temp_drybulb_c_tttttt: "",
        temp_wetbulb_c: "",
        temp_max_c_txtxtx: "",
        temp_min_c_tntntn: "",
        cloud_cover_oktas_m: "",
        rainfall_last_mm: "",
        evaporation_24hours_mm_eee: "",
        evaporation_eq_indicator_ie: "",
        solar_rad_24h_jcm2_f24: "",
        sunshine_h_sss: "",
        land_cond: "",
        land_note: "",
        cloud_low_type_cl: "",
        cloud_low_cover_oktas: "",
        cloud_low_type_1: "",
        cloud_low_type_2: "",
        cloud_low_type_3: "",
        cloud_low_base_1: "",
        cloud_low_base_2: "",
        cloud_low_base_3: "",
        cloud_low_peak_1: "",
        cloud_low_peak_2: "",
        cloud_low_cover_1: "",
        cloud_low_cover_2: "",
        cloud_low_cover_3: "",
        cloud_low_dir_1: "",
        cloud_low_dir_2: "",
        cloud_low_dir_3: "",
        cloud_vertical_vis: ""
      },
      options: {
        wind_indicator_iw: [
          {
            value: 3,
            text: "wind speed estimated, kt",
          },
          {
            value: 4,
            text: "wind speed from anemometer, kt",
          },
        ],
        weather_indicator_ix: [
          { value: 1, text: "manned station, weather group included" },
          {
            value: 2,
            text: "manned station, omitted, no significant weather",
          },
          {
            value: 3,
            text: "manned station, omitted, no weather observation",
          },
          {
            value: 4,
            text: "automated station, weather group included (code 4677 and 4561)",
          },
          { value: 5, text: "automated station, omitted, weather group" },
          {
            value: 6,
            text: "automated station, omitted, no weather observation",
          },
          {
            value: 7,
            text: "automated station, weather group included (code 4680 and 4531)",
          },
        ],
        present_weather_ww: [
          { value: 0, text: "clear sky" },
          { value: 1, text: "cloud dissolving" },
          { value: 2, text: "state of sky unchanged" },
          { value: 3, text: "clouds developing" },
          { value: 4, text: "visibility reduced by smoke" },
          { value: 5, text: "haze" },
          {
            value: 6,
            text: "widespread dust in suspension not raised by wind",
          },
          { value: 7, text: "dust or sand raised by wind" },
          { value: 8, text: "well developed dust or sand whirl" },
          {
            value: 9,
            text: "dust or sand storm within sight but not station",
          },
          { value: 10, text: "mist" },
          { value: 11, text: "patches of shallow fog" },
          { value: 12, text: "continuous shallow fog" },
          { value: 13, text: "lighting visible, no thunder heard" },
          {
            value: 14,
            text: "precipitation within sign but not hitting ground",
          },
          {
            value: 15,
            text: "distanct precipitation but not failing at station",
          },
          {
            value: 16,
            text: "nearby precipitation but failing at station",
          },
          {
            value: 17,
            text: "thunderstorm but no precipitation failing at station",
          },
          {
            value: 18,
            text: "squalls within sight but no precipitation failing at station",
          },
          { value: 19, text: "funnel clouds within sight" },
          { value: 20, text: "recent drizlle" },
          { value: 21, text: "recent rain" },
          { value: 22, text: "recent snow" },
          { value: 23, text: "recent rain and snow" },
          { value: 24, text: "recent freezing rain" },
          { value: 25, text: "recent rain showers" },
          { value: 26, text: "recent snow showers" },
          { value: 27, text: "recent hail showers" },
          { value: 28, text: "recent fog" },
          { value: 29, text: "recent thuderstorm" },
          {
            value: 30,
            text: "Slight to moderate duststorm, decreasing in intensity",
          },
          { value: 31, text: "Slight to moderate duststorm, no change" },
          {
            value: 32,
            text: "Slight to moderate duststorm, increasing in intensity",
          },
          { value: 33, text: "severe duststorm, decreasing in intensity" },
          { value: 34, text: "Severe duststorm, no change" },
          { value: 35, text: "Severe duststorm, increasing in intensity" },
          {
            value: 36,
            text: "Slight to moderate drifting snow, below eye level",
          },
          { value: 37, text: "Heavy drifting snow, below eye level" },
          {
            value: 38,
            text: "Slight to moderate drifting snow, above eye level",
          },
          { value: 39, text: "Heavy drifting snow, above eye level" },
          { value: 40, text: "Fog at a distance" },
          { value: 41, text: "Patches of Fog" },
          { value: 42, text: "Fog, sky visible, thinning" },
          { value: 43, text: "Fog, sky not visible, thinning" },
          { value: 44, text: "Fog, sky visible, no change" },
          { value: 45, text: "Fog, sky not visible, no change" },
          { value: 46, text: "Fog, sky visible, becoming thicker" },
          { value: 47, text: "Fog, sky not visible, becoming thicker" },
          { value: 48, text: "Fog, depositing rime, sky visible" },
          { value: 49, text: "Fog, depositing rime, sky not visible" },
          { value: 50, text: "Intermittent light drizzle" },
          { value: 51, text: "Continuous light drizzle" },
          { value: 52, text: "Intermittent moderate drizzle" },
          { value: 53, text: "Continuous moderate drizzle" },
          { value: 54, text: "Intermittent heavy drizzle" },
          { value: 55, text: "Continuous heavy drizzle" },
          { value: 56, text: "Light freezing drizzle" },
          { value: 57, text: "Moderate to heavy freezing drizzle" },
          { value: 58, text: "Light drizzle and rain" },
          { value: 59, text: "Moderate to heavy drizzle and rain" },
          { value: 60, text: "Intermittent light rain" },
          { value: 61, text: "Continuous light rain" },
          { value: 62, text: "Intermittent moderate rain" },
          { value: 63, text: "Continuous moderate rain" },
          { value: 64, text: "Intermittent heavy rain" },
          { value: 65, text: "Continuous heavy rain" },
          { value: 66, text: "Light freezing rain" },
          { value: 67, text: "Moderate to heavy freezing rain" },
          { value: 68, text: "Light rain and snow" },
          { value: 69, text: "Moderate to heavy rain and snow" },
          { value: 70, text: "Intermittent light snow" },
          { value: 71, text: "Continuous light snow" },
          { value: 72, text: "Intermittent moderate snow" },
          { value: 73, text: "Continuous moderate snow" },
          { value: 74, text: "Intermittent heavy snow" },
          { value: 75, text: "Continuous heavy snow" },
          { value: 76, text: "Diamond dust" },
          { value: 77, text: "Snow grain" },
          { value: 78, text: "Snow crystal" },
          { value: 79, text: "Ice pellet" },
          { value: 80, text: "Light rain shower" },
          { value: 81, text: "Moderate to heavy rain shower" },
          { value: 82, text: "Violent rain shower" },
          { value: 83, text: "Light rain and snow shower" },
          { value: 84, text: "Moderate to heavy rain and snow shower" },
          { value: 85, text: "Light snow shower" },
          { value: 86, text: "Moderate to heavy snow shower" },
          { value: 87, text: "Light snow/ice pellet shower" },
          { value: 88, text: "Moderate to heavy snow/ice pellet shower" },
          { value: 89, text: "light hail shower" },
          { value: 90, text: "Moderate to heavy hail shower" },
          {
            value: 91,
            text: "Thunderstorm in past hour, currently only light rain",
          },
          {
            value: 92,
            text: "Thunderstorm in past hour, currently only moderate to heavy rain",
          },
          {
            value: 93,
            text: "Thunderstorm in past hour, currently only light snow or rain/snow mist",
          },
          {
            value: 94,
            text: "Thunderstorm in past hour, currently only moderate to heavy",
          },
          { value: 95, text: "Light to moderate thunderstorm" },
          { value: 96, text: "Light to moderate thunderstorm with hail" },
          { value: 97, text: "Heavy thunderstor" },
          {
            value: 98,
            text: "Thunderstorm combined with duststorm or sandstorm at time of observation",
          },
          {
            value: 99,
            text: "Thunderstorm, heavy, with hail at time of observation",
          },
        ],
        past_weather_w1: [
          { value: 0, text: "Cloudy -" },
          { value: 1, text: "Cloudy" },
          { value: 2, text: "Cloudy +" },
          { value: 3, text: "Sandstorm, duststorm or blowing snow" },
          { value: 4, text: "fog, or thick haze." },
          { value: 5, text: "Drizzle" },
          { value: 6, text: "Rain" },
          { value: 7, text: "Snow or mixed rain and snow" },
          { value: 8, text: "Showers" },
          { value: 9, text: "Thunderstorms" },
          { value: "/", text: "Unknown" },
        ],
        past_weather_w2: [
          { value: 0, text: "Cloudy -" },
          { value: 1, text: "Cloudy" },
          { value: 2, text: "Cloudy +" },
          { value: 3, text: "Sandstorm, duststorm or blowing snow" },
          { value: 4, text: "fog, or thick haze." },
          { value: 5, text: "Drizzle" },
          { value: 6, text: "Rain" },
          { value: 7, text: "Snow or mixed rain and snow" },
          { value: 8, text: "Showers" },
          { value: 9, text: "Thunderstorms" },
          { value: "/", text: "Unknown" },
        ],
        cloud_cover_oktas_m: [
          { value: "0", text: "0" },
          { value: "1", text: "1 okta or less, but not zero" },
          { value: "2", text: "2 oktas" },
          { value: "3", text: "3 oktas" },
          { value: "4", text: "4 oktas" },
          { value: "5", text: "5 oktas" },
          { value: "6", text: "6 oktas" },
          { value: "7", text: "7 oktas or more, but not eight" },
          { value: "8", text: "8 oktas" },
          {
            value: "9",
            text: "9 - Sky obscured by fog and/or other meteorological phenomena",
          },
          {
            value: "/",
            text: "/ - Cloud cover is indiscernible for reasons other than fog or other meteorological phenomena, or observation is not made",
          },
        ],
        evaporation_eq_indicator_ie: [
          { value: 0, text: "USA open pan evaporimeter (without cover)" },
          { value: 1, text: "USA open pan evaporimeter (mesh cover)" },
          { value: 2, text: "GGI-3000 evaporimeter (sunken)" },
          { value: 3, text: "20-m2 tank" },
          { value: 4, text: "Others" },
        ],
        land_cond: [
          { value: 0, text: "Kering" },
          { value: 1, text: "Lembab" },
          { value: 2, text: "Basah" },
          { value: 3, text: "Tergenang" },
        ],
        awan_rendah: {
          CL: [
            { value: "0", text: "tidak ada awan C1" },
            {
              value: "1",
              text: "cumulus humilis atau fracto cumulus atao kedua-duanya",
            },
            {
              value: "2",
              text: "cumulus mediocris atau congestus, disertai atau tidak disertai fracto cumulus atau humilis atau strato cumulus, dengan tinggi dasar sama",
            },
            {
              value: "3",
              text: "cumulunimbus tanpa landasan, disertai atau tidak disertai cumulus, strato cumulus atau stratus",
            },
            {
              value: "4",
              text: "stratocumulus yang terjadi dari bentangan cumulus",
            },
            {
              value: "5",
              text: "stratocumulus yang tidak terjadi dari bentangan cumulus",
            },
            { value: "6", text: "stratus" },
            {
              value: "7",
              text: "fraktostratus atau fraktocumulus yang menyertai cuaca buruk, biasanya dibawah As atau Ns",
            },
            {
              value: "8",
              text: "cumulus dan stratocumulus yang tidak terjadi dari bentangan cumulus, dengan tinggi dasar berlainan",
            },
            {
              value: "9",
              text: "cumulunimbus, biasanya berlandasan disertai atau tidak disertai cumulus stratocumulus, stratus, cumulunimbus yang tidak berlandasan",
            },
            {
              value: "/",
              text: "C1 tidak kelihatan disebabkan oleh kabut, badai debu, badai pasir atau lain-lain gejala",
            },
          ],
          NCL: [
            { value: "0", text: "0" },
            { value: "1", text: "1 okta or less, but not zero" },
            { value: "2", text: "2 oktas" },
            { value: "3", text: "3 oktas" },
            { value: "4", text: "4 oktas" },
            { value: "5", text: "5 oktas" },
            { value: "6", text: "6 oktas" },
            { value: "7", text: "7 oktas or more, but not eight" },
            { value: "8", text: "8 oktas" },
            {
              value: "9",
              text: "9 - Sky obscured by fog and/or other meteorological phenomena",
            },
            {
              value: "/",
              text: "/ - Cloud cover is indiscernible for reasons other than fog or other meteorological phenomena, or observation is not made",
            },
          ],
          jenis: [
            // { value: "5", text: "nimbostratus (Ns)" },
            { value: "6", text: "stratocumulus (Sc)" },
            { value: "7", text: "stratus (St)" },
            { value: "8", text: "cumulus (Cu)" },
            { value: "9", text: "cumulonimbus (Cb)" },
            { value: "/", text: "cloud not visible" },
          ],
          jumlah: [
            { value: "0", text: "0" },
            { value: "1", text: "1 okta or less, but not zero" },
            { value: "2", text: "2 oktas" },
            { value: "3", text: "3 oktas" },
            { value: "4", text: "4 oktas" },
            { value: "5", text: "5 oktas" },
            { value: "6", text: "6 oktas" },
            { value: "7", text: "7 oktas or more, but not eight" },
            { value: "8", text: "8 oktas" },
            {
              value: "9",
              text: "9 - Sky obscured by fog and/or other meteorological phenomena",
            },
            {
              value: "/",
              text: "/ - Cloud cover is indiscernible for reasons other than fog or other meteorological phenomena, or observation is not made",
            },
          ],
          arah: [
            { value: 0, text: "Stationary or No Clouds" },
            { value: 1, text: "NE" },
            { value: 2, text: "E" },
            { value: 3, text: "SE" },
            { value: 4, text: "S" },
            { value: 5, text: "SW" },
            { value: 6, text: "W" },
            { value: 7, text: "NW" },
            { value: 8, text: "N" },
            { value: 9, text: "All Directions or Invisible" },
          ],
          sudut: [
            { value: 0, text: "top of cloud not visible" },
            { value: 1, text: "45º or more" },
            { value: 2, text: "About 30º" },
            { value: 3, text: "About 20º" },
            { value: 4, text: "About 15º" },
            { value: 5, text: "About 12º" },
            { value: 6, text: "About 9º" },
            { value: 7, text: "About 7º" },
            { value: 8, text: "About 6º" },
            { value: 9, text: "Less than 5º" },
          ],
        },
        awan_menengah: {
          CM: [
            { value: "0", text: "No CM Cloud" },
            { value: "1", text: "Altostratus translucidus" },
            { value: "2", text: "Altostratus opacus or Nimbostratus" },
            { value: "3", text: "Altocumulus translucidus at a single level" },
            {
              value: "4",
              text: "Patches (often lenticular) of Altocumulus translucidious, continually changing and occurring at one or more levels",
            },
            {
              value: "5",
              text: "Altocumulus translucidius in bands, or one or more layers of Altocumulus translucidius or opacus, progressively invading the sky; these Altocumulus clouds generally thicken as a whole",
            },
            {
              value: "6",
              text: "Altocumulus cumulogenitus (or cumulu-nimbogenitus)",
            },
            {
              value: "7",
              text: "Altocumulus translucidius or opacus in two or more layers, or Altocumulus opacus in a single layer, not progressively invading the sky, or Altocumulus with Altostratus or Nimbostratus",
            },
            { value: "8", text: "Altocumulus castellanus or floccus" },
            {
              value: "9",
              text: "Altocumulus of a chaotic sky, generally at several levels",
            },
            {
              value: "/",
              text: "CM clouds invisible owing to darkness, fog, blowing dust or sand, or other similar phenomena, or because continuous layer of clouds",
            },
          ],
          NCM: [
            { value: "0", text: "0" },
            { value: "1", text: "1 okta or less, but not zero" },
            { value: "2", text: "2 oktas" },
            { value: "3", text: "3 oktas" },
            { value: "4", text: "4 oktas" },
            { value: "5", text: "5 oktas" },
            { value: "6", text: "6 oktas" },
            { value: "7", text: "7 oktas or more, but not eight" },
            { value: "8", text: "8 oktas" },
            {
              value: "9",
              text: "9 - Sky obscured by fog and/or other meteorological phenomena",
            },
            {
              value: "/",
              text: "/ - Cloud cover is indiscernible for reasons other than fog or other meteorological phenomena, or observation is not made",
            },
          ],
          jenis: [
            { value: "3", text: "altocumulus (Ac)" },
            { value: "4", text: "altostratus (As)" },
            { value: "5", text: "nimbostratus (Ns)" },
            { value: "/", text: "cloud not visible" },
          ],
          jumlah: [
            { value: "0", text: "0" },
            { value: "1", text: "1 okta or less, but not zero" },
            { value: "2", text: "2 oktas" },
            { value: "3", text: "3 oktas" },
            { value: "4", text: "4 oktas" },
            { value: "5", text: "5 oktas" },
            { value: "6", text: "6 oktas" },
            { value: "7", text: "7 oktas or more, but not eight" },
            { value: "8", text: "8 oktas" },
            {
              value: "9",
              text: "9 - Sky obscured by fog and/or other meteorological phenomena",
            },
            {
              value: "/",
              text: "/ - Cloud cover is indiscernible for reasons other than fog or other meteorological phenomena, or observation is not made",
            },
          ],
          arah: [
            { value: 0, text: "Stationary or No Clouds" },
            { value: 1, text: "NE" },
            { value: 2, text: "E" },
            { value: 3, text: "SE" },
            { value: 4, text: "S" },
            { value: 5, text: "SW" },
            { value: 6, text: "W" },
            { value: 7, text: "NW" },
            { value: 8, text: "N" },
            { value: 9, text: "All Directions or Invisible" },
          ],
        },
        awan_tinggi: {
          CH: [
            { value: "0", text: "No CH clouds" },
            {
              value: "1",
              text: "Cirrus fibratis, sometimes uncinus, not progressively invading the sky",
            },
            {
              value: "2",
              text: "Cirrus spissatus, in patches or entangled sheaves, which usually do not increase and sometimes seem to be the remains of the upper part of Cumulonimbus, or Cirrus castellanus or floccus",
            },
            { value: "3", text: "Cirrus spissatus cumulonimbogenitus" },
            {
              value: "4",
              text: "Cirrus uncinus or fibratus, or both, progressively invading the sky; they generally thicken as a whole",
            },
            {
              value: "5",
              text: "Cirrus (often in bands) and Cirrostratus, or Cirrostratus alone, progressively invading the sky; they generally thicken as a whole, but the continuous veil does not reach 45 degrees above the horizon",
            },
            {
              value: "6",
              text:
                "Cirrus (often in bands) and Cirrostratus, or Cirrostratus alone, progressively invading the sky; they generally thicken as a whole, but the continuous veil extends more than 45 degrees above the horizon, withhout the sky being totally covered",
            },
            { value: "7", text: "Cirrostratus covering the whole sky" },
            {
              value: "8",
              text: "Cirrostratus not progressively invading the sky and not entirely covering it",
            },
            {
              value: "9",
              text: "Cirrocumulus alone, or Cirrocumulus predominant among the CH clouds",
            },
            {
              value: "/",
              text: "CH clouds not visible owing to darkness, fog, blowing dust or sand, or other similar phenomena, or because of a continuous layer of lower clouds",
            },
          ],
          NCH: [
            { value: "0", text: "0" },
            { value: "1", text: "1 okta or less, but not zero" },
            { value: "2", text: "2 oktas" },
            { value: "3", text: "3 oktas" },
            { value: "4", text: "4 oktas" },
            { value: "5", text: "5 oktas" },
            { value: "6", text: "6 oktas" },
            { value: "7", text: "7 oktas or more, but not eight" },
            { value: "8", text: "8 oktas" },
            {
              value: "9",
              text: "9 - Sky obscured by fog and/or other meteorological phenomena",
            },
            {
              value: "/",
              text: "/ - Cloud cover is indiscernible for reasons other than fog or other meteorological phenomena, or observation is not made",
            },
          ],
          jenis: [
            { value: "0", text: "cirrus (Ci)" },
            { value: "1", text: "cirrocumulus (Cc)" },
            { value: "2", text: "cirrostratus (Cs)" },
            { value: "/", text: "cloud not visible" },
          ],
          jumlah: [
            { value: "0", text: "0" },
            { value: "1", text: "1 okta or less, but not zero" },
            { value: "2", text: "2 oktas" },
            { value: "3", text: "3 oktas" },
            { value: "4", text: "4 oktas" },
            { value: "5", text: "5 oktas" },
            { value: "6", text: "6 oktas" },
            { value: "7", text: "7 oktas or more, but not eight" },
            { value: "8", text: "8 oktas" },
            {
              value: "9",
              text: "9 - Sky obscured by fog and/or other meteorological phenomena",
            },
            {
              value: "/",
              text: "/ - Cloud cover is indiscernible for reasons other than fog or other meteorological phenomena, or observation is not made",
            },
          ],
          arah: [
            { value: 0, text: "Stationary or No Clouds" },
            { value: 1, text: "NE" },
            { value: 2, text: "E" },
            { value: 3, text: "SE" },
            { value: 4, text: "S" },
            { value: 5, text: "SW" },
            { value: 6, text: "W" },
            { value: 7, text: "NW" },
            { value: 8, text: "N" },
            { value: 9, text: "All Directions or Invisible" },
          ],
        },
      },
      awan_rendah: {
        cloud_2: false,
        cloud_3: false,
        CL: "",
        NCL: "",
        jenis: {
          [0]: "",
          [1]: "",
          [2]: "",
        },
        jumlah: {
          [0]: "",
          [1]: "",
          [2]: "",
        },
        tinggi_dasar: {
          [0]: "",
          [1]: "",
          [2]: "",
        },
        tinggi_puncak: {
          [0]: "",
          [1]: "",
        },
        arah: {
          [0]: "",
          [1]: null, //"",
          [2]: null, //"",
        },
        sudut: {
          [0]: "",
          [1]: "",
        },
        da: {
          [0]: "",
          [1]: null, //"",
        },
      },
      awan_menengah: {
        cloud_2: false,
        CM: "",
        NCM: "",
        jenis: {
          [0]: "",
          [1]: "",
        },
        jumlah: {
          [0]: "",
          [1]: "",
        },
        tinggi_dasar: {
          [0]: "",
          [1]: "",
        },
        arah: {
          [0]: "",
          [1]: "",
        },
      },
      awan_tinggi: {
        cloud_2: false,
        CH: "",
        NCH: "",
        jenis: {
          [0]: "",
          [1]: "",
        },
        jumlah: {
          [0]: "",
          [1]: "",
        },
        tinggi_dasar: {
          [0]: "",
          [1]: "",
        },
        arah: {
          [0]: "",
          [1]: "",
        },
      },
      // start: otomatis setCloudslayer()
      cloud_layer_1_type_c: null,
      cloud_layer_1_height_m_hshs: null,
      cloud_layer_1_amt_oktas_ns: null,
      cloud_layer_2_type_c: null,
      cloud_layer_2_height_m_hshs: null,
      cloud_layer_2_amt_oktas_ns: null,
      cloud_layer_3_type_c: null,
      cloud_layer_3_height_m_hshs: null,
      cloud_layer_3_amt_oktas_ns: null,
      cloud_layer_4_type_c: null,
      cloud_layer_4_height_m_hshs: null,
      cloud_layer_4_amt_oktas_ns: null,
      // end: otomatis setCloudslayer()
      has_barometer_air_raksa: true,
      rainfall_6h_rrr: null,
      rainfall_24h_rrrr: null,
      pressure_tend_3h_mb_ppp: null,
      pressure_tend_24h_mb_ppp: null,
      pressure_tend_3h_mb_www: null,
      pressure_tend_2h_mb_www: null,
      pressure_tend_1h_mb_www: null,
      temp_dewpoint_c_tdtdtd: "",
      relative_humidity_pc: "",
      koreksi_pressure_qff_mb_derived: null,
      koreksi_pressure_qfe_mb_derived: null,
      sandi_tr: null,
      // QFF QFE sementara buat testing
      QFF_QFE: {},
      // QFF_QFE: {
      //   17.5: { pc_qff: -2.4, pc_qfe: -5.4 },
      //   18: { pc_qff: -2.5, pc_qfe: -5.5 },
      //   18.5: { pc_qff: -2.6, pc_qfe: -5.6 },
      //   19: { pc_qff: -2.7, pc_qfe: -5.7 },
      //   19.5: { pc_qff: -2.7, pc_qfe: -5.8 },
      //   20: { pc_qff: -2.8, pc_qfe: -5.8 },
      //   20.5: { pc_qff: -2.9, pc_qfe: -5.9 },
      //   21: { pc_qff: -3, pc_qfe: -6 },
      //   21.5: { pc_qff: -3.1, pc_qfe: -6.1 },
      //   22: { pc_qff: -3.2, pc_qfe: -6.2 },
      //   22.5: { pc_qff: -3.2, pc_qfe: -6.3 },
      //   23: { pc_qff: -3.3, pc_qfe: -6.3 },
      //   23.5: { pc_qff: -3.4, pc_qfe: -6.4 },
      //   24: { pc_qff: -3.5, pc_qfe: -6.5 },
      //   24.5: { pc_qff: -3.6, pc_qfe: -6.6 },
      //   25: { pc_qff: -3.7, pc_qfe: -6.7 },
      //   25.5: { pc_qff: -3.8, pc_qfe: -6.8 },
      //   26: { pc_qff: -3.8, pc_qfe: -6.8 },
      //   26.5: { pc_qff: -3.9, pc_qfe: -6.9 },
      //   27: { pc_qff: -4, pc_qfe: -7 },
      //   27.5: { pc_qff: -4.1, pc_qfe: -7.1 },
      //   28: { pc_qff: -4.2, pc_qfe: -7.2 },
      //   28.5: { pc_qff: -4.3, pc_qfe: -7.3 },
      //   29: { pc_qff: -4.3, pc_qfe: -7.3 },
      //   29.5: { pc_qff: -4.4, pc_qfe: -7.4 },
      //   30: { pc_qff: -4.5, pc_qfe: -7.5 },
      //   30.5: { pc_qff: -4.6, pc_qfe: -7.6 },
      //   31: { pc_qff: -4.7, pc_qfe: -7.7 },
      //   31.5: { pc_qff: -4.8, pc_qfe: -7.9 },
      //   32: { pc_qff: -4.8, pc_qfe: -7.9 },
      //   32.5: { pc_qff: -4.9, pc_qfe: -7.9 },
      //   33: { pc_qff: -5, pc_qfe: -8 },
      //   33.5: { pc_qff: -5.1, pc_qfe: -8.1 },
      //   34: { pc_qff: -5.2, pc_qfe: -8.2 },
      //   34.5: { pc_qff: -5.3, pc_qfe: -8.3 },
      //   35: { pc_qff: -5.3, pc_qfe: -8.4 },
      //   35.5: { pc_qff: -6.5, pc_qfe: -8.7 },
      //   36: { pc_qff: -7.8, pc_qfe: -9 },
      //   36.5: { pc_qff: -7.8, pc_qfe: -9.1 },
      //   37: { pc_qff: -7.9, pc_qfe: -9.2 },
      //   37.5: { pc_qff: -8, pc_qfe: -9.3 },
      //   38: { pc_qff: -8.1, pc_qfe: -9.4 },
      //   38.5: { pc_qff: -8.2, pc_qfe: -9.5 },
      //   39: { pc_qff: -8.3, pc_qfe: -9.5 },
      //   39.5: { pc_qff: -8.4, pc_qfe: -9.6 },
      // },
      sandi: "",
      sandi_with_header: "",
      sandi_gts_message: "",
      sandiEditable: 0,
      preview_ref: {
        present_weather_ww: {
          0: "Cld dev unk",
          1: "Cld decr",
          2: "Cld unch",
          3: "Cld incr",
          4: "Smoke",
          5: "Haze",
          6: "Dush 06",
          7: "Dust 07 atau Sand 07",
          8: "DW atau SW",
          9: "DS 09 atau SS 09",
          10: "Mist",
          11: "Shalow fog 11",
          12: "Shalow fog 12",
          13: "Lighting",
          14: "Prec. In Sight 14",
          15: "Prec. In Sight 15",
          16: "Prec. In Sight 16",
          17: "Ts no prec",
          18: "Squalls",
          19: "Funnel Cld",
          20: "Re DZ (not FR) atau SN.GR",
          21: "Re RA (not FR)",
          22: "Re SN",
          23: "Re RA + SN atau ice pellets",
          24: "Re FR DZ atau Re. FR.RA",
          25: "Re SH of RA",
          26: "Re SH of SN, atau Re SH of RA + SN",
          27: "Re SH of HA, ataumRe SH of RA + HA",
          28: "Re Fog atau Re ice fog",
          29: "Re TS",
          30: "Sl/Mod DS atau SS decr",
          31: "Sl/Mod DS atau SS unch",
          32: "Sl/Mod DS atau SS incr",
          33: "Sev DS atau SS decr",
          34: "Sev DS atau SS unch",
          35: "Sev DS atau SS incr",
          36: "Sl/Mod drifting SN low",
          37: "Heavy drifting SN low",
          38: "Sl/Mod blowing SN high",
          39: "Heavy blowing SN high",
          40: "Fog at a dist",
          41: "Fog in patches",
          42: "Fog SV thinner",
          43: "Fog S inv thinner",
          44: "Fog SV unch",
          45: "Fog S inv unch",
          46: "Fog SV thicker",
          47: "Fog S inv thicker",
          48: "Fog dep rime SV",
          49: "Fog dep rime S inv",
          50: "Inter Sl DZ",
          51: "Cns Sl DZ",
          52: "Inter Mod DZ",
          53: "Cns Mod DZ",
          54: "Inter heavy (dense) DZ",
          55: "Cns heavy (dense) DZ",
          56: "Sl DZ FR",
          57: "Mod/heavy (dense) DZ FR",
          58: "Sl/DZ and RA",
          59: "Mod/heavy DZ and RA",
          60: "Inter Sl RA",
          61: "Cns Sl RA",
          62: "Inter mod RA",
          63: "Cns Mod RA",
          64: "Inter heavy RA",
          65: "Cns heavy RA",
          66: "Sl RA FR",
          67: "Mod/heavy RA FR",
          68: "Sl RA and SN atau Sl DZ and SN",
          69: "Mod/heavy RA and SN atau Mod/heavy DZ and SN",
          70: "Inter Sl of SNF",
          71: "Cns Sl os SNF",
          72: "Inter mod of SNF",
          73: "Cns mod of SNF",
          74: "Inter heavy of SNF",
          75: "Cns Heavy of SNF",
          76: "Diamod dust fog atau diamond dust no fog",
          77: "SN grains fog atau SN grains no fog",
          78: "SN crystal fog atau SN crystal no fog",
          79: "Ice pellets",
          80: "Sl RA SH",
          81: "Mod/heavy RA SH",
          82: "Violent RA SH",
          83: "Sl RA + SN SH",
          84: "Mod/heavy RA + SN SH",
          85: "Sl SN SH 85",
          86: "Mod/heavy SN SH 86",
          87: "Sl SN SH 87",
          88: "Mod/heavy SN SH 88",
          89: "Sl Hail SH",
          90: "Mod/heavy hail SH",
          91: "Sl RA re TS",
          92: "Mod/heavy RA re TS",
          93: "Sl SN atau RA + SN atau HA re TS",
          94: "Mod/heavy SN atau RA + SN atau HA + TS",
          95: "Sl/mod TS no HA + RA atau RA + SN atau SN",
          96: "Sl/mod TS + HA",
          97: "Heavy TS no HA + RA atau RA + SN atau SN",
          98: "TS + DS/SS",
          99: "Heavy TS + HA",
        },
        past_weather_w1: {
          0: "Cloudy -",
          1: "Cloudy ±",
          2: "Cloudy +",
          3: "DS, SS atau blowing",
          4: "Fog atau thick haze / thick smoke",
          5: "DZ",
          6: "RA",
          7: "SN",
          8: "SH",
          9: "TS",
          "/": "/",
        },
        past_weather_w2: {
          0: "Cloudy -",
          1: "Cloudy ±",
          2: "Cloudy +",
          3: "DS, SS atau blowing",
          4: "Fog atua thick haze / thick smoke",
          5: "DZ",
          6: "RA",
          7: "SN",
          8: "SH",
          9: "TS",
          "/": "/",
        },
        awan_rendah: {
          "0": "0",
          "1": "Cu",
          "2": "Cu",
          "3": "Cb",
          "4": "Sc",
          "5": "Sc",
          "6": "St",
          "7": "Cu",
          "8": "CuSc",
          "9": "Cb",
          "/": "/",
        },
        awan_menengah: {
          "0": "0",
          "1": "As",
          "2": "As",
          "3": "Ac",
          "4": "Ac",
          "5": "Ac",
          "6": "Ac",
          "7": "AcAs",
          "8": "Ac",
          "9": "Ac",
          "/": "/",
        },
        awan_tinggi: {
          "0": "0",
          "1": "Ci",
          "2": "Ci",
          "3": "Ci",
          "4": "Ci",
          "5": "CiCs",
          "6": "CiCs",
          "7": "Cs",
          "8": "Cs",
          "9": "Cc",
          "/": "/",
        },
        // sudut: {
        //   "0": "STNR",
        //   "1": "045",
        //   "2": "090",
        //   "3": "135",
        //   "4": "180",
        //   "5": "225",
        //   "6": "270",
        //   "7": "315",
        //   "8": "360",
        //   "9": "Not Visible",
        // },
        sudut: {
          "0": "0", //"Not Visible",
          "1": "45",
          "2": "30",
          "3": "20",
          "4": "15",
          "5": "12",
          "6": "9",
          "7": "7",
          "8": "6",
          "9": "5",
        },
        lapisan_awan: {
          "0": "Ci",
          "1": "Cc",
          "2": "Cs",
          "3": "Ac",
          "4": "As",
          "5": "Ns",
          "6": "Sc",
          "7": "St",
          "8": "Cu",
          "9": "Cb",
          "/": "Not Visible",
        },
      },
      GC: {},
      RC: {},
      CC: {
        wind_dir_deg_dd: { status: false, message: "" },
        wind_speed_ff: { status: false, message: "" },
        visibility_vv: { status: false, message: "" },
        present_weather_ww: { status: false, message: "" },
        past_weather_w1: { status: false, message: "" },
        past_weather_w2: { status: false, message: "" },
        cloud_cover_oktas_m: { status: false, message: "" },
        cloud_low_type_cl: { status: false, message: "" },
        cloud_low_cover_oktas: { status: false, message: "" },
        cloud_med_type_cm: { status: false, message: "" },
        cloud_med_cover_oktas: { status: false, message: "" },
        cloud_high_type_ch: { status: false, message: "" },
        cloud_high_cover_oktas: { status: false, message: "" },
        temp_dewpoint_c_tdtdtd: { status: false, message: "" },
        temp_drybulb_c_tttttt: { status: false, message: "" },
        temp_max_c_txtxtx: { status: false, message: "" },
        temp_min_c_tntntn: { status: false, message: "" },
        cloud_elevation_1_angle_ec: {status: false, message: ""},
        cloud_elevation_2_angle_ec: {status: false, message: ""},
        temp_wetbulb_c: { status: false, message: "" }
      },
      SC: {},
      rainfall_pressure: null,
      temp_drybulb_c_tttttt_max: null,
      temp_drybulb_c_tttttt_min: null,
      temp_max_c_txtxtx_12: null,
      temp_min_c_tntntn_00: null,
      gts_send_to_bmkgswitching: 0,
      gts_main_header: "",
      gts_intermediate_header: "",
      gts_non_standard_hour_header: "",
      hasDigi : false,
      // stepcheck_param_last: {
      //   pressure_qff_mb_derived: null,
      //   pressure_qfe_mb_derived: null,
      //   temp_drybulb_c_tttttt: null,
      //   relative_humidity_pc: null
      // },
      // stepcheck_range: {
      //   pressure_qff_mb_derived: null,
      //   pressure_qfe_mb_derived: null,
      //   temp_drybulb_c_tttttt: null,
      //   relative_humidity_pc: null
      // }
      notif_params: {
        region_id: null,
        path_oinput: null
      }
    };
  },
  computed: {
    filter_complete() {
      let status = this.t_station && this.t_observer && this.tanggal && this.jam && this.is_data_ready_to_filled;
      // console.log('filter_complete', this.t_station, this.t_observer, this.tanggal, this.jam, this.is_data_ready_to_filled)
      return status;
      // return this.t_station && this.t_observer && this.tanggal && this.jam ;
    },
    t_data_id() {
      return this.t_station_id + "_sinoptik_" + this.tanggal + "T" + this.jam.substr(0, 2);
    },
    rainfall_indicator_ir: function () {
      let iR = -1;

      let rrlast = this.input_data.rainfall_last_mm;
      if (this.jam === "00:00") {
        let rr24 = +this.rainfall_jumlah_24h; // AN ch 24h here is string
       
        if (rrlast == 9999) { // 3 jam yll rusak
          iR = 4;
        } else if (rr24 == 9999 && rrlast) { // 24 jam yll rusak tetapi 3 jam yll normal
          iR = 2;
        } else if (rr24 && rrlast) { // 3 jam dan 24 jam yll hujan
          iR = 0;
        } else if (rr24 || rrlast) { // 24 jam yll hujan
          iR = 1;
        } else {
          iR = 3;
        }
      } else if (this.jam === "06:00" || this.jam === "12:00" || this.jam === "18:00") {
        let rr6 = +this.rainfall_jumlah_6h;
        if (rrlast == 9999) { // 3 jam yll rusak
          iR = 4;
        } else if (rr6 == 9999 && rrlast) { // 6 jam yll rusak tetapi 3 jam yll normal
          iR = 2;
        } else if (rr6 && rrlast) {
          iR = 0;
        } else if (rr6 || rrlast) {
          iR = 1;
        } else {
          iR = 3;
        }
      } else if (this.jam === "03:00" || this.jam === "09:00" || this.jam === "15:00" || this.jam === "21:00") {
        if (rrlast == 9999) { // 3 jam yll rusak
          iR = 4;
        } else if (rrlast) {
          iR = 2;
        } else {
          iR = 3;
        }
      } else {
        iR = 4;
      }

      return iR;
    },
    selisih_QFF_3h() {
      if (is_3hourly_obs(this.jam)) {
        // if (this.rainfall_pressure) {
        //   if (this.rainfall_pressure.hasOwnProperty('pressure_qff_mb_derived') &&  
        //      this.rainfall_pressure.pressure_qff_mb_derived.hasOwnProperty('items')) {
        //     let ppps = []
        //     for (var k in this.rainfall_pressure.pressure_qff_mb_derived.items) {
        //       ppps.push(k)
        //     }
        //     if (ppps.length > 0) {
        //       return parseFloat((+this.input_data.pressure_qff_mb_derived - ppps[0]).toFixed(1))
        //     }
        //   }
        //   else {
        //     return null
        //   }
        // }
        // else {
        // console.log('selisih_QFF_3h', 
        //  this.input_data.pressure_qff_mb_derived, 
        //  this.pressure_tend_3h_mb_www)
       
        const range_h = counter_clockwise_range(+this.jam.split(":")[0], this.t_start_hour)
        const current_qff = +this.input_data.pressure_qff_mb_derived
        if (this.pressure_tend_3h_mb_www !== null && 
          this.pressure_tend_3h_mb_www !== undefined &&
          this.pressure_tend_3h_mb_www !== 9999 &&
          current_qff !== 9999 &&
          (this.t_operating_hours == 24 || (this.t_operating_hours < 24 && range_h >= 3))) {
          return parseFloat((current_qff - 
            this.pressure_tend_3h_mb_www).toFixed(1));
        } else {
          return "-";
        }
        // }
      }
      return null;
    },
    selisih_QFF_24h() {
      // Cek null berarti diluar jam pengamatan
      if (this.pressure_tend_24h_mb_ppp !== null && this.pressure_tend_24h_mb_ppp !== undefined) {
        //  let dif_qff_24h = parseFloat((+this.input_data.pressure_qfe_mb_derived - this.pressure_tend_24h_mb_ppp).toFixed(1));
        // console.log('selisih_QFF_24h - this.pressure_tend_24h_mb_ppp', this.pressure_tend_24h_mb_ppp)
        
        let dif_qff_24h = ""
        const current_qff = +this.input_data.pressure_qff_mb_derived
        if (this.pressure_tend_24h_mb_ppp && 
          this.pressure_tend_24h_mb_ppp !== 9999 &&
          current_qff !== 9999) {
          dif_qff_24h = parseFloat((current_qff - 
            this.pressure_tend_24h_mb_ppp).toFixed(1));
        } else {
          dif_qff_24h = "-" // tidak 9999?
        }

        return dif_qff_24h;
        // return parseFloat((+this.input_data.pressure_qff_mb_derived - this.pressure_tend_24h_mb_ppp).toFixed(1));
      } else {
        return null;
      }
    },
    rainfall_jumlah_terakhir() {
      if (this.input_data.rainfall_last_mm !== null && this.input_data.rainfall_last_mm !== undefined
        && this.input_data.rainfall_last_mm != 8888 && this.input_data.rainfall_last_mm != 9999
        && this.input_data.rainfall_last_mm !== '') {
        return this.input_data.rainfall_last_mm.toFixed(1)
      } 
      return this.input_data.rainfall_last_mm;
    },
    rainfall_jumlah_6h() {
      if (this.rainfall_6h_rrr !== null && this.rainfall_6h_rrr !== undefined) {
        // return +this.input_data.rainfall_last_mm + +this.rainfall_6h_rrr;
        if (this.jam === "06:00" || this.jam === "12:00" || this.jam === "18:00" || this.jam === "00:00") { // AN tambah jam 00

          if (this.rainfall_6h_rrr == 9999 || this.input_data.rainfall_last_mm == 9999) {
            return 9999
          }

          if ((+this.rainfall_6h_rrr) == 8888) {
            return (+this.input_data.rainfall_last_mm) == 0 ? 8888 : (+this.input_data.rainfall_last_mm).toFixed(1)
          }
          return (+this.input_data.rainfall_last_mm) == 8888 ? (+this.rainfall_6h_rrr == 0 ? (+this.input_data.rainfall_last_mm) : (+this.rainfall_6h_rrr).toFixed(1))
            :
            (+this.input_data.rainfall_last_mm + +this.rainfall_6h_rrr).toFixed(1);

          // return +this.input_data.rainfall_last_mm + +this.rainfall_6h_rrr;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    rainfall_jumlah_24h() {
      if (this.rainfall_24h_rrrr !== null && this.rainfall_24h_rrrr !== undefined) {
        // return +this.input_data.rainfall_last_mm + +this.rainfall_24h_rrrr;
        if (this.jam === "00:00") {

          if (this.rainfall_24h_rrrr == 9999 || this.input_data.rainfall_last_mm == 9999) {
            return 9999
          }

          if ((+this.rainfall_24h_rrrr) == 8888) {
            return (+this.input_data.rainfall_last_mm) == 0 ? 8888 : (+this.input_data.rainfall_last_mm).toFixed(1)
          }
          else if ((+this.rainfall_jumlah_6h) == 8888 && +this.rainfall_24h_rrrr == 0) {
            return 8888
          }
          return (+this.input_data.rainfall_last_mm) == 8888 ? (+this.rainfall_24h_rrrr == 0 ? (+this.input_data.rainfall_last_mm) : (+this.rainfall_24h_rrrr).toFixed(1))
            :
            (+this.input_data.rainfall_last_mm + +this.rainfall_24h_rrrr).toFixed(1);

          // return +this.input_data.rainfall_last_mm + +this.rainfall_24h_rrrr;
        }
      } else {
        return null;
      }
    },
    nh_me48(){
      // shall always be reported on form eventhough 8nhclcmch ga dilaporkan
      if(!this.disabled.is_cloud_cover_block) {
        return get_source_nh(this.awan_rendah.NCL, this.awan_menengah.NCM)
      }

      // Karena sumber CL CM tidak dipakai jadi pakai awan terendah?
      return this.awan_rendah.NCL
    },
    low_cloud_type_list() {
      if (this.awan_rendah?.NCL == '9') {
        return '-';
      } else if (this.awan_rendah?.NCL == '/') {
       return '';
      } else {
        return get_combination_clouds(this.awan_rendah?.jenis, this.options?.awan_rendah?.jenis)
      }
   },
    medium_cloud_type_list() {
      if (this.awan_menengah?.NCM == '9') {
        return '-';
      } else if (this.awan_menengah?.NCM == '/') {
       return '';
      } else {
        return get_combination_clouds(this.awan_menengah?.jenis, 
          this.options?.awan_menengah?.jenis)
      }
    },
    high_cloud_type_list() {
      if (this.awan_tinggi?.NCH == '9') {
        return '-';
      } else if (this.awan_tinggi?.NCH == '/') {
       return '';
      } else {
        return get_combination_clouds(this.awan_tinggi?.jenis, 
          this.options?.awan_tinggi?.jenis)
      }
    },
    enable_send_gts() {
      const dataid = this.t_data_id.split("T");
      const jam = dataid[1];
      let configured_header = null
      if (jam == "00" || jam == "06" || jam == "12" || jam == "18") {
        configured_header = this.gts_main_header
      } else if ( jam == "03" || jam == "09" || jam == "15" || jam == "21"){
        configured_header = this.gts_intermediate_header
      } else if ( jam == "01" || jam == "02" || jam == "04" || jam == "05" || jam == "07" || jam == "08" || jam == "10" || jam == "11" 
              || jam == "13" || jam == "14" || jam == "16" || jam == "17" || jam == "19" || jam == "20" || jam == "22" || jam == "23"){
        configured_header = this.gts_non_standard_hour_header
      }

      return configured_header !== null && configured_header !== ""
    },
    // AN TODO : Should be separated for each value for performance
    disabled: function () {
      return {
        pressure_temp_c: this.t_barometer_digital,
        pressure_reading_mb: this.t_barometer_digital,
        pressure_qff_mb_derived: this.t_barometer_air_raksa,
        pressure_qfe_mb_derived: this.t_barometer_air_raksa,
        temp_max_c_txtxtx: this.jam !== "12:00",
        temp_min_c_tntntn: this.jam !== "00:00",
        rainfall_last_mm: !(this.jam === "00:00" || this.jam === "03:00" || this.jam === "06:00" || this.jam === "09:00" || this.jam === "12:00" || this.jam === "15:00" || this.jam === "18:00" || this.jam === "21:00"),
        awan_rendah: {
          cloud_1: this.awan_rendah.CL === "0" || this.awan_rendah.CL === "/",
          cloud_2: !this.awan_rendah.cloud_2,
          cloud_3: !this.awan_rendah.cloud_3,
          tinggi_puncak: {
            [0]: !(this.awan_rendah.jenis[0] === "8" || this.awan_rendah.jenis[0] === "9"),
            [1]: !this.awan_rendah.cloud_2 || !(this.awan_rendah.jenis[1] === "8" || this.awan_rendah.jenis[1] === "9"),
          },
          NCL: (this.awan_rendah.CL === "0" || this.awan_rendah.CL === "/")
          // sudut: {
          //   [0]: !(this.awan_rendah.jenis[0] === "8" || this.awan_rendah.jenis[0] === "9"),
          //   [1]: !this.awan_rendah.cloud_2 || !(this.awan_rendah.jenis[1] === "8" || this.awan_rendah.jenis[1] === "9"),
          // },
        },
        cloud_elevation_1_angle_ec: !(this.awan_rendah.jenis[0] === "8" || this.awan_rendah.jenis[0] === "9"),
        cloud_elevation_2_angle_ec: !this.awan_rendah.cloud_2 || !(this.awan_rendah.jenis[1] === "8" || this.awan_rendah.jenis[1] === "9"),
        awan_menengah: {
          cloud_1: this.awan_menengah.CM === "0" || this.awan_menengah.CM === "/",
          cloud_2: !this.awan_menengah.cloud_2,
          NCM: this.awan_menengah.CM === "0" || this.awan_menengah.CM === "/"
        },
        awan_tinggi: {
          cloud_1: this.awan_tinggi.CH === "0" || this.awan_tinggi.CH === "/",
          cloud_2: !this.awan_tinggi.cloud_2,
          NCH: this.awan_tinggi.CH === "0" || this.awan_tinggi.CH === "/"
        },
        evaporation_24hours_mm_eee: this.jam !== "00:00",
        evaporation_eq_indicator_ie: this.jam !== "00:00",
        solar_rad_24h_jcm2_f24: this.jam !== "00:00",
        sunshine_h_sss: this.jam !== "00:00",
        cloud_vertical_vis: this.input_data.cloud_cover_oktas_m != "9",
        submit: !this.donePreview,
        is_cloud_cover_block: (this.input_data.cloud_cover_oktas_m == "9" 
          || this.input_data.cloud_cover_oktas_m == "0"
          || this.input_data.cloud_cover_oktas_m == "/")
      };
    },
  },
  mounted() {
    this.listStasiun();
  },
  methods: {

    timeWSOption(time) {

      let startHour = this.t_start_hour
      let endHour = this.t_end_hour
      let midStartHour = this.t_mid_start_hour
      let midEndHour = this.t_mid_end_hour

      if (startHour == 0) {
        if (time >= startHour && time <= endHour){
          return false
        }
      }
      else {
        if ((time >= startHour && time <= midEndHour) || (time >= midStartHour && time <= endHour)){
          return false
        }
      }
      return true
    },

    onKeyCheck(event) {
      if (event.key === "ArrowUp" || event.key === "ArrowDown") {
        event.preventDefault();
      }
    },

    consistency_check_tooltip (param) {
      // console.log('consistency_check_tooltip', param)
      switch (param) {
        case "temp_drybulb_c_tttttt":
          if (this.input_data.temp_drybulb_c_tttttt < this.input_data.temp_wetbulb_c) {
            let result = { status: true, message: "Suhu Bola Kering >= Suhu Bola Basah" };

            return result;
          }
          return { status: false, message: "" };
        case "temp_wetbulb_c":
          if (this.input_data.temp_drybulb_c_tttttt < this.input_data.temp_wetbulb_c) {
            let result =  { status: true, message: "Suhu Bola Basah < Suhu Bola Kering" };
            // console.log('consistency_check_tooltip - ', param, result)
            return result
          }
          return { status: false, message: "" };

        // case "temp_max_c_txtxtx":
        //   if (this.input_data.temp_max_c_txtxtx && 
        //     this.temp_min_c_tntntn_00 && 
        //     this.temp_min_c_tntntn_00 >= 0 && 
        //     this.input_data.temp_max_c_txtxtx < this.temp_min_c_tntntn_00 &&
        //     this.input_data.temp_max_c_txtxtx != 9999 &&
        //     this.temp_min_c_tntntn_00 != 9999
        //     ) {
        //     return { status: true, message: "Tx >= Tn; Tn=" + this.temp_min_c_tntntn_00 };
        //   }
        //   return { status: false, message: "" };

        // case "temp_min_c_tntntn":
        //   if (this.input_data.temp_min_c_tntntn && 
        //     this.temp_max_c_txtxtx_12 && 
        //     this.temp_max_c_txtxtx_12 >= 0 && 
        //     this.temp_max_c_txtxtx_12 < this.input_data.temp_min_c_tntntn &&
        //     this.input_data.temp_min_c_tntntn != 9999 &&
        //     this.temp_max_c_txtxtx_12 != 9999) {
        //     return { status: true, message: "Tx >= Tn; Tx=" + this.temp_max_c_txtxtx_12 };
        //   }
        //   return { status: false, message: "" };

        // case "maxTT":
        //   if (this.input_data.temp_max_c_txtxtx && 
        //     this.temp_drybulb_c_tttttt_max && 
        //     this.temp_drybulb_c_tttttt_max >= 0 && 
        //     this.input_data.temp_max_c_txtxtx > +this.temp_drybulb_c_tttttt_max + 3 &&
        //     this.input_data.temp_max_c_txtxtx != 9999 &&
        //     this.temp_drybulb_c_tttttt_max != 9999) {
        //     return { status: true, message: "Tx < Max(TT) + 3; Max(TT)=" + this.temp_drybulb_c_tttttt_max };
        //   }
        //   return { status: false, message: "" };

        // case "minTT":
        //   if (this.input_data.temp_min_c_tntntn && 
        //     this.temp_drybulb_c_tttttt_min && 
        //     this.temp_drybulb_c_tttttt_min >= 0 && 
        //     this.input_data.temp_min_c_tntntn < this.temp_drybulb_c_tttttt_min - 3 &&
        //     this.input_data.temp_min_c_tntntn != 9999 &&
        //     this.temp_drybulb_c_tttttt_min != 9999) {
        //     return { status: true, message: "Tn > Min(TT) - 3; Min(TT)=" + this.temp_drybulb_c_tttttt_min };
        //   }
        //   return { status: false, message: "" };

        case "pressure_qff_mb_derived":
          if (this.input_data.pressure_qff_mb_derived < this.input_data.pressure_qfe_mb_derived) {
            return { status: true, message: "Tekanan QFF >= Tekanan QFE" };
          }
          return { status: false, message: "" };
        case "pressure_qfe_mb_derived":
          if (this.input_data.pressure_qff_mb_derived < this.input_data.pressure_qfe_mb_derived) {
            return { status: true, message: "Tekanan QFF >= Tekanan QFE" };
          }
          return { status: false, message: "" };
        // case "awan_rendah.sudut[0]":
        //   if (this.awan_rendah.tinggi_puncak[0]
        //     && (this.awan_rendah.sudut[0] == "-" 
        //       || this.awan_rendah.sudut[0] == null 
        //       || this.awan_rendah.sudut[0] == "0")) {
        //     let result = { status: true, message: "Sudut awan ≠ [-, 0]" };

        //     return result;
        //   }
        //   return { status: false, message: "" };
        // case "awan_rendah.sudut[1]":
        //   if (this.awan_rendah.tinggi_puncak[1]
        //     && (this.awan_rendah.sudut[1] == "-" 
        //       || this.awan_rendah.sudut[1] == null 
        //       || this.awan_rendah.sudut[1] == "0")) {
        //     let result = { status: true, message: "Sudut awan ≠ [-, 0]" };

        //     return result;
        //   }
        //   return { status: false, message: "" };
        default:
          return { status: false, message: "" };
      }
    },

    perawanan_tinggi(tinggi, clmh, nclmh, multi=false) {
      function get_perawanan_tinggi(tinggi, clmh) {
        if (clmh >= 1 && clmh <= 9) {
          return tinggi;
        }
      }

      // clmh should be / too
      if (nclmh == "/") {
        return ""
      }
      // nclmh 9
      if (clmh == "/") {
        return "-"
      } 

      if (clmh) {
        if (multi) {      
          const length_tinggi = Object.keys(tinggi).length
          let result = ""
          for (let ind=0;ind < length_tinggi;ind++) {
           
            if (tinggi[ind] === '' || tinggi[ind] == null ||
                tinggi[ind] == undefined) {
                break;
            }

            if (ind > 0) {
              result += ' / '
            }
            result += get_perawanan_tinggi(tinggi[ind], clmh)
          }
          return result
        } else {
          return get_perawanan_tinggi(tinggi, clmh)
        }
      }
      return ""
      // if (clmh) {
      //   if (clmh == "/") {
      //     return "-"
      //   } else if (clmh >= 1 && clmh <= 9) {
      //     return tinggi;
      //   }
      // }
    },

    perawanan_arah(arah, clmh, nclmh, multi=false) {
      // untuk perawanan dari CL atau CM
      // contoh: perawanan_arah(awan_rendah.arah[0], awan_rendah.CL)
      // perawanan_arah(awan_rendah.arah, awan_rendah.CL)
      function get_perawanan_arah(arah, clmh, fn_derajat_arah_awan) {
        if (clmh >= 1 && clmh <= 9) {
          return fn_derajat_arah_awan(arah);
        }
      }

      // clmh should be / too
      if (nclmh == "/") {
        return ""
      }

      // nclmh 9
      if (clmh == 0) {
        return "No Clouds";
      } else if (clmh == "/") {
        return "-"
      }
      
      if (clmh) {
        if (multi) {      
          const length_arah = Object.keys(arah).length
          let result = ""
          // console.log('arah', arah, Object.keys(arah).length)
          for (let ind=0;ind < length_arah;ind++) {
            if (arah[ind] === '' || arah[ind] == null ||
                arah[ind] == undefined) {
                break;
            }

            if (ind > 0) {
              result += ' / '
            }
            result += get_perawanan_arah(arah[ind], clmh, 
              this.derajat_arah_awan)
          }
          return result
        } else {
          return get_perawanan_arah(arah, clmh, this.derajat_arah_awan)
        }
      }
      return ""
    },

    perawanan_elevasi(elevasi, cl, ncl) {
      if (cl && ncl != "/") {
        if (cl == 0) {
          return "No Clouds";
        } else if (cl == "/") {
          return "-"
        } else if (cl >= 1 && cl <= 9) {
          return this.preview_ref.sudut[elevasi];
        }
      }
    },

    perawanan_banyaknya(oktas) {
      if (oktas == "/") {
        return ""
      } else if (oktas == 9) {
        return "-"
      } else {
        return oktas
      }
    },

    derajat_arah_awan(arah) {
      let dict = {
        0: "STNR",
        1: 45,
        2: 90,
        3: 135,
        4: 180,
        5: 225,
        6: 270,
        7: 315,
        8: 360,
        9: "-",
      };
      return dict[arah];
    },

    // AN : Edit to optimize fetch
    setQCData(ref_data) {
      // console.log('setQCData', ref_data)
      //const ref_data = await metadata.getReferensiData(this.t_station.path);
      if (ref_data && Object.keys(ref_data).length > 0) {
        const qc_data = ref_data[Params.GET_QC_STATION_KEY("me48")]
        const qc_params = Params.GET_PARAMS("me48");
        const qc_key = Params.GET_KEY("me48");
        this.RC_helper.setData(qc_data, qc_params, qc_key);
        // console.log('this.RC_helper', this.RC_helper)
        const qff_qfe = ref_data[Params.GET_QC_STATION_KEY("table_qff_qfe")].qc_pressure_correction_qfe_qff
        if (qff_qfe && Object.keys(qff_qfe).length > 0) {
          this.QFF_QFE = qff_qfe
          // console.log('this.QFF_QFE', this.QFF_QFE)
        }

        /** AMBIL REFERENSI STEPCHECK */
        this.SC_helper.saveStepCheckReference(qc_data)

        // const sc_qff = ref_data[Params.GET_QC_STATION_KEY("pressure_qff_mb_derived")].pressure_QFF_mb_derived
        // this.SC_helper.saveStepCheckReference(sc_qff, 'pressure_qff_mb_derived')

        // const sc_qfe = ref_data[Params.GET_QC_STATION_KEY("pressure_qfe_mb_derived")].pressure_QFE_mb_derived
        // this.SC_helper.saveStepCheckReference(sc_qfe, 'pressure_qfe_mb_derived')

        // const sc_drybulb = ref_data[Params.GET_QC_STATION_KEY("temp_drybulb_c_tttttt")].temp_drybulb_c_tttttt
        // this.SC_helper.saveStepCheckReference(sc_drybulb, 'temp_drybulb_c_tttttt')

        // const sc_wetbulb = ref_data[Params.GET_QC_STATION_KEY("temp_wetbulb_c")].temp_wetbulb_c
        // this.SC_helper.saveStepCheckReference(sc_wetbulb, 'temp_wetbulb_c')

        // const sc_rh = ref_data[Params.GET_QC_STATION_KEY("relative_humidity_pc")].relative_humidity_qc
        // this.SC_helper.saveStepCheckReference(sc_rh, 'relative_humidity_pc')

        const gts_ref = ref_data["BmkgSatu.metadata.gts.IGTS"]
        this.gts_send_to_bmkgswitching = gts_ref.sinop_send_to_bmkgswitching
        this.gts_main_header = gts_ref.sinop_gts_main_header
        this.gts_intermediate_header = gts_ref.sinop_gts_intermediate_header
        this.gts_non_standard_hour_header = gts_ref.sinop_gts_non_standard_hour_header

      }
      else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Gagal load data referensi. Silahkan coba lagi atau periksa koneksi anda terlebih dahulu",
            icon: "BellIcon",
            variant: "warning",
          },
        });
      }
    },

    // async setQCData() {
    //   // const qc_data = await metadata.getQCData(this.t_station.path, Params.GET_QC_STATION_KEY("me48"));
    //   const ref_data = await metadata.getReferensiData(this.t_station.path);
    //   if (ref_data && Object.keys(ref_data).length > 0) {
    //     const qc_data = ref_data[Params.GET_QC_STATION_KEY("me48")]
    //     const qc_params = Params.GET_PARAMS("me48");
    //     const qc_key = Params.GET_KEY("me48");
    //     this.RC_helper.setData(qc_data, qc_params, qc_key);
    //     // console.log('this.RC_helper', this.RC_helper)
    //     const qff_qfe = ref_data[Params.GET_QC_STATION_KEY("table_qff_qfe")].qc_pressure_correction_qfe_qff
    //     if (qff_qfe && Object.keys(qff_qfe).length > 0) {
    //       this.QFF_QFE = qff_qfe
    //       // console.log('this.QFF_QFE', this.QFF_QFE)
    //     }

    //     /** AMBIL REFERENSI STEPCHECK */

    //     const sc_qff = ref_data[Params.GET_QC_STATION_KEY("pressure_qff_mb_derived")].pressure_QFF_mb_derived
    //     this.SC_helper.saveStepCheckReference(sc_qff, 'pressure_qff_mb_derived')

    //     const sc_qfe = ref_data[Params.GET_QC_STATION_KEY("pressure_qfe_mb_derived")].pressure_QFE_mb_derived
    //     this.SC_helper.saveStepCheckReference(sc_qfe, 'pressure_qfe_mb_derived')

    //     const sc_drybulb = ref_data[Params.GET_QC_STATION_KEY("temp_drybulb_c_tttttt")].temp_drybulb_c_tttttt
    //     this.SC_helper.saveStepCheckReference(sc_drybulb, 'temp_drybulb_c_tttttt')

    //     const sc_wetbulb = ref_data[Params.GET_QC_STATION_KEY("temp_wetbulb_c")].temp_wetbulb_c
    //     this.SC_helper.saveStepCheckReference(sc_wetbulb, 'temp_wetbulb_c')

    //     // const sc_rh = ref_data[Params.GET_QC_STATION_KEY("relative_humidity_pc")].relative_humidity_qc
    //     // this.SC_helper.saveStepCheckReference(sc_rh, 'relative_humidity_pc')

    //     const gts_ref = ref_data["BmkgSatu.metadata.gts.IGTS"]
    //     this.gts_send_to_bmkgswitching = gts_ref.sinop_send_to_bmkgswitching
    //     this.gts_main_header = gts_ref.sinop_gts_main_header
    //     this.gts_intermediate_header = gts_ref.sinop_gts_intermediate_header

    //   }
    //   else {
    //     this.$toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: "Gagal load data referensi. Silahkan coba lagi atau periksa koneksi anda terlebih dahulu",
    //         icon: "BellIcon",
    //         variant: "warning",
    //       },
    //     });
    //   }
    // },
    grosscheck_status (type) {
      if (this.GC[type]?.status) {
        // console.log('grosscheck_status', type, this.GC)
        return this.GC[type];
      } else {
        // console.log('grosscheck_status else', type, false)
        return false;
      }
    },

    stepcheck_status (type) {
      // console.log('stepcheck_status', type)
      if (this.SC[type]?.status) {
        return this.SC[type].status;
      } else {
        return false;
      }
    },
    rangecheck_status (type) {
      // if (grosscheck_status(type) || consistencycheck_status(type)) {
      //   return false;
      // }

      // if (this.CC[type]?.status) {
      //   return {
      //     status: this.CC[type].status,
      //     message: this.CC[type].message,
      //   };
      // } else
      // console.log('rangecheck_status', type, this.RC[type])
      if (this.RC[type]?.status) {
        return this.RC[type].status;
      } else {
        return false;
      }
    },
    // AN
    consistencycheck_status (type) {
      // console.log('consistencycheck_status', type, this.CC)
      if (this.CC[type]?.status) {
        // console.log('consistencycheck_status', this.CC[type], this.CC[type].status)
        return this.CC[type].status;
      } else {
        return false;
      }
    },
    form_status (type, qc_type=null) {
      if (qc_type == null) {
        if (this.CC[type]?.status) {
          return { status: this.CC[type].status, message: this.CC[type].message};
        }
        else if (this.RC[type]?.status) {
          return { status: this.RC[type].status, message: this.RC[type].message};
        }
        else if (this.SC[type]?.status) {
          return { status: this.SC[type].status, message: this.SC[type].message };
        }
        else {
          return { status: false, message: ""};
        }
      }
      else if (qc_type == 'rangecheck'){
        if (this.RC[type]?.status) {
          return { status: this.RC[type].status, message: this.RC[type].message};
        }
      }
      else if (qc_type == 'stepcheck'){
        if (this.SC[type]?.status) {
          return { status: this.SC[type].status, message: this.SC[type].message};
        }
      }
      else if (qc_type == 'consistencycheck'){
        if (this.CC[type]?.status) {
          return { status: this.CC[type].status, message: this.CC[type].message };
        }
      }
      
      return { status: false, message: ""};

    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    formatInteger(event) {
      switch (event.key) {
        case "ArrowUp":
          event.preventDefault();
          break;
        case "ArrowDown":
          event.preventDefault();
          break;
        case "e":
        case ".":
        case ",":
          event.preventDefault();
          break;
      }
    },
    formatPersepuluhan(event) {
      if (!/^\d+\.?\d?$/.test(event.target.value)) {
        const rounded = Math.round(event.target.value * 10) / 10;
        if (rounded) event.target.value = rounded;
      }
      return event;
    },
    checkValidate: function (event, type) {
      let value = event.target.value;
      // console.log('checkValidate', type, value)
      // GROSS CHECK
      let gc = false; // false -> tidak lulus gross check
      switch (type) {
        case "wind_speed_ff":
          gc = GC_WIND_SPEED_FF(value);
          break;
        case "visibility_vv":
          gc = GC_VISIBILITY_VV(value);
          break;
        case "rainfall_last_mm":
          gc = GC_RAINFALL_LAST_MM(value);
          break;
        case "cloud_low_base_1":
          gc = GC_CLOUD_LOW_BASE(value);
          break;
        case "cloud_low_base_2":
          gc = GC_CLOUD_LOW_BASE(value);
          break;
        case "cloud_low_base_3":
          gc = GC_CLOUD_LOW_BASE(value);
          break;
        case "cloud_med_base_1":
          gc = GC_CLOUD_MED_BASE(value);
          break;
        case "cloud_med_base_2":
          gc = GC_CLOUD_MED_BASE(value);
          break;
        case "cloud_high_base_1":
          gc = GC_CLOUD_HIGH_BASE(value);
          break;
        case "cloud_high_base_2":
          gc = GC_CLOUD_HIGH_BASE(value);
          break;
        case "cloud_low_peak_1":
          gc = GC_CLOUD_LOW_PEAK(value);
          break;
        case "cloud_low_peak_2":
          gc = GC_CLOUD_LOW_PEAK(value);
          break;
        case "evaporation_24hours_mm_eee":
          gc = GC_EVAPORATION_24HOURS_MM_EEE(value);
          break;
        case "solar_rad_24h_jcm2_f24":
          gc = GC_SOLAR_RAD_24H_JCM2_F24(value);
          break;
        case "sunshine_h_sss":
          gc = GC_SUNSHINE_H_SSS(value);
          break;
        case "cloud_vertical_vis":
          gc = GC_CLOUD_VERTICAL_VIS(value);
          break;
        case "temp_drybulb_c_tttttt":
          gc = GC_TEMP_DRYBULB_C_TTTTTT(value);
          break;
        case "temp_wetbulb_c":
          gc = GC_TEMP_WETBULB_C(value);
          break;
        case "pressure_qff_mb_derived":
          gc = GC_PRESSURE_QFF_MB_DERIVED(value);
          break;
        case "pressure_qfe_mb_derived":
          gc = GC_PRESSURE_QFE_MB_DERIVED(value);
          break;
        case "temp_max_c_txtxtx": //AN
          gc = GC_TEMP_MAX_C_TXTXTX(value);
          break;
        case "temp_min_c_tntntn": //AN
          gc = GC_TEMP_MIN_C_TNTNTN(value);
          break;
        default:
          gc = true;
          break;
      }

      //GROSS CHECK
      this.GC[type] = { status: gc, message: "" };

      // RANGE CHECK
      let raw = { status: true, message: "" };
      raw = this.RC_helper.check(value, type);

      // CONSISTENCY CHECK
      let cc = this.CC_SINOP(type);
      // if(type == 'awan_rendah.sudut[0]' || type == 'awan_rendah.sudut[1]') {
      //   let cc = this.consistency_check_tooltip(type)
      //   this.CC[type] = {
      //     status: !cc.status ? (!gc ? false : true) : false,
      //     message: cc.message,
      //   };
      // }

      // SET VALIDATION STATUS
      this.RC[type] = {
        status: !raw.status ? (!gc ? false : true) : false,//gc && !raw.status,
        message: raw.message,
      };
      // console.log('set Range Check', type, raw, this.RC, gc)

      // STEP CHECK   
      if (type == 'pressure_qff_mb_derived' || type == 'pressure_qfe_mb_derived' || type == 'temp_drybulb_c_tttttt' || type == 'relative_humidity_pc' || type == 'temp_wetbulb_c'
      ) {
        let rawSc = { status: true, message: "" };
        rawSc = this.SC_helper.check(value, type);
        this.SC[type] = {
          status: gc && !rawSc.status,
          message: rawSc.message,
        };
        // console.log('set Step Check', type, this.SC)
      }
    },
    check_consistency(checks = []) {
      let IR_exclude = null
      if (this.t_operating_hours != 24 && this.jam === "00:00") {
        IR_exclude = this.input_data.rainfall_last_mm == 0 ? 3 : 2
      }
      const PARAMS = {
        dd: this.input_data.wind_dir_deg_dd,
        ff: this.input_data.wind_speed_ff,
        vv: this.input_data.visibility_vv,
        ww: this.input_data.present_weather_ww,
        w1: this.input_data.past_weather_w1,
        w2: this.input_data.past_weather_w2,
        tt: this.input_data.temp_drybulb_c_tttttt,
        td: this.temp_dewpoint_c_tdtdtd,
        "tt-td": this.input_data.temp_drybulb_c_tttttt - this.temp_dewpoint_c_tdtdtd,
        n: this.input_data.cloud_cover_oktas_m,
        ir: IR_exclude ? IR_exclude : this.rainfall_indicator_ir,
        cl: this.awan_rendah.CL,
        cm: this.awan_menengah.CM,
        ch: this.awan_tinggi.CH,
        tx: this.input_data.temp_max_c_txtxtx,
        tn: this.input_data.temp_min_c_tntntn,
      };

      let valid = checks.map((check) => {
        // check: { param, not, op, values }
        let res = check.values.map((val) => {
          let param = PARAMS[check.param];
          if (param === undefined) param = check.param;
          if (param === "") param = null;
          return check.op(param, val);
        });
        res = res.some((x) => x);
        return check?.not ? !res : res;
      });
      let flag = false;
      for (let i = 0; i < valid.length; i++) {
        if (!valid[i]) {
          flag = true;
          break;
        }
      }
      return flag;
    },
    CC_SINOP(type) {
      // console.log('CC_SINOP ', type)
      // let isConsistencyW1W2a = false
      let cc = true;
      let cc_message = "";

      // function2 buat perbandingan
      const eq = (a, b) => a == b;
      const neq = (a, b) => a != b;
      const lt = (a, b) => a < b;
      const gt = (a, b) => a > b;
      const lte = (a, b) => a <= b;
      const gte = (a, b) => a >= b;

      if (this.disabled?.[type]) {
        this.CC[type].status = !cc;
        this.CC[type].message = cc_message;
        return cc;
      }

      switch (type) {
        case "wind_dir_deg_dd":
          if (this.input_data.wind_speed_ff !== "" && this.input_data.wind_speed_ff !== null) {
            cc = this.check_consistency([
              { param: "dd", op: eq, values: [0] },
              { param: "ff", op: neq, values: [0] },
            ]);
            if (!cc) {
              cc_message = "dd = 00; ff ≠ 00";
            }
          }
          break;
        case "wind_speed_ff":
          cc = this.check_consistency([
            { param: "dd", op: neq, values: [0] },
            { param: "ff", op: eq, values: [0] },
          ]);
          if (!cc) {
            cc_message = "dd ≠ 00; ff = 00";
          }
          break;
        // visibility_vv tidak ada
        case "present_weather_ww":
          // WW = 42-49; TT-TD>0.5◦C
          cc = this.check_consistency([
            { param: "ww", op: eq, values: [42, 43, 44, 45, 46, 47, 48, 49] },
            { param: "tt-td", op: gt, values: [0.5] },
          ]);
          if (!cc) {
            cc_message = "WW = 42-49; TT-TD>0.5◦C";
            break;
          }

          // WW = 10-12; TT-TD>1◦C
          cc = this.check_consistency([
            { param: "ww", op: eq, values: [10, 11, 12] },
            { param: "tt-td", op: gt, values: [1] },
          ]);
          if (!cc) {
            cc_message = "WW = 10-12; TT-TD>1◦C";
            break;
          }

          // ww = 00-16, 18-29,40,42,44,46 or 48; N = 9
          cc = this.check_consistency([
            { param: "ww", op: eq, values: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 40, 42, 44, 46, 48] },
            { param: "n", op: eq, values: ["9"] },
          ]);
          if (!cc) {
            cc_message = "ww = 00-16, 18-29,40,42,44,46 or 48; N = 9";
            break;
          }

          // ww = 14-17, 19, 50-75 or 77-99; N = 0
          cc = this.check_consistency([
            {
              param: "ww",
              op: eq,
              values: [14, 15, 16, 17, 19, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99],
            },
            { param: "n", op: eq, values: ["0"] },
          ]);
          if (!cc) {
            cc_message = "ww = 14-17, 19, 50-75 or 77-99; N = 0";
            break;
          }

          // ww = 43,45,47 or 49; N ≠ 9
          cc = this.check_consistency([
            { param: "ww", op: eq, values: [43, 45, 47, 49] },
            { param: "n", op: neq, values: ["9"] },
          ]);
          if (!cc) {
            cc_message = "ww = 43,45,47 or 49; N ≠ 9";
            break;
          }

          // ww = 20-27,51,53,55,61,63,65 ; RR=0
          cc = this.check_consistency([
            { param: "ww", op: eq, values: [20, 21, 22, 23, 24, 25, 26, 27, 51, 53, 55, 61, 63, 65] },
            { param: "ir", op: eq, values: [3] },
          ]);
          if (!cc) {
            cc_message = "ww = 20-27,51,53,55,61,63,65 ; RR=0";
            break;
          }

          break;
        case "past_weather_w1":
        case "past_weather_w2":
          // AN - This logic only applies in main/inter hour, the 'if' statement is optional as ir=4 in those hour
          // WMO 306
          // 1. Jam utama melihat past weather 6 jam lalu
          // 2. Jam intermediate melihat past weather 3 jam lalu
          // Hal tersebut dicover ir, kecuali jam 00
          // untuk sta non 24h harusnya logic berikut otomatis menyesuaikan

          // Disable QC
          if(this.input_data.past_weather_w1 == '/' || this.input_data.past_weather_w2 == '/') {
            cc = true;
            cc_message = "";
            break;
          }

          if ((this.jam === "00:00" || this.jam === "03:00" || this.jam === "06:00" || this.jam === "09:00" 
              || this.jam === "12:00" || this.jam === "15:00" || this.jam === "18:00" || this.jam === "21:00")
            && this.t_operating_hours >= 18) {

                // Terdapat Rule khusus saat jam 00, tidak memakai ch 24 jam lalu tetapi 6 jam lalu sehingga tidak bisa mengandalkan ir=1

                const rainy_past_weather = (
                  (this.input_data.past_weather_w1 >= 5 && this.input_data.past_weather_w1 <= 8) || 
                  (this.input_data.past_weather_w2 >= 5 && this.input_data.past_weather_w2 <= 8)
                )
                const non_rainy_past_weather = (
                    (this.input_data.past_weather_w1 >= 0 && this.input_data.past_weather_w1 <= 4) || this.input_data.past_weather_w1 == 9
                  ) && (
                    (this.input_data.past_weather_w2 >= 0 && this.input_data.past_weather_w2 <= 4) || this.input_data.past_weather_w2 == 9
                  )
                const ww_recent_rain = this.input_data.present_weather_ww >= 80 
                  || [20, 21, 22, 23, 24, 25, 26, 27, 51, 53, 55, 61, 63, 65].indexOf(this.input_data.present_weather_ww) >= 0

                if(rainy_past_weather && this.rainfall_indicator_ir == 3) {
                  cc = false;
                  cc_message = "W1 or W2 = 5-8; RRR=0 (ir = 3)";
                  break;
                } else if(non_rainy_past_weather && !ww_recent_rain && (
                    (this.rainfall_indicator_ir == 0 || (this.rainfall_indicator_ir == 1 && this.jam !== "00:00") || this.rainfall_indicator_ir == 2) || 
                    (this.jam === "00:00" && this.rainfall_indicator_ir == 1 && this.rainfall_6h_rrr > 0)
                  )) {
                  cc = false;
                  cc_message = "W1 and W2 = 0-4 or 9 ; RRR>0 (ir = 0, 1 or 2)";
                  break;
                }
          }

          if (this.input_data.past_weather_w1 < this.input_data.past_weather_w2) {
            cc = false;
            cc_message = "W1 < W2";
          }

          break;
        case "cloud_cover_oktas_m":
          // cc = this.check_consistency([
          //   { param: "n", op: eq, values: [0] },
          //   { param: "nh", not: true, op: eq, values: [0] },
          // ]);
          // if (!cc) {
          //   cc_message = "N = 0; Nh≠0 or Nh missing";
          //   break;
          // }

          // cc = this.check_consistency([
          //   { param: "n", op: eq, values: [9] },
          //   { param: "nh", not: true, op: eq, values: [9] },
          // ]);
          // if (!cc) {
          //   cc_message = "N = 9; Nh≠9";
          //   break;
          // }

          cc = this.check_consistency([
            { param: "n", op: eq, values: [9] },
            { param: "cl", not: true, op: eq, values: [null, "-", "/"] },
          ]);
          if (!cc) {
            cc_message = "N = 9 ; Cl, Cm, and/or Ch reported";
            break;
          }
          cc = this.check_consistency([
            { param: "n", op: eq, values: [9] },
            { param: "cm", not: true, op: eq, values: [null, "-", "/"] },
          ]);
          if (!cc) {
            cc_message = "N = 9 ; Cl, Cm, and/or Ch reported";
            break;
          }
          cc = this.check_consistency([
            { param: "n", op: eq, values: [9] },
            { param: "ch", not: true, op: eq, values: [null, "-", "/"] },
          ]);
          if (!cc) {
            cc_message = "N = 9 ; Cl, Cm, and/or Ch reported";
            break;
          }

          cc = this.check_consistency([
            { param: "n", op: lt, values: [8] },
            { param: "ch", op: eq, values: [7] },
          ]);
          if (!cc) {
            cc_message = "N<8; Ch =7";
            break;
          }

          cc = this.check_consistency([
            { param: "n", op: eq, values: [9] },
            { param: "vv", op: gte, values: [1] },
          ]);
          if (!cc) {
            cc_message = "N = 9; VV ≥1 km";
            break;
          }

          // AN : add null in cl
          cc = this.check_consistency([
            { param: "n", op: eq, values: [0] },
            { param: "cl", not: true, op: eq, values: [null, 0] }, 
          ]);
          if (!cc) {
            cc_message = "N = 0 ; Cl, Cm, and/or Ch reported (≠0)";
            break;
          }
          
          // AN : add null in cm
          cc = this.check_consistency([
            { param: "n", op: eq, values: [0] },
            { param: "cm", not: true, op: eq, values: [null, 0] },
          ]);
          if (!cc) {
            cc_message = "N = 0 ; Cl, Cm, and/or Ch reported (≠0)";
            break;
          }
          
          // AN : add null in ch
          cc = this.check_consistency([
            { param: "n", op: eq, values: [0] },
            { param: "ch", not: true, op: eq, values: [null, 0] },
          ]);
          if (!cc) {
            cc_message = "N = 0 ; Cl, Cm, and/or Ch reported (≠0)";
            break;
          }

          cc = this.check_consistency([
            { param: "n", op: eq, values: [8] },
            { param: "cl", op: eq, values: [0] },
            { param: "cm", op: eq, values: [0] },
            { param: "ch", op: eq, values: [0, 1, 4, 5, 6, 8, 9] },
          ]);
          if (!cc) {
            cc_message = "N=8 ; Cl = Ch = 0 ; Ch = 0,1,4,5,6,8,9";
            break;
          }

          // cc = this.check_consistency([
          //   { param: "n", op: lt, values: [this.awan_tinggi.NCH] },
          // ]);
          // if (!cc) {
          //   cc_message = "N<Nh";
          //   break;
          // }

          break;
        case "temp_dewpoint_c_tdtdtd":
          cc = this.check_consistency([{ param: "tt", op: lt, values: [this.temp_dewpoint_c_tdtdtd] }]);
          if (!cc) {
            cc_message = "TT < Td";
            break;
          }

          break;
        case "temp_drybulb_c_tttttt":
          cc = this.check_consistency([{ param: "tt", op: lt, values: [this.temp_dewpoint_c_tdtdtd] }]);
          if (!cc) {
            cc_message = "TT < Td";
            break;
          }

          if (this.input_data.temp_drybulb_c_tttttt < this.input_data.temp_wetbulb_c) {
            cc = false
            cc_message = "Suhu Bola Kering >= Suhu Bola Basah"
            break;
          }

          break;
        case "temp_wetbulb_c":
          if (this.input_data.temp_drybulb_c_tttttt < this.input_data.temp_wetbulb_c) {
            cc = false
            cc_message = "Suhu Bola Basah < Suhu Bola Basah"
            break;
          }
          break;
        case "cloud_low_type_cl":
          cc = this.check_consistency([
            { param: "cl", op: eq, values: [null, "-"] },
            { param: "n", not: true, op: eq, values: [9] },
          ]);
          if (!cc) {
            cc_message = "Cl missing; N ≠9";
            break;
          }

          cc = this.check_consistency([
            { param: "cl", op: eq, values: [0] },
            { param: "cm", op: eq, values: ["/", "-"] },
          ]);
          if (!cc) {
            cc_message = "Cl = 0; Cm missing (obscure)";
            break;
          }

          cc = this.check_consistency([
            { param: "cl", op: eq, values: [0] },
            { param: "cm", op: eq, values: [0] },
            { param: "ch", op: eq, values: [null, "-"] },
          ]);
          if (!cc) {
            cc_message = "Cl = 0 ; Cm =0 ; Ch missing";
            break;
          }

          cc = this.check_consistency([
            { param: "cl", op: eq, values: [0] },
            { param: "cm", op: eq, values: [0] },
            { param: "ch", op: eq, values: [null, "-"] },
          ]);
          if (!cc) {
            cc_message = "Cl = 0 ; Cm =0 ; Ch missing";
            break;
          }

          break;
        // cloud_med_type_cm tidak ada
        case "cloud_high_type_ch":
          cc = this.check_consistency([
            { param: "ch", op: eq, values: ["/", "-"] },
            { param: "n", op: lt, values: [4] },
          ]);
          if (!cc) {
            cc_message = "Ch obscure (missing); N < 4 ; Nh reported";
            break;
          }

          break;
        case "temp_max_c_txtxtx": {
          // // cc = this.check_consistency([
          // //   { param: "tx", op: lt, values: [this.input_data.temp_min_c_tntntn] },
          // // ]);
          // // if (!cc) {
          // //   cc_message = "Tx < Tn";
          // //   break;
          // // }

          // cc = this.check_consistency([{ param: "tx", op: gt, values: [this.input_data.temp_drybulb_c_tttttt + 3] }]);
          // if (!cc) {
          //   cc_message = "Tx > Max (TT) + 3";
          //   break;
          // }

          // Masih mencari data rainfallsinop
          if(this.showLoading) {
            break;
          }

          let maximum_drybulb = null;
          if(this.temp_drybulb_c_tttttt_max 
            && this.temp_drybulb_c_tttttt_max != 9999
            || this.temp_drybulb_c_tttttt_max == 0){
            maximum_drybulb = Math.max(this.temp_drybulb_c_tttttt_max, 
              this.input_data.temp_drybulb_c_tttttt);
          } else {
            maximum_drybulb = this.input_data.temp_drybulb_c_tttttt;
          }
     
          // console.log("maximum_drybulb", maximum_drybulb, this.temp_drybulb_c_tttttt_max);
            
          if (this.input_data.temp_max_c_txtxtx && 
              maximum_drybulb &&
              this.input_data.temp_max_c_txtxtx < maximum_drybulb &&
              maximum_drybulb >= 0 &&
              this.input_data.temp_max_c_txtxtx != 9999 &&
              maximum_drybulb != 9999) {
                cc = false;
                cc_message = "Tx < TT; TT=" + maximum_drybulb;
                break;
          }

          if (+this.t_operating_hours == 24) {
            if (this.input_data.temp_max_c_txtxtx && 
              maximum_drybulb && 
              this.input_data.temp_max_c_txtxtx > maximum_drybulb + 3 &&
              maximum_drybulb >= 0 &&
              this.input_data.temp_max_c_txtxtx != 9999 &&
              maximum_drybulb != 9999) {
                cc = false;
                cc_message = "Tx < Max(TT) + 3; Max(TT)=" + maximum_drybulb;
                break;
            }
          }

         let minimum_drybulb = null;
          if(this.temp_min_c_tntntn_00 
            && this.temp_min_c_tntntn_00 != 9999
            || this.temp_min_c_tntntn_00 == 0){
            minimum_drybulb = Math.min(this.temp_min_c_tntntn_00, 
              this.input_data.temp_drybulb_c_tttttt);
          } else {
            minimum_drybulb = this.input_data.temp_drybulb_c_tttttt;
          }
          
          // console.log("this.temp_min_c_tntntn_00", minimum_drybulb, this.temp_min_c_tntntn_00);
          if (this.input_data.temp_max_c_txtxtx && 
            minimum_drybulb && 
            minimum_drybulb >= 0 && 
            minimum_drybulb > this.input_data.temp_max_c_txtxtx &&
            this.input_data.temp_max_c_txtxtx != 9999 &&
            minimum_drybulb != 9999) {
              cc = false;
              cc_message = "Tx < Tn; Tn=" + minimum_drybulb;
              break;
           }
          break;
        }
        case "temp_min_c_tntntn": {
          //   // cc = this.check_consistency([
          //   //   { param: "tn", op: gt, values: [this.input_data.temp_max_c_txtxtx] },
          //   // ]);
          //   // if (!cc) {
          //   //   cc_message = "Tn > Tx";
          //   //   break;
          //   // }

          // cc = this.check_consistency([{ param: "tn", op: lt, values: [this.input_data.temp_drybulb_c_tttttt - 3] }]);
          // if (!cc) {
          //   cc_message = "Tn < Min (TT) - 3";
          //   break;
          // }

          // Masih mencari data rainfallsinop
          if(this.showLoading) {
            break;
          }

          let minimum_drybulb = null;
          if(this.temp_drybulb_c_tttttt_min 
            && this.temp_drybulb_c_tttttt_min != 9999
            || this.temp_drybulb_c_tttttt_min == 0){
            minimum_drybulb = Math.min(this.temp_drybulb_c_tttttt_min, 
              this.input_data.temp_drybulb_c_tttttt);
          } else {
            minimum_drybulb = this.input_data.temp_drybulb_c_tttttt;
          }
     
          // console.log("minimum_drybulb", minimum_drybulb, this.temp_drybulb_c_tttttt_min);
            
          if (this.input_data.temp_min_c_tntntn && 
              minimum_drybulb &&
              this.input_data.temp_min_c_tntntn > minimum_drybulb &&
              minimum_drybulb >= 0 &&
              this.input_data.temp_min_c_tntntn != 9999 &&
              minimum_drybulb != 9999) {
                cc = false;
                cc_message = "Tn > TT; TT=" + minimum_drybulb;
                break;
          }

          if (+this.t_operating_hours == 24) {
            if (this.input_data.temp_min_c_tntntn && 
              minimum_drybulb && 
              this.input_data.temp_min_c_tntntn < minimum_drybulb - 3 &&
              minimum_drybulb >= 0 &&
              this.input_data.temp_min_c_tntntn != 9999 &&
              minimum_drybulb != 9999) {
                cc = false;
                cc_message = "Tn > Min(TT) - 3; Min(TT)=" + minimum_drybulb;
                break;
            }
          }

          let maximum_drybulb = null;
          if(this.temp_max_c_txtxtx_12 
            && this.temp_max_c_txtxtx_12 != 9999
            || this.temp_max_c_txtxtx_12 == 0){
            maximum_drybulb = Math.max(this.temp_max_c_txtxtx_12, 
              this.input_data.temp_drybulb_c_tttttt);
          } else {
            maximum_drybulb = this.input_data.temp_drybulb_c_tttttt;
          }
          
          // console.log("this.temp_max_c_txtxtx_12", maximum_drybulb, this.temp_max_c_txtxtx_12);
          if (this.input_data.temp_min_c_tntntn && 
            maximum_drybulb && 
            maximum_drybulb >= 0 && 
            maximum_drybulb < this.input_data.temp_min_c_tntntn &&
            this.input_data.temp_min_c_tntntn != 9999 &&
            maximum_drybulb != 9999) {
              cc = false;
              cc_message = "Tn > Tx; Tx=" + maximum_drybulb;
              break;
           }

          break;
        }
        case "cloud_elevation_1_angle_ec":
          if (this.awan_rendah.tinggi_puncak[0]
            && (this.awan_rendah.sudut[0] == "-" 
              || !this.awan_rendah.sudut[0]
              || this.awan_rendah.sudut[0] == "0")) {
            cc = false
            cc_message = "Sudut awan ≠ [blank, 0]"
          } else if (!this.awan_rendah.tinggi_puncak[0]
            && !(this.awan_rendah.sudut[0] == "-" 
              || !this.awan_rendah.sudut[0]
              || this.awan_rendah.sudut[0] == "0")) {
            cc = false
            cc_message = "Sudut awan ≠ [1, 2, 3, 4, 5, 6, 7, 8, 9]"
          }
          break;
        case "cloud_elevation_2_angle_ec":
          if (this.awan_rendah.tinggi_puncak[1]
            && (this.awan_rendah.sudut[1] == "-" 
              || !this.awan_rendah.sudut[1]
              || this.awan_rendah.sudut[1] == "0")) {
            cc = false
            cc_message = "Sudut awan ≠ [blank, 0]"
          } else if (!this.awan_rendah.tinggi_puncak[1]
            && !(this.awan_rendah.sudut[1] == "-" 
              || !this.awan_rendah.sudut[1]
              || this.awan_rendah.sudut[1] == "0")) {
            cc = false
            cc_message = "Sudut awan ≠ [1, 2, 3, 4, 5, 6, 7, 8, 9]"
          }
          break;
        default:
          return false;
          break;
      }
      // console.log('cc_sinop - ', type, this.CC)
      this.CC[type].status = !cc;
      this.CC[type].message = cc_message;
      return cc;
    },
    ASelectValidate(item) {
      const valid = this.validateState(item);
      // console.log(valid)
      if (valid === null) {
        return {
          "is-valid": false,
          "is-invalid": false,
        };
      } else if (valid) {
        return {
          "is-valid": true,
          "is-invalid": false,
        };
      } else {
        return {
          "is-valid": false,
          "is-invalid": true,
        };
      }
    },
    //validate tooltips GC
    tooltipMessage(attr) {
      return GC_SINOP(attr).message;
    },
    validateTooltipStateInput_data(name) {
      // const { $error } = this.$v.input_data[name];
      // return $error && !this.CC[name].status;
      return false;
    },
    validateState(item) {
      // console.log('vs', $v?.input_data)
      const { $dirty, $error } = item;
      return $dirty ? !$error : null;
    },
    resetForm() {
      // console.log("reset form");
      this.is_data_ready_to_filled = false;
      this.input_data.cloud_cover_oktas_m = "";
      this.awan_rendah.CL = "";
      this.awan_menengah.CM = "";
      this.awan_tinggi.CH = "";
      this.awan_rendah.NCL = "";
      this.awan_menengah.NCM = "";
      this.awan_tinggi.NCH = "";
      this.cloud_layer_1_type_c = "";
      this.cloud_layer_1_height_m_hshs = "";
      this.cloud_layer_1_amt_oktas_ns = "";
      this.cloud_layer_2_type_c = "";
      this.cloud_layer_2_height_m_hshs = "";
      this.cloud_layer_2_amt_oktas_ns = "";
      this.cloud_layer_3_type_c = "";
      this.cloud_layer_3_height_m_hshs = "";
      this.cloud_layer_3_amt_oktas_ns = "";
      this.cloud_layer_4_type_c = "";
      this.cloud_layer_4_height_m_hshs = "";
      this.cloud_layer_4_amt_oktas_ns = "";
      this.input_data.evaporation_24hours_mm_eee = "";
      this.input_data.evaporation_eq_indicator_ie = "";
      this.input_data.land_cond = "";
      this.input_data.land_note = "";
      this.input_data.past_weather_w1 = "";
      this.input_data.past_weather_w2 = "";
      this.input_data.present_weather_ww = "";
      this.input_data.pressure_reading_mb = "";
      this.input_data.pressure_temp_c = "";
      this.rainfall_24h_rrrr = "";
      this.rainfall_6h_rrr = "";
      this.input_data.rainfall_last_mm = "";
      this.input_data.solar_rad_24h_jcm2_f24 = "";
      this.input_data.sunshine_h_sss = "";
      this.temp_dewpoint_c_tdtdtd = "";
      this.input_data.temp_drybulb_c_tttttt = "";
      this.input_data.temp_max_c_txtxtx = "";
      this.input_data.temp_min_c_tntntn = "";
      this.input_data.temp_wetbulb_c = "";
      this.input_data.visibility_vv = "";
      this.input_data.weather_indicator_ix = "";
      this.input_data.wind_dir_deg_dd = "";
      this.input_data.wind_indicator_iw = "";
      this.input_data.wind_speed_ff = "";
      this.input_data.cloud_vertical_vis = "";
      this.relative_humidity_pc = "";
      this.input_data.pressure_qff_mb_derived = "";
      this.input_data.pressure_qfe_mb_derived = "";
      this.pressure_tend_3h_mb_ppp = "";
      this.pressure_tend_24h_mb_ppp = "";
      this.awan_rendah.jenis[0] = "";
      this.awan_rendah.jenis[1] = "";
      this.awan_rendah.jenis[2] = "";
      this.awan_menengah.jenis[0] = "";
      this.awan_menengah.jenis[1] = "";
      this.awan_tinggi.jenis[0] = "";
      this.awan_tinggi.jenis[1] = "";
      this.awan_rendah.tinggi_dasar[0] = "";
      this.awan_rendah.tinggi_dasar[1] = "";
      this.awan_rendah.tinggi_dasar[2] = "";
      this.awan_menengah.tinggi_dasar[0] = "";
      this.awan_menengah.tinggi_dasar[1] = "";
      this.awan_tinggi.tinggi_dasar[0] = "";
      this.awan_tinggi.tinggi_dasar[1] = "";
      this.awan_rendah.tinggi_puncak[0] = "";
      this.awan_rendah.tinggi_puncak[1] = "";
      this.awan_rendah.jumlah[0] = "";
      this.awan_rendah.jumlah[1] = "";
      this.awan_rendah.jumlah[2] = "";
      this.awan_menengah.jumlah[0] = "";
      this.awan_menengah.jumlah[1] = "";
      this.awan_tinggi.jumlah[0] = "";
      this.awan_tinggi.jumlah[1] = "";
      this.awan_rendah.arah[0] = "";
      this.awan_rendah.arah[1] = "";
      this.awan_rendah.arah[2] = "";
      this.awan_menengah.arah[0] = "";
      this.awan_menengah.arah[1] = "";
      this.awan_tinggi.arah[0] = "";
      this.awan_tinggi.arah[1] = "";
      this.awan_rendah.sudut[0] = "";
      this.awan_rendah.sudut[1] = "";
      this.awan_rendah.da[0] = "";
      this.awan_rendah.da[1] = "";
      this.sandi = "";
      this.sandi_with_header = ""

      this.last_qc_histories = null;
      this.last_sc_qc_histories = null;

      this.awan_rendah.cloud_2 = false;
      this.awan_rendah.cloud_3 = false;
      this.awan_menengah.cloud_2 = false;
      this.awan_tinggi.cloud_2 = false;

      this.rainfall_pressure = null;

      this.pressure_tend_3h_mb_www = null
      this.pressure_tend_2h_mb_www = null
      this.pressure_tend_1h_mb_www = null

      this.$v.$reset();
      this.SC = {};
      this.RC = {};
      this.CC = {
        wind_dir_deg_dd: { status: false, message: "" },
        wind_speed_ff: { status: false, message: "" },
        visibility_vv: { status: false, message: "" },
        present_weather_ww: { status: false, message: "" },
        past_weather_w1: { status: false, message: "" },
        past_weather_w2: { status: false, message: "" },
        cloud_cover_oktas_m: { status: false, message: "" },
        cloud_low_type_cl: { status: false, message: "" },
        cloud_low_cover_oktas: { status: false, message: "" },
        cloud_med_type_cm: { status: false, message: "" },
        cloud_med_cover_oktas: { status: false, message: "" },
        cloud_high_type_ch: { status: false, message: "" },
        cloud_high_cover_oktas: { status: false, message: "" },
        temp_dewpoint_c_tdtdtd: { status: false, message: "" },
        temp_drybulb_c_tttttt: { status: false, message: "" },
        temp_max_c_txtxtx: { status: false, message: "" },
        temp_min_c_tntntn: { status: false, message: "" },
        cloud_elevation_1_angle_ec: {status: false, message: ""},
        cloud_elevation_2_angle_ec: {status: false, message: ""},
        temp_wetbulb_c: { status: false, message: "" },
      };

      this.notif_params = {
        region_id: null,
        path_oinput: null
      }
      this.fetching = false
    },
    resetCloud() {
      this.awan_rendah.CL = "";
      this.awan_menengah.CM = "";
      this.awan_tinggi.CH = "";
      this.awan_rendah.NCL = "";
      this.awan_menengah.NCM = "";
      this.awan_tinggi.NCH = "";
      this.cloud_layer_1_type_c = "";
      this.cloud_layer_1_height_m_hshs = "";
      this.cloud_layer_1_amt_oktas_ns = "";
      this.cloud_layer_2_type_c = "";
      this.cloud_layer_2_height_m_hshs = "";
      this.cloud_layer_2_amt_oktas_ns = "";
      this.cloud_layer_3_type_c = "";
      this.cloud_layer_3_height_m_hshs = "";
      this.cloud_layer_3_amt_oktas_ns = "";
      this.cloud_layer_4_type_c = "";
      this.cloud_layer_4_height_m_hshs = "";
      this.cloud_layer_4_amt_oktas_ns = "";
      
      this.awan_rendah.jenis[0] = "";
      this.awan_rendah.jenis[1] = "";
      this.awan_rendah.jenis[2] = "";
      this.awan_menengah.jenis[0] = "";
      this.awan_menengah.jenis[1] = "";
      this.awan_tinggi.jenis[0] = "";
      this.awan_tinggi.jenis[1] = "";
      this.awan_rendah.tinggi_dasar[0] = "";
      this.awan_rendah.tinggi_dasar[1] = "";
      this.awan_rendah.tinggi_dasar[2] = "";
      this.awan_menengah.tinggi_dasar[0] = "";
      this.awan_menengah.tinggi_dasar[1] = "";
      this.awan_tinggi.tinggi_dasar[0] = "";
      this.awan_tinggi.tinggi_dasar[1] = "";
      this.awan_rendah.tinggi_puncak[0] = "";
      this.awan_rendah.tinggi_puncak[1] = "";
      this.awan_rendah.jumlah[0] = "";
      this.awan_rendah.jumlah[1] = "";
      this.awan_rendah.jumlah[2] = "";
      this.awan_menengah.jumlah[0] = "";
      this.awan_menengah.jumlah[1] = "";
      this.awan_tinggi.jumlah[0] = "";
      this.awan_tinggi.jumlah[1] = "";
      // this.awan_rendah.arah[0] = "";
      this.awan_rendah.arah[1] = "";
      this.awan_rendah.arah[2] = "";
      // this.awan_menengah.arah[0] = "";
      this.awan_menengah.arah[1] = "";
      // this.awan_tinggi.arah[0] = "";
      this.awan_tinggi.arah[1] = "";
      this.awan_rendah.sudut[0] = "";
      this.awan_rendah.sudut[1] = "";
      this.awan_rendah.da[0] = "";
      this.awan_rendah.da[1] = "";
      
      this.awan_rendah.cloud_2 = false;
      this.awan_rendah.cloud_3 = false;
      this.awan_menengah.cloud_2 = false;
      this.awan_tinggi.cloud_2 = false;

      // this.$v.$reset();
      // this.SC = {};
      // this.RC = {};
      // console.log(this.CC)
      this.CC = { ...this.CC,
        cloud_low_type_cl: { status: false, message: "" },
        cloud_low_cover_oktas: { status: false, message: "" },
        cloud_med_type_cm: { status: false, message: "" },
        cloud_med_cover_oktas: { status: false, message: "" },
        cloud_high_type_ch: { status: false, message: "" },
        cloud_high_cover_oktas: { status: false, message: "" },
        cloud_elevation_1_angle_ec: {status: false, message: ""},
        cloud_elevation_2_angle_ec: {status: false, message: ""},
      };

      this.$v.awan_rendah.$reset();
      this.$v.awan_menengah.$reset();
      this.$v.awan_tinggi.$reset();
    },
    async listStasiun() {
      this.showLoading = true;
      try {
        // const { data } = await metadata.getAllStasiunWithWMO();
        const { data } = await metadata.getAllStasiunWithWMOandMetaStation();
        this.t_station_opt = [
          {
            value: "",
            text: "Pilih Stasiun",
          },
        ];
        for (var i = 0; i < data.length; i++) {
          const data_station = {
            value: data[i].station_id,
            text: data[i].station_name,
            // path: data[i].path,
            path: data[i].path_station,
            wmoid: data[i].station_wmo_id,
          };

          this.t_station_opt.push(data_station);
        }
        this.t_station = "";

        // baca url query
        if (this.$route.query.station_id) {
          this.t_station = this.t_station_opt.find((el) => el.value == this.$route.query.station_id);
        }
        if (this.$route.query.tanggal) {
          this.tanggal = this.$route.query.tanggal;
        }
        if (this.$route.query.jam) {
          this.jam = this.$route.query.jam;
        }

        this.showLoading = false;
      } catch (e) {
        console.error(e);
        this.showLoading = false;
      }
    },
    async listObserver() {
      this.showLoading = true;
      this.t_observer_opt = await metadata.getObserverStation(this.t_station_id);
      this.showLoading = false;
    },
    popupData(data) {
      this.resetForm()
      this.input_data.cloud_cover_oktas_m = data.cloud_cover_oktas_m;
      this.awan_rendah.CL = data.cloud_low_type_cl;
      this.awan_menengah.CM = data.cloud_med_type_cm;
      this.awan_tinggi.CH = data.cloud_high_type_ch;
      this.awan_rendah.NCL = data.cloud_low_cover_oktas;
      this.awan_menengah.NCM = data.cloud_med_cover_oktas;
      this.awan_tinggi.NCH = data.cloud_high_cover_oktas;
      this.cloud_layer_1_type_c = data.cloud_layer_1_type_c;
      this.cloud_layer_1_height_m_hshs = data.cloud_layer_1_height_m_hshs;
      this.cloud_layer_1_amt_oktas_ns = data.cloud_layer_1_amt_oktas_ns;
      this.cloud_layer_2_type_c = data.cloud_layer_2_type_c;
      this.cloud_layer_2_height_m_hshs = data.cloud_layer_2_height_m_hshs;
      this.cloud_layer_2_amt_oktas_ns = data.cloud_layer_2_amt_oktas_ns;
      this.cloud_layer_3_type_c = data.cloud_layer_3_type_c;
      this.cloud_layer_3_height_m_hshs = data.cloud_layer_3_height_m_hshs;
      this.cloud_layer_3_amt_oktas_ns = data.cloud_layer_3_amt_oktas_ns;
      this.cloud_layer_4_type_c = data.cloud_layer_4_type_c;
      this.cloud_layer_4_height_m_hshs = data.cloud_layer_4_height_m_hshs;
      this.cloud_layer_4_amt_oktas_ns = data.cloud_layer_4_amt_oktas_ns;
      this.input_data.evaporation_24hours_mm_eee = data.evaporation_24hours_mm_eee;
      this.input_data.evaporation_eq_indicator_ie = data.evaporation_eq_indicator_ie;
      this.input_data.land_cond = data.land_cond;
      this.input_data.land_note = data.land_note;
      this.input_data.past_weather_w1 = data.past_weather_w1 != "/"? +data.past_weather_w1 : data.past_weather_w1;
      this.input_data.past_weather_w2 = data.past_weather_w2 != "/"? +data.past_weather_w2 : data.past_weather_w2;
      this.input_data.present_weather_ww = +data.present_weather_ww;
      this.input_data.pressure_reading_mb = data.pressure_reading_mb;
      this.input_data.pressure_temp_c = data.pressure_temp_c;
      // this.rainfall_24h_rrrr = data.rainfall_24h_rrrr;
      // this.rainfall_6h_rrr = data.rainfall_6h_rrr;
      this.input_data.rainfall_last_mm = data.rainfall_last_mm;
      this.input_data.solar_rad_24h_jcm2_f24 = data.solar_rad_24h_jcm2_f24;
      this.input_data.sunshine_h_sss = data.sunshine_h_sss;
      this.temp_dewpoint_c_tdtdtd = data.temp_dewpoint_c_tdtdtd;
      this.input_data.temp_drybulb_c_tttttt = data.temp_drybulb_c_tttttt;
      this.input_data.temp_max_c_txtxtx = data.temp_max_c_txtxtx;
      this.input_data.temp_min_c_tntntn = data.temp_min_c_tntntn;
      this.input_data.temp_wetbulb_c = data.temp_wetbulb_c;
      this.input_data.visibility_vv = data.visibility_vv;
      this.input_data.weather_indicator_ix = data.weather_indicator_ix;
      this.input_data.wind_dir_deg_dd = data.wind_dir_deg_dd;
      this.input_data.wind_indicator_iw = data.wind_indicator_iw;
      this.input_data.wind_speed_ff = data.wind_speed_ff;
      this.relative_humidity_pc = data.relative_humidity_pc;
      this.input_data.pressure_qff_mb_derived = data.pressure_qff_mb_derived;
      this.input_data.pressure_qfe_mb_derived = data.pressure_qfe_mb_derived;
      this.input_data.cloud_vertical_vis = GenHelper.getCleanValue(data.cloud_vertical_vis);
      // this.pressure_tend_3h_mb_ppp = data.pressure_tend_3h_mb_ppp;
      // this.pressure_tend_24h_mb_ppp = data.pressure_tend_24h_mb_ppp;
      this.awan_rendah.jenis[0] = data.cloud_low_type_1;
      this.awan_rendah.jenis[1] = data.cloud_low_type_2;
      this.awan_rendah.jenis[2] = data.cloud_low_type_3;
      this.awan_menengah.jenis[0] = data.cloud_med_type_1;
      this.awan_menengah.jenis[1] = data.cloud_med_type_2;
      this.awan_tinggi.jenis[0] = data.cloud_high_type_1;
      this.awan_tinggi.jenis[1] = data.cloud_high_type_2;
      this.awan_rendah.tinggi_dasar[0] = data.cloud_low_base_1;
      this.awan_rendah.tinggi_dasar[1] = data.cloud_low_base_2;
      this.awan_rendah.tinggi_dasar[2] = data.cloud_low_base_3;
      this.awan_menengah.tinggi_dasar[0] = data.cloud_med_base_1;
      this.awan_menengah.tinggi_dasar[1] = data.cloud_med_base_2;
      this.awan_tinggi.tinggi_dasar[0] = data.cloud_high_base_1;
      this.awan_tinggi.tinggi_dasar[1] = data.cloud_high_base_2;
      this.awan_rendah.tinggi_puncak[0] = data.cloud_low_peak_1;
      this.awan_rendah.tinggi_puncak[1] = data.cloud_low_peak_2;
      this.awan_rendah.jumlah[0] = data.cloud_low_cover_1;
      this.awan_rendah.jumlah[1] = data.cloud_low_cover_2;
      this.awan_rendah.jumlah[2] = data.cloud_low_cover_3;
      this.awan_menengah.jumlah[0] = data.cloud_med_cover_1;
      this.awan_menengah.jumlah[1] = data.cloud_med_cover_2;
      this.awan_tinggi.jumlah[0] = data.cloud_high_cover_1;
      this.awan_tinggi.jumlah[1] = data.cloud_high_cover_2;
      this.awan_rendah.arah[0] = GenHelper.parseIfOnlyNumber(data.cloud_low_dir_1);
      this.awan_rendah.arah[1] = GenHelper.parseIfOnlyNumber(data.cloud_low_dir_2);
      this.awan_rendah.arah[2] = GenHelper.parseIfOnlyNumber(data.cloud_low_dir_3);
      this.awan_menengah.arah[0] = GenHelper.parseIfOnlyNumber(data.cloud_med_dir_1);
      this.awan_menengah.arah[1] = GenHelper.parseIfOnlyNumber(data.cloud_med_dir_2);
      this.awan_tinggi.arah[0] = GenHelper.parseIfOnlyNumber(data.cloud_high_dir_1);
      this.awan_tinggi.arah[1] = GenHelper.parseIfOnlyNumber(data.cloud_high_dir_2);
      this.awan_rendah.sudut[0] = GenHelper.parseIfOnlyNumber(data.cloud_elevation_angle_ec_1);
      this.awan_rendah.sudut[1] = GenHelper.parseIfOnlyNumber(data.cloud_elevation_angle_ec_2);
      this.awan_rendah.da[0] = GenHelper.parseIfOnlyNumber(GenHelper.getCleanValue(data.cloud_low_dir_true_da_1));
      this.awan_rendah.da[1] = GenHelper.parseIfOnlyNumber(GenHelper.getCleanValue(data.cloud_low_dir_true_da_2));

      this.sandi = data.encoded_synop;
      this.sandi_with_header = data.encoded_synop_with_header ? data.encoded_synop_with_header : data.encoded_synop;

      let observer_valid = this.t_observer_opt.find((el) => el.id === data.observer_id);
      // console.log('observer_valid', observer_valid, data.observer_id, this.t_observer_opt)
      if (observer_valid) {
        this.t_observer = data.observer_id;
      } else {
        this.t_observer = null;
      }

      this.last_qc_histories = JSON.parse(data.qc_histories)?.after || null;
      this.last_sc_qc_histories = JSON.parse(data.qc_stepcheck_histories)?.after || null;

      // console.log(data);

      this.awan_rendah.cloud_2 = data.cloud_low_type_2 != null;
      this.awan_rendah.cloud_3 = data.cloud_low_type_3 != null;
      this.awan_menengah.cloud_2 = data.cloud_med_type_2 != null;
      this.awan_tinggi.cloud_2 = data.cloud_high_type_2 != null;

      this.is_data_ready_to_filled = true;
      this.getDataTambahan();
      this.showLoading = false;
      this.isEdit = true;
      this.donePreview = false;

      this.$v.$touch();
    },

    popupDigiData(data) {
      this.resetForm()
      
      if(!this.disabled.evaporation_24hours_mm_eee) {
        this.input_data.evaporation_24hours_mm_eee = data.evaporation_24h_mm;
      }
      if(!this.disabled.evaporation_eq_indicator_ie) {
        this.input_data.evaporation_eq_indicator_ie = 
          +data.evaporation_indicator_ie; 
      }
      // this.rainfall_24h_rrrr = data.rainfall_24h_rrrr;
      // this.rainfall_6h_rrr = data.rainfall_6h_rrr;
      if(!this.disabled.rainfall_last_mm) {
        this.input_data.rainfall_last_mm = data.precipitation_3h_mm;
      }
      if(!this.disabled.solar_rad_24h_jcm2_f24) {
        this.input_data.solar_rad_24h_jcm2_f24 = data.solar_radiation_24h_jcm2;
      }
      if(!this.disabled.sunshine_h_sss) {
        this.input_data.sunshine_h_sss = data.sunshine_duration_h;
      }
      this.temp_dewpoint_c_tdtdtd = data.dew_point_temp_c;
      this.input_data.temp_drybulb_c_tttttt = data.temperature_c;
      if(!this.disabled.temp_max_c_txtxtx) {
        this.input_data.temp_max_c_txtxtx = data.temperature_max_c;
      }
      if(!this.disabled.temp_min_c_tntntn) {
        this.input_data.temp_min_c_tntntn = data.temperature_min_c;
      }
      this.input_data.temp_wetbulb_c = data.wetbulb_c;
      this.input_data.wind_dir_deg_dd = data.wind_direction_deg;
      this.input_data.wind_indicator_iw = +data.wind_indicator_iw;
      this.input_data.wind_speed_ff = data.wind_speed_knot;
      this.relative_humidity_pc = data.relative_humidity_pc;

      if(!this.disabled.pressure_qff_mb_derived) {
        this.input_data.pressure_qff_mb_derived = data.qff_mb;
      }
      if(!this.disabled.pressure_qfe_mb_derived) {
        this.input_data.pressure_qfe_mb_derived = data.qfe_mb;
      }
      // this.pressure_tend_3h_mb_ppp = data.pressure_tend_3h_mb_ppp;
      // this.pressure_tend_24h_mb_ppp = data.pressure_tend_24h_mb_ppp;
      
      this.is_data_ready_to_filled = true;
      this.getDataTambahan(); // AN - add for qc
      this.showLoading = false;
      this.donePreview = false;

      // this.$v.$touch();
      this.$v.input_data.evaporation_24hours_mm_eee.$touch();
      this.$v.input_data.evaporation_eq_indicator_ie.$touch();
      this.$v.input_data.rainfall_last_mm.$touch();
      this.$v.input_data.solar_rad_24h_jcm2_f24.$touch();
      this.$v.input_data.sunshine_h_sss.$touch();
      // this.$v.temp_dewpoint_c_tdtdtd.$touch();
      this.$v.input_data.temp_drybulb_c_tttttt.$touch();
      this.$v.input_data.temp_max_c_txtxtx.$touch();
      this.$v.input_data.temp_min_c_tntntn.$touch();
      this.$v.input_data.temp_wetbulb_c.$touch();
      this.$v.input_data.wind_dir_deg_dd.$touch();
      this.$v.input_data.wind_indicator_iw.$touch();
      this.$v.input_data.wind_speed_ff.$touch();
      // this.$v.relative_humidity_pc.$touch();
      this.$v.input_data.pressure_qff_mb_derived.$touch();
      this.$v.input_data.pressure_qfe_mb_derived.$touch();
    },

    async cekExistData() {
      // console.log("cekExistData");
      const status = {exist : false, allowToEntry : false};
      this.showLoading = true;
      const get_url = Helper.getBaseUrl() + this.t_path + "/" + this.t_data_id;
      await axios
        .get(get_url, Helper.getConfig())
        .then((response) => {
          const { data } = response;
          status.exist = true;
          if (this.$route.query.qc_edit == true || this.$route.query.qc_edit == "true") {
            this.popupData(data);
          } else {
            this.$swal({
              title: "Data Exist",
              html: "Data tanggal tersebut sudah ada <br style='margin-top:2px;'>View data ?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "View",
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-danger ml-1",
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                this.popupData(data);
              } else if (result.dismiss === "cancel") {
                this.tanggal = "";
                this.jam = "";
                this.showLoading = false;
                this.isEdit = false;
                this.donePreview = false;
                this.resetForm();
              }
            });
          }
        })
        .catch(async (error) => {
          // console.log("cekExistData - eeror");
          const { response } = error;
          this.resetForm();
          if (response?.status === 404) {
            // this.checkPreviousDataExist(this.t_path, this.t_data_id);
            status.allowToEntry = await this.checkPreviousDataExist(this.t_path, this.t_data_id);
          } else {
            console.error(error);
          }

          this.showLoading = false;
          this.isEdit = false;
          this.donePreview = false;
          // this.resetForm();
          // this.is_data_ready_to_filled = true;
        });
      return Promise.resolve(status);
    },

    async cekDigiData() {
      this.showLoading = true;
      // const urldigi = 'http://10.52.0.18:5123'
      // const get_url = `${urldigi}/synop/${this.t_wmoid}?ts=${this.tanggal}T${this.jam.substr(0, 2)}:00:00.000Z`;
      
      // await axios
      //   .get(get_url, Helper.getConfig())
      await awsdigi.getSynopDigi(this.t_wmoid, this.tanggal, this.jam)
        .then((response) => {
          const { data } = response;
          const currentDate = new Date(data.ts_data_utc)
          const dstring = `${currentDate.getUTCDate().toString().padStart(2, '0')}-${(currentDate.getUTCMonth() + 1).toString().padStart(2, '0')}-${currentDate.getUTCFullYear()} 
            ${(currentDate.getUTCHours()).toString().padStart(2, '0')}:${(currentDate.getUTCMinutes()).toString().padStart(2, '0')}:00`;
          this.$swal({
              title: "Data AWS Digi Exist",
              html: `Data AWS Digi <strong>${data.id_station}</strong>  
              <br style='margin-top:2px;'>
              timestamp <strong>${dstring}</strong> tersedia
              <br style='margin-top:2px;'>
              Fill data ?`,
              icon: "info",
              showCancelButton: true,
              confirmButtonText: "Fill",
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-danger ml-1",
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                this.popupDigiData(data)
              }
            });
        })
        .catch((error) => {
          this.$toast({
          component: ToastificationContent,
          props: {
            title: "Data AWS Digi belum tersedia",
            icon: "BellIcon",
            variant: "danger",
          },
        });
        })
    },

    isSameDate(first_date, choose_date) {
      // console.log(first_date, choose_date);
      if (first_date && choose_date) {
        let firstDate = new Date(String(first_date).trim());
        let chooseDate = new Date(String(choose_date).trim());

        let status = firstDate.getDate() == chooseDate.getDate() && firstDate.getMonth() == chooseDate.getMonth() && firstDate.getFullYear() == chooseDate.getFullYear();
        // console.log("masuk status", status, chooseDate, firstDate.getDate(), chooseDate.getDate(), firstDate.getMonth(), chooseDate.getMonth(), firstDate.getFullYear(), chooseDate.getFullYear());
        return status;
      } else {
        return false;
      }
    },

    getPreviousObjectName(format) {
      let objData = format.split("_");
      let now = objData[2];
      let dateNow = new Date(now);
      dateNow.setDate(dateNow.getDate() - 1);
      // console.log("dateNow", now, dateNow);
      let formatPreviousDate = objData[0] + "_" + objData[1] + "_" + dateNow.getFullYear() + "-" + ("0" + (dateNow.getMonth() + 1)).slice(-2) + "-" + ("0" + dateNow.getDate()).slice(-2);
      let sdate = dateNow.getDate() + "-" + DataHelper.getMonthText(dateNow.getMonth()) + "-" + dateNow.getFullYear();
      // console.log("formatPreviousDate", formatPreviousDate);
      return { "format": formatPreviousDate, "date": sdate }//formatPreviousDate;
    },

    getPreviousTime(time) {
      let previousTime = 0
      if (this.t_start_hour != 0) {
        if (time == 0) {
          previousTime = this.t_mid_end_hour;
        }
        else {
          if((time > this.t_start_hour && time <= this.t_mid_end_hour) || (time > this.t_mid_start_hour && time <= this.t_end_hour)) {
            previousTime = (time - 1)
          }
          else if (time == this.t_start_hour){
            previousTime = this.t_end_hour
          }
          else if (time == this.t_mid_start_hour) {
            previousTime = this.t_mid_end_hour
          }
          else {
            // console.log('getPreviousTime - else nothing')
          }
        }
      }
      else { // start from 00 (normal)
        if (time == 0) {
          previousTime = this.t_operating_hours ? (this.t_operating_hours != 24) ? this.t_operating_hours : (this.t_operating_hours - 1) : 0;
        }
        else {
          previousTime = (time - 1)
        }
      }

      return previousTime
    },

    async checkPreviousDataExist(tPath, tDataID) {
      
      let allowToEntry = false;
      let dataid = tDataID.split("T");
      let jam = dataid[1];

      if (jam == "01") {
        // let formatData = this.getPreviousObjectName(dataid[0]).format;
        // let previewJam = this.t_operating_hours ? this.t_operating_hours - 1 : 0;
        // let currentHours = ("0" + previewJam).slice(-2);
        // const get_url = Helper.getBaseUrl() + tPath + "/" + formatData + "T" + currentHours;
        let jamInt = parseInt(jam);
        let previewJam = jamInt - 1;
        let currentHours = ("0" + previewJam).slice(-2);
        const get_url = Helper.getBaseUrl() + tPath + "/" + dataid[0] + "T" + currentHours;

        await axios
          .get(get_url, Helper.getConfig())
          .then((response) => {
            const { data } = response;
            this.is_data_ready_to_filled = true;
            this.getDataTambahan();

            allowToEntry = this.is_data_ready_to_filled;
          })
          .catch((error) => {
            const { response } = error;
            if (response?.status === 404) {
              if (this.isSameDate(this.t_entry_first_date, this.tanggal)) {
                // console.log('is same date')
                this.is_data_ready_to_filled = true;
                allowToEntry = this.is_data_ready_to_filled;
                this.getDataTambahan();
              } else {
                // console.log("checkPreviousDataExist - jam 00, not same date");
                let message = "";
                if (this.t_entry_first_date) {
                  let fdate = new Date(this.t_entry_first_date);
                  let sdate = fdate.getDate() + "-" + DataHelper.getMonthText(fdate.getMonth()) + "-" + fdate.getFullYear();
                  message = "Silahkan cek atau entry data pertama pada tanggal " + sdate + " jam 01:00";
                } else {
                  message = "Entry data pertama belum ditentukan. Lihat pada metadata atau hubungi admin anda.";
                }

                this.$swal({
                  title: "Data Sinoptik pada hari sebelumnya belum ada!",
                  text: message,
                  icon: "error",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });

                // this.resetForm();
                this.is_data_ready_to_filled = false;
                allowToEntry = this.is_data_ready_to_filled;
              }

              // AN redundant set
              // this.showLoading = false;
              // this.isEdit = false;
              // this.donePreview = false;
            } else {
              // AN redundant set
              // this.showLoading = false;
              // this.isEdit = false;
              // this.donePreview = false;
              // this.resetForm();
              this.is_data_ready_to_filled = false;
              allowToEntry = this.is_data_ready_to_filled;
            }
          });
          
          return allowToEntry

        // if (this.isSameDate(this.t_entry_first_date, this.tanggal)) {
        //   this.is_data_ready_to_filled = true
        //   this.getDataTambahan();
        // }
        // else {
        //   console.log('checkPreviousDataExist - jam 00, not same date')
        //   let formatData = this.getPreviousObjectName(dataid[0])
        //   let previewJam = this.t_operating_hours ? this.t_operating_hours - 1 : 0
        //   let currentHours = ("0" + previewJam).slice(-2);
        //   const get_url = Helper.getBaseUrl() + tPath + "/" + formatData + "T" + currentHours;

        //   axios
        //     .get(get_url, Helper.getConfig())
        //     .then((response) => {
        //       const { data } = response;
        //       this.is_data_ready_to_filled = true
        //       this.getDataTambahan();
        //     })
        //     .catch((error) => {
        //       const { response } = error;
        //       if (response?.status === 404) {
        //         let message = ""
        //         if(this.t_entry_first_date) {
        //           let fdate = new Date(this.t_entry_first_date)
        //           let sdate = fdate.getDate() + "-" + DataHelper.getMonthText((fdate.getMonth())) + "-" + fdate.getFullYear();
        //           message = "Silahkan cek atau entry data pertama pada tanggal "+ sdate
        //         }
        //         else {
        //           message = "Entry data pertama belum ditentukan. Lihat pada metadata atau hubungi admin anda."
        //         }

        //         this.$swal({
        //           title: "Data Sinoptik pada hari sebelumnya belum ada!",
        //           text: message,
        //           icon: "danger",
        //           customClass: {
        //             confirmButton: "btn btn-primary",
        //           },
        //           buttonsStyling: false,
        //         });
        //       } else {}

        //       this.showLoading = false;
        //       this.isEdit = false;
        //       this.donePreview = false;
        //       this.resetForm();
        //     });

        // }

        //  // this.getDataTambahan();
      } else {
        let formatData = ""
        let previewJam = ""
        let currentHours = ""
        let get_url = ""
        let message = ""
        if (jam == "00") {
          let objName = this.getPreviousObjectName(dataid[0]);
          formatData = objName.format
          // previewJam = this.t_operating_hours ? (this.t_operating_hours != 24) ? this.t_operating_hours : (this.t_operating_hours - 1) : 0;
          previewJam = this.getPreviousTime(0)
          currentHours = ("0" + previewJam).slice(-2);
          get_url = Helper.getBaseUrl() + tPath + "/" + formatData + "T" + currentHours;
          message = "Data Sinoptik pada tanggal " + objName.date + " jam " + currentHours + " belum dientry!"
        }
        else {
          let jamInt = parseInt(jam);
          // previewJam = jamInt - 1;
          previewJam = this.getPreviousTime(jamInt)
          currentHours = ("0" + previewJam).slice(-2);
          get_url = Helper.getBaseUrl() + tPath + "/" + dataid[0] + "T" + currentHours;
          message = "Data Sinoptik pada jam " + currentHours + " belum dientry!"
        }
        // console.log("checkPreviousDataExist - jam 01 dst", get_url);
        await axios
          .get(get_url, Helper.getConfig())
          .then((response) => {
            const { data } = response;
            this.is_data_ready_to_filled = true;
            this.getDataTambahan();
            // this.showLoading = false;

            allowToEntry = this.is_data_ready_to_filled;
          })
          .catch((error) => {
            const { response } = error;
            if (response?.status === 404) {
              this.$swal({
                title: message,//"Data Sinoptik pada jam " + currentHours + " belum dientry!",
                text: "Mohon pastikan data tersebut dientry agar menghasilkan sandi yang benar.",
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            } else {
              // AN ?
            }

            // Redundant set
            // this.showLoading = false;
            // this.isEdit = false;
            // this.donePreview = false;
            // this.resetForm();
            this.is_data_ready_to_filled = false;
            allowToEntry = this.is_data_ready_to_filled;
          });
          
          return allowToEntry
      }
    },

    fetchData() {
      if (this.t_station_id == "") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Pilih stasiun terlebih dahulu",
            icon: "BellIcon",
            variant: "danger",
          },
        });
      } else if (this.jam != "" && this.tanggal != "") {
        this.cekExistData().then((res) => {
          if(!res.exist && res.allowToEntry && this.hasDigi) {
            this.cekDigiData()
          }
        });
      } else {
        this.showLoading = false;
        this.isEdit = false;
        this.donePreview = false;
        this.resetForm();
      }
    },

    validateForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Data tidak valid. Periksa kembali inputan anda",
            icon: "BellIcon",
            variant: "danger",
          },
        });
        return false;
      } else {
        return true;
      }
    },

    getHtmlMessage(data) {
      let html = ""
      if (data && data.length > 0) {
        for (let dt of data) {
          html += '<div>' + dt.atribut + ': ' + dt.message + ' </div>'
        }
        return html
      }

      return "<div />"
    },

    submitForm() {

      if (!this.validateForm()) return false;
      // qc parameter:START
      let qc_flag = 0;
      let qc_histories = {
        before: this.last_qc_histories,
        after: null,
      };
      let sc_qc_flag = 0;
      let sc_qc_histories = {
        before: this.last_sc_qc_histories,
        after: null,
      };
      
      // range check
      // RC: { status, message }
      let qc_found = Object.entries(this.RC)
        .filter((e) => e[1].status)
        .map((e) => e[0])
        .map((e) => {
          const roles_data = RC_helper.data[e];
          const roles = Object.values(roles_data).join(",");
          return {
            raw_type: "sinoptik",
            atribut: e,
            value: this.input_data[e],
            roles: roles,
            type: "range_check",
            message: `Range Check - Batas Bawah ${roles_data.min}, Batas Atas ${roles_data.max}, Special Value ${roles_data.values}`,
          };
        });

      let sc_qc_found = Object.entries(this.SC)
        .filter((e) => e[1].status)
        .map((e) => e[0])
        .map((e) => {
          const roles_data = SC_helper.data[e];
          const roles = Object.values(roles_data).join(",");
          return {
            raw_type: "sinoptik",
            atribut: e,
            value: this.input_data[e],
            roles: roles,
            type: "step_check",
            message: `Step Check - Batas Bawah ${roles_data.before}, Batas Atas ${roles_data.after}`,
          };
        });
      
      // console.log('terkoreksi qc', qc_found, sc_qc_found)

      let qc_status = 0
      let text = ""
      let title = ""
      let msg_html = ""
      if (Object.values(qc_found).length > 0 && Object.values(sc_qc_found).length > 0) {
        // console.log('1' )
        qc_status = 1
        qc_flag = 1;
        qc_histories.after = qc_found;
        msg_html = this.getHtmlMessage(qc_found)

        sc_qc_flag = 1;
        sc_qc_histories.after = sc_qc_found;
        msg_html = msg_html + " \n" +this.getHtmlMessage(sc_qc_found)

        text = "Ada Data Terkoreksi QC"
        title = "Ada data terkoreksi Range Check dan Step Check. Apakah Anda yakin untuk simpan data ?"
      }
      else if (Object.values(qc_found).length > 0) {
        // console.log('2' )
        qc_status = 2
        sc_qc_flag = 1;
        sc_qc_histories.after = qc_found;
        msg_html = this.getHtmlMessage(qc_found)

        text = "Ada Data Terkoreksi QC "
        title = "Ada data terkoreksi Range Check. Apakah Anda yakin untuk simpan data ?"
      }
      else if (Object.values(sc_qc_found).length > 0) {
        // console.log('3' )
        qc_status = 3
        sc_qc_flag = 1;
        sc_qc_histories.after = sc_qc_found;
        msg_html = this.getHtmlMessage(sc_qc_found)

        text = "Ada Data Terkoreksi QC"
        title = "Ada data terkoreksi Step Check. Apakah Anda yakin untuk simpan data ?"
      }
      else {
        qc_status = 0
      }

      // console.log('qc_status', qc_status, text, title, msg_html)
      if (qc_status > 0) {

        this.$swal({
          title: title,
          text: text,
          html: msg_html,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Submit",
          cancelButtonText: "Cek Data",
          allowOutsideClick: false,
          allowEscapeKey: false,
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.submitFormComfirm(qc_flag, qc_histories, sc_qc_flag, sc_qc_histories);
          } else if (result.dismiss === "cancel") {
            return;
          }
        });
      }
      else {
        this.submitFormComfirm(0, { before: null, after: null }, 0, { before: null, after: null });
      }

    },

    submitFormComfirm(flag, qc_histories, sc_flag, sc_qc_histories) {
      // if (!this.validateForm()) return false;
      this.showLoading = true;

      // // qc parameter:START
      // let qc_flag = 0;
      // let qc_histories = {
      //   before: this.last_qc_histories,
      //   after: null,
      // };
      // // range check
      // // RC: { status, message }
      // let qc_found = Object.entries(this.RC)
      //   .filter((e) => e[1].status)
      //   .map((e) => e[0])
      //   .map((e) => {
      //     const roles_data = RC_helper.data[e];
      //     const roles = Object.values(roles_data).join(",");
      //     return {
      //       raw_type: "sinoptik",
      //       atribut: e,
      //       value: this.input_data[e],
      //       roles: roles,
      //       type: "range_check",
      //       message: `batas bawah ${roles_data.min}, batas atas ${roles_data.max}, special value ${roles_data.values}`,
      //     };
      //   });
      // if (Object.values(qc_found).length) {
      //   qc_flag = 1;
      //   qc_histories.after = qc_found;

      // }
      

      let qc_flag = flag
      let sc_qc_flag = sc_flag

      if (this.$route.query.qc_edit == true || this.$route.query.qc_edit == "true") {
        qc_flag = 2;
      }
      // qc parameter:END

      const data_timestamp = this.tanggal + "T" + this.jam.substr(0, 2);
      const post_data = {
        cloud_cover_oktas_m: this.input_data.cloud_cover_oktas_m !== "" ? this.input_data.cloud_cover_oktas_m : null,
        cloud_low_type_cl: this.awan_rendah.CL !== "" ? this.awan_rendah.CL : null,
        cloud_med_type_cm: this.awan_menengah.CM !== "" ? this.awan_menengah.CM : null,
        cloud_high_type_ch: this.awan_tinggi.CH !== "" ? this.awan_tinggi.CH : null,
        cloud_low_cover_oktas: this.awan_rendah.NCL !== "" ? this.awan_rendah.NCL : null,
        cloud_med_cover_oktas: this.awan_menengah.NCM !== "" ? this.awan_menengah.NCM : null,
        cloud_high_cover_oktas: this.awan_tinggi.NCH !== "" ? this.awan_tinggi.NCH : null,
        // start: otomatis setCloudslayer()
        cloud_layer_1_type_c: this.cloud_layer_1_type_c,
        cloud_layer_1_height_m_hshs: this.cloud_layer_1_height_m_hshs,
        cloud_layer_1_amt_oktas_ns: this.cloud_layer_1_amt_oktas_ns,
        cloud_layer_2_type_c: this.cloud_layer_2_type_c,
        cloud_layer_2_height_m_hshs: this.cloud_layer_2_height_m_hshs,
        cloud_layer_2_amt_oktas_ns: this.cloud_layer_2_amt_oktas_ns,
        cloud_layer_3_type_c: this.cloud_layer_3_type_c,
        cloud_layer_3_height_m_hshs: this.cloud_layer_3_height_m_hshs,
        cloud_layer_3_amt_oktas_ns: this.cloud_layer_3_amt_oktas_ns,
        cloud_layer_4_type_c: this.cloud_layer_4_type_c,
        cloud_layer_4_height_m_hshs: this.cloud_layer_4_height_m_hshs,
        cloud_layer_4_amt_oktas_ns: this.cloud_layer_4_amt_oktas_ns,
        // end: otomatis setCloudslayer()
        evaporation_24hours_mm_eee: this.input_data.evaporation_24hours_mm_eee !== "" ? this.input_data.evaporation_24hours_mm_eee : null,
        evaporation_eq_indicator_ie: this.input_data.evaporation_eq_indicator_ie !== "" ? this.input_data.evaporation_eq_indicator_ie : null,
        land_cond: this.input_data.land_cond !== "" ? this.input_data.land_cond : null,
        land_note: this.input_data.land_note !== "" ? this.input_data.land_note : null,
        past_weather_w1: this.input_data.past_weather_w1,
        past_weather_w2: this.input_data.past_weather_w2,
        present_weather_ww: this.input_data.present_weather_ww,
        pressure_reading_mb: this.input_data.pressure_reading_mb !== "" ? this.input_data.pressure_reading_mb : null,
        pressure_temp_c: this.input_data.pressure_temp_c !== "" ? this.input_data.pressure_temp_c : null,
        rainfall_24h_rrrr: this.rainfall_jumlah_24h, //otomatis jumlah hujan 24 jam (total rr3 dalam satu hari 8 data)
        rainfall_6h_rrr: this.rainfall_jumlah_6h, // otomatis jumlah hujan setiap 6 jam (total rr3 dalam 6 jam 2 data)
        rainfall_indicator_ir: this.rainfall_indicator_ir,
        rainfall_last_mm: this.input_data.rainfall_last_mm !== "" ? this.input_data.rainfall_last_mm : null,
        solar_rad_24h_jcm2_f24: this.input_data.solar_rad_24h_jcm2_f24 !== "" ? this.input_data.solar_rad_24h_jcm2_f24 : null,
        sunshine_h_sss: this.input_data.sunshine_h_sss !== "" ? this.input_data.sunshine_h_sss : null,
        temp_dewpoint_c_tdtdtd: this.temp_dewpoint_c_tdtdtd,
        temp_drybulb_c_tttttt: this.input_data.temp_drybulb_c_tttttt,
        temp_max_c_txtxtx: this.input_data.temp_max_c_txtxtx !== "" ? this.input_data.temp_max_c_txtxtx : null,
        temp_min_c_tntntn: this.input_data.temp_min_c_tntntn !== "" ? this.input_data.temp_min_c_tntntn : null,
        temp_wetbulb_c: this.input_data.temp_wetbulb_c,
        visibility_vv: this.input_data.visibility_vv,
        weather_indicator_ix: this.input_data.weather_indicator_ix, //berdasarkan data ww, w1 dan w2 ix = 1, jika ww, w1 dan w2 bermakna
        wind_dir_deg_dd: this.input_data.wind_dir_deg_dd,
        wind_indicator_iw: this.input_data.wind_indicator_iw !== "" ? this.input_data.wind_indicator_iw : null, //referensi
        wind_speed_ff: this.input_data.wind_speed_ff,
        relative_humidity_pc: this.relative_humidity_pc,
        pressure_qff_mb_derived: this.input_data.pressure_qff_mb_derived,
        pressure_qfe_mb_derived: this.input_data.pressure_qfe_mb_derived,
        pressure_tend_3h_mb_ppp: (typeof this.selisih_QFF_3h === 'string' || this.selisih_QFF_3h instanceof String) ? null : this.selisih_QFF_3h, //selisih tekanan PRESSURE_QFE (sekarang - 3jam yang lalu) --> hanya disandi, untuk value parameter tidak perlu perhitungan
        pressure_tend_24h_mb_ppp: (typeof this.selisih_QFF_24h === 'string' || this.selisih_QFF_24h instanceof String) ? null : this.selisih_QFF_24h, //selisih tekanan QFE 24 jam
        cloud_low_type_1: this.awan_rendah.jenis[0] !== "" ? this.awan_rendah.jenis[0] : null,
        cloud_low_type_2: this.awan_rendah.jenis[1] !== "" ? this.awan_rendah.jenis[1] : null,
        cloud_low_type_3: this.awan_rendah.jenis[2] !== "" ? this.awan_rendah.jenis[2] : null,
        cloud_med_type_1: this.awan_menengah.jenis[0] !== "" ? this.awan_menengah.jenis[0] : null,
        cloud_med_type_2: this.awan_menengah.jenis[1] !== "" ? this.awan_menengah.jenis[1] : null,
        cloud_high_type_1: this.awan_tinggi.jenis[0] !== "" ? this.awan_tinggi.jenis[0] : null,
        cloud_high_type_2: this.awan_tinggi.jenis[1] !== "" ? this.awan_tinggi.jenis[1] : null,
        cloud_low_base_1: this.awan_rendah.tinggi_dasar[0] !== "" ? this.awan_rendah.tinggi_dasar[0] : null,
        cloud_low_base_2: this.awan_rendah.tinggi_dasar[1] !== "" ? this.awan_rendah.tinggi_dasar[1] : null,
        cloud_low_base_3: this.awan_rendah.tinggi_dasar[2] !== "" ? this.awan_rendah.tinggi_dasar[2] : null,
        cloud_med_base_1: this.awan_menengah.tinggi_dasar[0] !== "" ? this.awan_menengah.tinggi_dasar[0] : null,
        cloud_med_base_2: this.awan_menengah.tinggi_dasar[1] !== "" ? this.awan_menengah.tinggi_dasar[1] : null,
        cloud_high_base_1: this.awan_tinggi.tinggi_dasar[0] !== "" ? this.awan_tinggi.tinggi_dasar[0] : null,
        cloud_high_base_2: this.awan_tinggi.tinggi_dasar[1] !== "" ? this.awan_tinggi.tinggi_dasar[1] : null,
        cloud_low_peak_1: this.awan_rendah.tinggi_puncak[0] !== "" ? this.awan_rendah.tinggi_puncak[0] : null,
        cloud_low_peak_2: this.awan_rendah.tinggi_puncak[1] !== "" ? this.awan_rendah.tinggi_puncak[1] : null,
        cloud_low_cover_1: this.awan_rendah.jumlah[0] !== "" ? this.awan_rendah.jumlah[0] : null,
        cloud_low_cover_2: this.awan_rendah.jumlah[1] !== "" ? this.awan_rendah.jumlah[1] : null,
        cloud_low_cover_3: this.awan_rendah.jumlah[2] !== "" ? this.awan_rendah.jumlah[2] : null,
        cloud_med_cover_1: this.awan_menengah.jumlah[0] !== "" ? this.awan_menengah.jumlah[0] : null,
        cloud_med_cover_2: this.awan_menengah.jumlah[1] !== "" ? this.awan_menengah.jumlah[1] : null,
        cloud_high_cover_1: this.awan_tinggi.jumlah[0] !== "" ? this.awan_tinggi.jumlah[0] : null,
        cloud_high_cover_2: this.awan_tinggi.jumlah[1] !== "" ? this.awan_tinggi.jumlah[1] : null,
        cloud_low_dir_1: this.awan_rendah.arah[0] !== "" ? this.awan_rendah.arah[0] : null,
        cloud_low_dir_2: this.awan_rendah.arah[1] !== "" ? this.awan_rendah.arah[1] : null,
        cloud_low_dir_3: this.awan_rendah.arah[2] !== "" ? this.awan_rendah.arah[2] : null,
        cloud_med_dir_1: this.awan_menengah.arah[0] !== "" ? this.awan_menengah.arah[0] : null,
        cloud_med_dir_2: this.awan_menengah.arah[1] !== "" ? this.awan_menengah.arah[1] : null,
        cloud_high_dir_1: this.awan_tinggi.arah[0] !== "" ? this.awan_tinggi.arah[0] : null,
        cloud_high_dir_2: this.awan_tinggi.arah[1] !== "" ? this.awan_tinggi.arah[1] : null,
        cloud_elevation_angle_ec_1: this.awan_rendah.sudut[0] || this.awan_rendah.sudut[0] == "0"  ? this.awan_rendah.sudut[0] : null,
        cloud_elevation_angle_ec_2: this.awan_rendah.sudut[1] || this.awan_rendah.sudut[1] == "0" ? this.awan_rendah.sudut[1] : null,
        cloud_low_dir_true_da_1: this.awan_rendah.da[0] !== "" ? this.awan_rendah.da[0] : null,
        cloud_low_dir_true_da_2: this.awan_rendah.da[1] !== "" ? this.awan_rendah.da[1] : null,
        cloud_vertical_vis: GenHelper.parseIfOnlyNumberElseNull(this.input_data.cloud_vertical_vis),
        entry_user: 1,
        encoded_synop: this.sandi,
        encoded_synop_with_header: this.sandi_with_header,
        observer_id: this.t_observer,
        observer_name: this.t_observer_opt.find((el) => el.id === this.t_observer).observer,
        data_timestamp: data_timestamp,
        koreksi_pressure_qff_mb_derived: this.koreksi_pressure_qff_mb_derived,
        koreksi_pressure_qfe_mb_derived: this.koreksi_pressure_qfe_mb_derived,
        sandi_tr: this.sandi_tr,
        qc_flag: qc_flag,
        qc_histories: JSON.stringify(qc_histories),
        qc_stepcheck_flag: sc_qc_flag,
        qc_stepcheck_histories: JSON.stringify(sc_qc_histories),
      };

      let url_sinop = Helper.getBaseUrl() + this.t_path;
      let region_id = this.t_path.split('/')[1]
      if (this.isEdit) {
        // update data
        url_sinop += "/" + this.t_data_id;
        axios
          .patch(url_sinop, post_data, Helper.getConfig())
          .then((response) => {
            if (response.status == 201 || response.status == 200 || response.status == 204) {
              this.$swal({
                title: "Edit Data berhasil!",
                text: "Edit Data Sinoptik",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.isEdit = false;
              this.donePreview = false;
              this.showLoading = false;
              this.resetForm();
              // this.tanggal = "";
              this.jam = "";
            }
          })
          .catch((error) => {
            this.showLoading = false;
            error_handler(this, error, "Sinoptik", "Edit Data Error");
          });


        // update qc notification
        qc_helper.editQCNotification(
          this.t_data_id, // id entry
          "Sinoptik", // type form entry
          this.tanggal, // tanggal entry
          this.jam, // jam entry
          JSON.stringify(qc_histories), // qc histories
          qc_flag,
          this.t_station_id,
          this.t_station.text,
          this.t_observer,
          this.t_observer_opt.find((el) => el.id === this.t_observer).observer, // observer name
          region_id,
          this.t_path + '/' + this.t_data_id
        );
        if (this.$route.query.qc_edit == true || this.$route.query.qc_edit == "true") {
          this.$router.replace({ query: null });
        }
      } else {
        // input data baru
        post_data["@type"] = "Sinoptik";
        post_data.id = this.t_data_id;
        axios
          .post(url_sinop, post_data, Helper.getConfig())
          .then((response) => {
            if (response.status == 201 || response.status == 200 || response.status == 204) {
              this.$swal({
                title: "Input Data berhasil!",
                text: "Input Data Sinoptik",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.showLoading = false;
              this.resetForm();
              // this.tanggal = "";
              this.jam = "";
            }
          })
          .catch((error) => {
            this.showLoading = false;
            error_handler(this, error, "Sinoptik", "Input Data Error");
          });

        // input qc notification
        if (qc_flag == 1) {
          qc_helper.createQCNotification(
            this.t_data_id, // id entry
            "Sinoptik", // type form entry
            this.tanggal, // tanggal entry
            this.jam, // jam entry
            JSON.stringify(qc_histories), // qc histories
            qc_flag,
            this.t_station_id,
            this.t_station.text,
            this.t_observer,
            this.t_observer_opt.find((el) => el.id === this.t_observer).observer, // observer name
            region_id,
            this.t_path + '/' + this.t_data_id
          );
        }
      }
    },
    async getDataTambahan() {
      this.showLoading = true;

      const data_timestamp = this.tanggal + "T" + this.jam.substr(0, 2);
      const config = {
        mode: "cors",
        withCredentials: false,
        params: {
          type_name__eq: "Sinoptik",
          station_wmo_id: this.t_wmoid,
          data_timestamp__lt: data_timestamp,
          _size: "24",
        },
        headers: Helper.getHeader(),
      };
      const get_url = Helper.getBaseUrl() + "@sinop_rainfall_pressure";

      axios
        .get(get_url, config)
        .then((response) => {
          const { data } = response;
          if (data.hasOwnProperty("error")) {
            this.showLoading = false;
            this.rainfall_pressure = null;
            let position = "top-center";
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: data.error + ".",
                  icon: "BellIcon",
                  variant: "danger",
                },
              },
              // AN standardize to top right
              // { position }
            );
          } else {
            const { rainfall_last_mm, pressure_qfe_mb_derived, pressure_qff_mb_derived, temp_drybulb_c_tttttt, temp_wetbulb_c, temp_max_c_txtxtx, temp_min_c_tntntn, relative_humidity_pc } = response.data;
            this.rainfall_pressure = response.data;
            
            if (temp_drybulb_c_tttttt?.temp_drybulb_c_tttttt_max !== null && temp_drybulb_c_tttttt?.temp_drybulb_c_tttttt_max !== undefined) {
              this.temp_drybulb_c_tttttt_max = +temp_drybulb_c_tttttt.temp_drybulb_c_tttttt_max;
            } else {
              this.temp_drybulb_c_tttttt_max = 0;
            }
            
            if (temp_drybulb_c_tttttt?.temp_drybulb_c_tttttt_min !== null && temp_drybulb_c_tttttt?.temp_drybulb_c_tttttt_min !== undefined) {
              this.temp_drybulb_c_tttttt_min = +temp_drybulb_c_tttttt.temp_drybulb_c_tttttt_min;
            } else {
              this.temp_drybulb_c_tttttt_min = 0;
            }

            // AN dari API null, sementara tak bisa dipakai
            // if (temp_max_c_txtxtx?.temp_max_c_txtxtx !== null && temp_max_c_txtxtx?.temp_max_c_txtxtx !== undefined) {
            //   this.temp_max_c_txtxtx_12 = +temp_max_c_txtxtx.temp_max_c_txtxtx;
            // } else {
            //   this.temp_max_c_txtxtx_12 = 0;
            // }

            // if (temp_min_c_tntntn?.temp_min_c_tntntn !== null && temp_min_c_tntntn?.temp_min_c_tntntn !== undefined) {
            //   this.temp_min_c_tntntn_00 = +temp_min_c_tntntn.temp_min_c_tntntn;
            // } else {
            //   this.temp_min_c_tntntn_00 = 0;
            // }

            if (temp_max_c_txtxtx?.items !== null && temp_max_c_txtxtx?.items !== undefined) {
              const itemsKeys = Object.keys(temp_max_c_txtxtx.items).map(parseFloat);
              this.temp_max_c_txtxtx_12 = Math.max(...itemsKeys);
            } else {
              this.temp_max_c_txtxtx_12 = 0;
            }

            if (temp_min_c_tntntn?.items !== null && temp_min_c_tntntn?.items !== undefined) {
              const itemsKeys = Object.keys(temp_min_c_tntntn.items).map(parseFloat);
              this.temp_min_c_tntntn_00 = Math.min(...itemsKeys);
            } else {
              this.temp_min_c_tntntn_00 = 0;
            }

            if (pressure_qff_mb_derived?.pressure_tend_3h_mb_www !== null && pressure_qff_mb_derived?.pressure_tend_3h_mb_www !== undefined && pressure_qff_mb_derived?.pressure_tend_3h_mb_www !== 'None') {
              this.pressure_tend_3h_mb_www = +pressure_qff_mb_derived.pressure_tend_3h_mb_www;
            } else {
              this.pressure_tend_3h_mb_www = null;
            }
            if (pressure_qff_mb_derived?.pressure_tend_2h_mb_www !== null && pressure_qff_mb_derived?.pressure_tend_2h_mb_www !== undefined) {
              this.pressure_tend_2h_mb_www = +pressure_qff_mb_derived.pressure_tend_2h_mb_www;
            } else {
              this.pressure_tend_2h_mb_www = 0;
            }
            if (pressure_qff_mb_derived?.pressure_tend_1h_mb_www !== null && pressure_qff_mb_derived?.pressure_tend_1h_mb_www !== undefined) {
              this.pressure_tend_1h_mb_www = +pressure_qff_mb_derived.pressure_tend_1h_mb_www;
            } else {
              this.pressure_tend_1h_mb_www = 0;
            }

            // per 3 jam
            if (this.jam === "00:00" || this.jam === "03:00" || this.jam === "06:00" || this.jam === "09:00" || this.jam === "12:00" || this.jam === "15:00" || this.jam === "18:00" || this.jam === "21:00") {
              this.pressure_tend_3h_mb_ppp = +pressure_qfe_mb_derived.pressure_tend_3h_mb_ppp;
            } else {
              this.pressure_tend_3h_mb_ppp = null;
            }

            // per 6 jam
            // if (this.jam === "00:00" || this.jam === "06:00" || this.jam === "12:00" || this.jam === "18:00") {
            //   if (rainfall_last_mm?.rainfall_6h_rrr !== null && rainfall_last_mm?.rainfall_6h_rrr !== undefined) {
            //     this.rainfall_6h_rrr = +rainfall_last_mm.rainfall_6h_rrr;
            //   } else {
            //     this.rainfall_6h_rrr = 0;
            //   }
            // } else {
            //   this.rainfall_6h_rrr = null;
            // }
            // AN : Ch 6 jam lalu tidak selalu ada sehingga boleh ada null
            if (this.jam === "00:00" || this.jam === "06:00" || this.jam === "12:00" || this.jam === "18:00") {
              if (rainfall_last_mm?.rainfall_6h_rrr !== null && rainfall_last_mm?.rainfall_6h_rrr !== undefined) {
                this.rainfall_6h_rrr = +rainfall_last_mm.rainfall_6h_rrr;
              } else {
                this.rainfall_6h_rrr = null;
              }
            } else {
              this.rainfall_6h_rrr = null;
            }

            // per 12 jam
            if (this.jam === "00:00" || this.jam === "12:00") {
              // if (pressure_qfe_mb_derived?.pressure_tend_24h_mb_ppp !== null && pressure_qfe_mb_derived?.pressure_tend_24h_mb_ppp !== undefined) {
              //   this.pressure_tend_24h_mb_ppp = +pressure_qfe_mb_derived.pressure_tend_24h_mb_ppp;
              // } else {
              //   this.pressure_tend_24h_mb_ppp = 0;
              // }

              if (pressure_qff_mb_derived?.pressure_tend_24h_mb_www !== null && 
                pressure_qff_mb_derived?.pressure_tend_24h_mb_www !== undefined) {
                this.pressure_tend_24h_mb_ppp = +pressure_qff_mb_derived.pressure_tend_24h_mb_www;
              } else {
                this.pressure_tend_24h_mb_ppp = 0;
              }
              if (rainfall_last_mm?.rainfall_24h_rrrr !== null && 
                rainfall_last_mm?.rainfall_24h_rrrr !== undefined) {
                this.rainfall_24h_rrrr = +rainfall_last_mm.rainfall_24h_rrrr;
              } else {
                this.rainfall_24h_rrrr = 0;
              }
            } else {
              this.pressure_tend_24h_mb_ppp = null;
              this.rainfall_24h_rrrr = null;
            }

            // console.log('set data QC Stepccheck', pressure_qfe_mb_derived, pressure_qff_mb_derived, temp_drybulb_c_tttttt, relative_humidity_pc)
            let jam = this.jam.substr(0, 2)

            // AN - disable this, QC conf mengikuti jam obs
            // if (jam == "00") {
            //   let previousJam = this.t_operating_hours ? this.t_operating_hours - 1 : 0;
            //   let currentHours = ("0" + previousJam).slice(-2);
            //   jam = currentHours
            // }
            // else {
            //   let jamInt = parseInt(jam);
            //   let previousJam = jamInt - 1;
            //   let currentHours = ("0" + previousJam).slice(-2);
            //   jam = currentHours
            // }
            let value = 0
            const qc_key = Params.GET_KEY("me48");
            if (temp_drybulb_c_tttttt?.temp_drybulb_c_tttttt_last !== null && temp_drybulb_c_tttttt?.temp_drybulb_c_tttttt_last !== undefined) {
              value = +temp_drybulb_c_tttttt.temp_drybulb_c_tttttt_last;
              this.SC_helper.setData(value, 'temp_drybulb_c_tttttt', jam, qc_key)
            } else {
              // AN di Loss karena pengamatan sebelumnya kosong
              this.SC_helper.data.temp_drybulb_c_tttttt = null
            }

            if (temp_wetbulb_c?.temp_wetbulb_c_last !== null && temp_wetbulb_c?.temp_wetbulb_c_last !== undefined) {
              value = +temp_wetbulb_c.temp_wetbulb_c_last;
              this.SC_helper.setData(value, 'temp_wetbulb_c', jam, qc_key)
            } else {
              // AN di Loss karena pengamatan sebelumnya kosong
              this.SC_helper.data.temp_wetbulb_c = null
            }

            if (pressure_qff_mb_derived?.pressure_qff_mb_derived_last !== null && pressure_qff_mb_derived?.pressure_qff_mb_derived_last !== undefined) {
              value = +pressure_qff_mb_derived.pressure_qff_mb_derived_last;
              this.SC_helper.setData(value, 'pressure_qff_mb_derived', jam, qc_key)
            } else {
              // AN di Loss karena pengamatan sebelumnya kosong
              this.SC_helper.data.pressure_qff_mb_derived = null
            }

            if (pressure_qfe_mb_derived?.pressure_qfe_mb_derived_last !== null && pressure_qfe_mb_derived?.pressure_qfe_mb_derived_last !== undefined) {
              value = +pressure_qfe_mb_derived.pressure_qfe_mb_derived_last;
              this.SC_helper.setData(value, 'pressure_qfe_mb_derived', jam, qc_key)
            } else {
              // AN di Loss karena pengamatan sebelumnya kosong
              this.SC_helper.data.pressure_qfe_mb_derived = null
            }

            // if (pressure_qfe_mb_derived?.pressure_qfe_mb_derived_last !== null && pressure_qfe_mb_derived?.pressure_qfe_mb_derived_last !== undefined) {
            //   value = +pressure_qfe_mb_derived.pressure_qfe_mb_derived_last;
            //   this.SC_helper.setData(value, 'temp_max_c_txtxtx', jam)
            // }

            // if (pressure_qfe_mb_derived?.pressure_qfe_mb_derived_last !== null && pressure_qfe_mb_derived?.pressure_qfe_mb_derived_last !== undefined) {
            //   value = +pressure_qfe_mb_derived.pressure_qfe_mb_derived_last;
            //   this.SC_helper.setData(value, 'temp_min_c_tntntn', jam)
            // }

            // if (relative_humidity_pc?.relative_humidity_pc_last !== null && relative_humidity_pc?.relative_humidity_pc_last !== undefined) {
            //   value = +relative_humidity_pc.relative_humidity_pc_last;
            //   this.SC_helper.setData(value, 'relative_humidity_pc', jam)
            //   console.log('this.SC_helper.getData()', this.SC_helper.getData())
            // }
            // console.log('teset')
            // console.log('this.SC_helper.getData()', this.SC_helper.getData())

            // AN - always refresh after set qc
            this.checkValidate({ target: { value: this.input_data.temp_max_c_txtxtx } }, "temp_max_c_txtxtx");
            this.checkValidate({ target: { value: this.input_data.temp_min_c_tntntn } }, "temp_min_c_tntntn");
            this.checkValidate({ target: { value: this.input_data.temp_drybulb_c_tttttt } }, "temp_drybulb_c_tttttt");
            this.checkValidate({ target: { value: this.input_data.temp_wetbulb_c } }, "temp_wetbulb_c");
            this.checkValidate({ target: { value: this.input_data.pressure_qfe_mb_derived } }, "pressure_qfe_mb_derived");
            this.checkValidate({ target: { value: this.input_data.pressure_qff_mb_derived } }, "pressure_qff_mb_derived");
    
            this.showLoading = false;
          }
        })
        .catch((error) => {
          if (error.request) {
            this.$swal({
              title: "Get Data Sebelum Error",
              text: "Data sebelumnya belum diinput",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.isEdit = false;
            this.donePreview = false;
            this.tanggal = "";
            this.jam = "";
          }
          this.showLoading = false;
        });
    },
    showPreview() {
      if (!this.validateForm()) return false;

      this.deriveDewPointTempAndRelativeHumidity();
      this.setCloudslayer();
      this.encodeSandi();
      this.$bvModal.show("modal-preview");
      this.donePreview = true;
    },
    // AN 
    // salah ketika menentukan awan di ketinggian sama, 
    // sandi salah saat awan tinggi ci, 
    // salah karena tidak bisa cross level(rendah, menengah, tinggi)
    // tidak bisa 2 CB
    // setCloudslayer() {
    //   let cbIndex = -1;
    //   let tempLayer = [];
    //   let selected = {
    //     jenis: 0,
    //     jumlah: 0,
    //     tinggi_dasar: Infinity,
    //   };

    //   // dari awan rendah
    //   let awan_rendah = this.awan_rendah;
    //   let awan_rendah_jenis = Object.values(awan_rendah.jenis).map(Number);
    //   if (awan_rendah_jenis.includes(9)) {
    //     // kalau ada awan Cb (9)
    //     let i = awan_rendah_jenis.indexOf(9);
    //     cbIndex = i;
    //     tempLayer.push({
    //       jenis: awan_rendah.jenis[i],
    //       jumlah: awan_rendah.jumlah[i],
    //       tinggi_dasar: awan_rendah.tinggi_dasar[i],
    //     });
    //   }

    //   // cek setiap awan_rendah
    //   selected = {
    //     jenis: 0,
    //     jumlah: 0,
    //     tinggi_dasar: Infinity,
    //   };
    //   for (let i = 0; i < 3; i++) {
    //     // langsung skip jika awan Cb
    //     if (cbIndex == i) continue;
    //     // ambil jenis paling tinggi nilainya
    //     if (awan_rendah.jenis[i] !== "" && awan_rendah.jenis[i] !== null && awan_rendah.jenis[i] !== "-" && selected.jenis < +awan_rendah.jenis[i]) {
    //       selected.jenis = +awan_rendah.jenis[i];
    //     }
    //     // jumlahkan semua jumlah
    //     if (awan_rendah.jumlah[i] !== "-") {
    //       selected.jumlah += +awan_rendah.jumlah[i];
    //     }
    //     // ambil tinggi_dasar paling rendah
    //     if (awan_rendah.tinggi_dasar[i] !== "" && awan_rendah.tinggi_dasar[i] !== null && selected.tinggi_dasar > awan_rendah.tinggi_dasar[i]) {
    //       selected.tinggi_dasar = +awan_rendah.tinggi_dasar[i];
    //     }
    //   }
    //   if (selected.jumlah >= 1) {
    //     tempLayer.push({
    //       jenis: "" + selected.jenis,
    //       jumlah: "" + selected.jumlah,
    //       tinggi_dasar: selected.tinggi_dasar,
    //     });
    //   }

    //   // cek setiap awan_menengah
    //   let awan_menengah = this.awan_menengah;
    //   selected = {
    //     jenis: 0,
    //     jumlah: 0,
    //     tinggi_dasar: Infinity,
    //   };
    //   for (let i = 0; i < 2; i++) {
    //     // ambil jenis paling tinggi nilainya
    //     if (awan_menengah.jenis[i] !== "" && awan_menengah.jenis[i] !== null && awan_menengah.jenis[i] !== "-" && selected.jenis < +awan_menengah.jenis[i]) {
    //       selected.jenis = +awan_menengah.jenis[i];
    //     }
    //     // jumlahkan semua jumlah
    //     if (awan_menengah.jumlah[i] !== "-") {
    //       selected.jumlah += +awan_menengah.jumlah[i];
    //     }
    //     // ambil tinggi_dasar paling rendah
    //     if (awan_menengah.tinggi_dasar[i] !== "" && awan_menengah.tinggi_dasar[i] !== null && selected.tinggi_dasar > awan_menengah.tinggi_dasar[i]) {
    //       selected.tinggi_dasar = +awan_menengah.tinggi_dasar[i];
    //     }
    //   }
    //   if (selected.jumlah >= 3) {
    //     tempLayer.push({
    //       jenis: "" + selected.jenis,
    //       jumlah: "" + selected.jumlah,
    //       tinggi_dasar: selected.tinggi_dasar,
    //     });
    //   }

    //   // cek setiap awan_tinggi
    //   let awan_tinggi = this.awan_tinggi;
    //   selected = {
    //     jenis: 0,
    //     jumlah: 0,
    //     tinggi_dasar: Infinity,
    //   };
    //   for (let i = 0; i < 2; i++) {
    //     // ambil jenis paling tinggi nilainya
    //     if (awan_tinggi.jenis[i] !== "" && awan_tinggi.jenis[i] !== null && awan_tinggi.jenis[i] !== "-" && selected.jenis < +awan_tinggi.jenis[i]) {
    //       selected.jenis = +awan_tinggi.jenis[i];
    //     }
        
    //     // jumlahkan semua jumlah
    //     if (awan_tinggi.jumlah[i] !== "-") {
    //       selected.jumlah += +awan_tinggi.jumlah[i];
    //     }
    //     // ambil tinggi_dasar paling rendah
    //     if (awan_tinggi.tinggi_dasar[i] !== "" && awan_tinggi.tinggi_dasar[i] !== null && selected.tinggi_dasar > awan_tinggi.tinggi_dasar[i]) {
    //       selected.tinggi_dasar = +awan_tinggi.tinggi_dasar[i];
    //     }
    //   }
      
    //   if (selected.jumlah >= 5) {
    //     tempLayer.push({
    //       jenis: "" + selected.jenis,
    //       jumlah: "" + selected.jumlah,
    //       tinggi_dasar: selected.tinggi_dasar,
    //     });
    //   }
    //   console.log("tempLayer", tempLayer)
    //   this.cloud_layer_1_type_c = tempLayer[0]?.jenis || null;
    //   this.cloud_layer_1_height_m_hshs = tempLayer[0]?.tinggi_dasar || null;
    //   this.cloud_layer_1_amt_oktas_ns = tempLayer[0]?.jumlah || null;
    //   this.cloud_layer_2_type_c = tempLayer[1]?.jenis || null;
    //   this.cloud_layer_2_height_m_hshs = tempLayer[1]?.tinggi_dasar || null;
    //   this.cloud_layer_2_amt_oktas_ns = tempLayer[1]?.jumlah || null;
    //   this.cloud_layer_3_type_c = tempLayer[2]?.jenis || null;
    //   this.cloud_layer_3_height_m_hshs = tempLayer[2]?.tinggi_dasar || null;
    //   this.cloud_layer_3_amt_oktas_ns = tempLayer[2]?.jumlah || null;
    //   this.cloud_layer_4_type_c = tempLayer[3]?.jenis || null;
    //   this.cloud_layer_4_height_m_hshs = tempLayer[3]?.tinggi_dasar || null;
    //   this.cloud_layer_4_amt_oktas_ns = tempLayer[3]?.jumlah || null;
    // },

    // AN
    // Jika awan memiliki tinggi sama, maka dilihat oktas terbanyak, jika oktas sama maka lihat jenis awan
    // Jumlah awan menggunakan total awan pada tinggi awan yang sama
    // Syarat jika tinggi dasar sama maka :
      // 1. diambil C dengan oktas yang lebih banyak
      // 2. jika oktas sama, maka diambil sandi C yang lebih tinggi
      // 3. oktas dijumlahkan dari awan yang tinggi dasar sama
    // setCloudslayer() {
    //   // Tidak perlu lapisan awan
    //   if (this.input_data.cloud_cover_oktas_m == "9" 
    //     || this.input_data.cloud_cover_oktas_m == "0" 
    //     || this.input_data.cloud_cover_oktas_m == "/") {
    //     for (let i = 1; i <= 4; i++) {
    //       this[`cloud_layer_${i}_type_c`] = null;
    //       this[`cloud_layer_${i}_amt_oktas_ns`] = null;
    //       this[`cloud_layer_${i}_height_m_hshs`] = null;
    //     }
    //     return;
    //   }

    //   let tempLayer = [];
      
    //   // // Logic to filter awan yang sesuai
    //   // // Due to weird data structure so weed to specify n
    //   // // Awan Jumlah 9 dan / tidak diambil di lapisan, awan jumlah 9 hanya dibaca di cloud cover
    //   // const filter_awan = (awan, n) => {
    //   //   const result = []
    //   //   for (let i = 0; i < n; i++) {
    //   //     if (awan.jenis[i] != "" && awan.jenis[i] != "/" && awan.jenis[i] != null &&
    //   //         awan.jumlah[i] != "9" && awan.jumlah[i] != "/" && 
    //   //         awan.jumlah[i] != "" && awan.jumlah[i] != null )
    //   //         result.push({
    //   //           jenis: awan.jenis[i],
    //   //           jumlah: +awan.jumlah[i],
    //   //           tinggi_dasar: +awan.tinggi_dasar[i],
    //   //         })
    //   //   }
    //   //   return result
    //   // }

    //   // // Combine all clouds
    //   // let clouds = [...filter_awan(this.awan_rendah, 3), 
    //   //   ...filter_awan(this.awan_menengah, 2), 
    //   //   ...filter_awan(this.awan_tinggi, 2)]
    //   // // console.log('clouds', clouds);
    //   let clouds = get_available_cloud(this.awan_rendah, 
    //     this.awan_menengah, 
    //     this.awan_tinggi)

    //   // Takes all CB
    //   const cbs = clouds.filter(x => x.jenis.includes(9))
    //     .sort((a, b) => a.tinggi_dasar - b.tinggi_dasar)
    //   tempLayer = [...cbs]
      
    //   // console.log('cbs', cbs);
    //   // console.log('tempLayer', ...tempLayer);

    //   // Removes CB
    //   clouds = clouds.filter(x => !x.jenis.includes(9))
    //     .sort((a, b) => a.tinggi_dasar - b.tinggi_dasar)
      
    //   // Grouping awan yang punya ketinggian sama
    //   // https://stackoverflow.com/questions/14446511/most-efficient-method-to-groupby-on-an-array-of-objects
    //   const groupBy = function(xs, key) {
    //     return xs.reduce(function(rv, x) {
    //       (rv[x[key]] = rv[x[key]] || []).push(x);
    //       return rv;
    //     }, {});
    //   };
    //   const cloudsByAltitude = groupBy(clouds, "tinggi_dasar")
    //   console.log('cloudsByAltitude', cloudsByAltitude);

    //   // Syarat oktas utk awan yang diambil, keberadaan CB mengurangi slot oktas
    //   // e.g. 2 Cb -> maka oktas hanya 3 dan 5, 0 cb maka 1,3,5
    //   const oktas_rule = [1, 3, 5].slice(Math.max(0, cbs.length - 1), 3)
    //   // console.log('oktas_rule', oktas_rule, cbs)

    //   // Create layer
    //   for (const key in cloudsByAltitude) {
    //     if (oktas_rule.length <= 0) break;
        
    //     const total_oktas = cloudsByAltitude[key].reduce((total, cl) => {return total + cl.jumlah}, 0)
    //     console.log("total_oktas", total_oktas)
    //     if(total_oktas >= oktas_rule[0]) {
          
    //       // Ambil awan oktas terbesar (jk lebih dari 1)
    //       const max_oktas = Math.max(...cloudsByAltitude[key].map(cl => cl.jumlah))
    //       let selected_cloud = cloudsByAltitude[key].filter(cl => cl.jumlah == max_oktas)

    //       // Lebih dari 1 awan dengan oktas sama, ambil index yang terbesar
    //       if(selected_cloud.length > 1) {
    //         selected_cloud = selected_cloud.sort((a, b) => b.jenis - a.jenis)
    //       }

    //       tempLayer.push({
    //         jenis: selected_cloud[0]?.jenis,
    //         jumlah: total_oktas,
    //         tinggi_dasar: +cloudsByAltitude[key][0].tinggi_dasar,
    //       })
    //       oktas_rule.shift()
    //     }
    //   }
    //   // console.log('templayer', tempLayer)
     
    //   // console.log("tempLayer", tempLayer)
    //   for (let i = 1; i <= 4; i++) {
    //       this[`cloud_layer_${i}_type_c`] = tempLayer[i-1]?.jenis || null;
    //       this[`cloud_layer_${i}_amt_oktas_ns`] = tempLayer[i-1]?.jumlah || null;
    //       this[`cloud_layer_${i}_height_m_hshs`] = tempLayer[i-1]?.tinggi_dasar || null;
    //     }
    // },
    setCloudslayer() {
      // Tidak perlu lapisan awan
      if (this.input_data.cloud_cover_oktas_m == "9" 
        || this.input_data.cloud_cover_oktas_m == "0" 
        || this.input_data.cloud_cover_oktas_m == "/") {
        for (let i = 1; i <= 4; i++) {
          this[`cloud_layer_${i}_type_c`] = null;
          this[`cloud_layer_${i}_amt_oktas_ns`] = null;
          this[`cloud_layer_${i}_height_m_hshs`] = null;
        }
        return;
      }

      let tempLayer = [];
      
      let clouds = get_available_cloud(this.awan_rendah, 
        this.awan_menengah, 
        this.awan_tinggi)

      // Takes all CB
      const cbs = clouds.filter(x => x.jenis.includes(9))
        .sort((a, b) => a.tinggi_dasar - b.tinggi_dasar)
      tempLayer = [...cbs]
      
      // Removes CB
      clouds = clouds.filter(x => !x.jenis.includes(9))
        .sort((a, b) => a.tinggi_dasar - b.tinggi_dasar)
      
      // Grouping awan yang punya ketinggian sama
      // https://stackoverflow.com/questions/14446511/most-efficient-method-to-groupby-on-an-array-of-objects
      const groupBy = function(xs, key) {
        return xs.reduce(function(rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      };
      const cloudsByAltitude = groupBy(clouds, "tinggi_dasar")
      // console.log('cloudsByAltitude', cloudsByAltitude);

      // Syarat oktas utk awan yang diambil, keberadaan CB mengurangi slot oktas
      // e.g. 2 Cb -> maka oktas hanya 3 dan 5, 0 cb maka 1,3,5
      const oktas_rule = [1, 3, 5].slice(Math.max(0, cbs.length - 1), 3)
      // console.log('oktas_rule', oktas_rule, cbs)

      // Create layer
      for (const key in cloudsByAltitude) {
        if (oktas_rule.length <= 0) break;
        
        const total_oktas = cloudsByAltitude[key].reduce((total, cl) => {return total + cl.jumlah}, 0)
        // console.log("total_oktas", total_oktas)
        if(total_oktas >= oktas_rule[0]) {
          
          // Ambil awan oktas terbesar (jk lebih dari 1)
          const max_oktas = Math.max(...cloudsByAltitude[key].map(cl => cl.jumlah))
          let selected_cloud = cloudsByAltitude[key].filter(cl => cl.jumlah == max_oktas)

          // Lebih dari 1 awan dengan oktas sama, ambil index yang terbesar
          if(selected_cloud.length > 1) {
            selected_cloud = selected_cloud.sort((a, b) => b.jenis - a.jenis)
          }

          tempLayer.push({
            jenis: selected_cloud[0]?.jenis,
            jumlah: total_oktas,
            tinggi_dasar: +cloudsByAltitude[key][0].tinggi_dasar,
          })
        }
        oktas_rule.shift()
      }
      // console.log('templayer', tempLayer)
     
      // console.log("tempLayer", tempLayer)
      for (let i = 1; i <= 4; i++) {
          this[`cloud_layer_${i}_type_c`] = tempLayer[i-1]?.jenis || null;
          this[`cloud_layer_${i}_amt_oktas_ns`] = tempLayer[i-1]?.jumlah || null;
          this[`cloud_layer_${i}_height_m_hshs`] = tempLayer[i-1]?.tinggi_dasar || null;
        }
    },
    deriveDewPointTempAndRelativeHumidity() {
      let tw = this.input_data.temp_wetbulb_c;
      let td = this.input_data.temp_drybulb_c_tttttt;

      // if pressure_mode=derived, then station_pressure_mb is not available.
      // In that sitution, we take the QFE value, and pretend it is the station
      // pressure.

      let p = null;
      if (this.input_data.pressure_reading_mb) {
        p = this.input_data.pressure_reading_mb;
      }
      if (this.input_data.pressure_qff_mb_derived) {
        p = this.input_data.pressure_qff_mb_derived;
      }

      if (tw == null || td == null || p == null) {
        this.temp_dewpoint_c_tdtdtd = "";
        this.relative_humidity_pc = "";
        return;
      } else if (tw == 9999 || td == 9999 || p == 9999) {
        this.temp_dewpoint_c_tdtdtd = "9999";
        this.relative_humidity_pc = "9999";
        return;
      }

      // determine dew point and relative humidity. We use the formulas
      // given in WMO No 8, Guide to Meteorological Instruments and
      // methods of observation, Annex 4B, Formulae for the computation
      // of measures of humidity.

      let ewt = 6.112 * Math.exp((17.62 * td) / (243.12 + td));
      let ewtw = 6.112 * Math.exp((17.62 * tw) / (243.12 + tw));

      let fp = 1.0016 + 3.15 * Math.pow(10, -6) * p - 0.074 * Math.pow(p, -1);

      let etwpt = fp * ewt;
      let etwptw = fp * ewtw;

      let et = etwptw - 6.53 * Math.pow(10, -4) * (1 + 0.000944 * tw) * p * (td - tw);

      let dp = (243.12 * Math.log(et / (6.112 * fp))) / (17.62 - Math.log(et / (6.112 * fp)));

      let rh = (100 * et) / etwpt;

      if (dp) {
        this.temp_dewpoint_c_tdtdtd = Number(dp.toFixed(1));
      }
      if (rh) {
        this.relative_humidity_pc = Number(rh.toFixed());
      }
    },
    padZero(val, n) {
      return `${"0".repeat(n)}${val}`.substr(-n, n);
    },
    floorTo(val, n) {
      return Math.floor(val / n) * n;
    },
    sandi_awan_hs(tinggi) {
      let hshs = "";
      // pembulatan kebawah
      if (tinggi <= 1500) {
        tinggi = this.floorTo(tinggi, 30);
      } else if (tinggi <= 9000) {
        tinggi = this.floorTo(tinggi, 300);
      } else if (tinggi <= 21000) {
        tinggi = this.floorTo(tinggi, 1500);
      }
      // penyandian
      if (tinggi <= 1500) {
        hshs = this.padZero(tinggi / 30, 2);
      } else if (tinggi <= 9000) {
        hshs = this.padZero(50 + tinggi / 300, 2);
      } else if (tinggi <= 21000) {
        // AN kurang + 74
        hshs = this.padZero(74 + tinggi / 1500, 2);
      } else {
        hshs = "89";
      }
      return hshs;
    },

    encodeSandi() {
      let result = [];

      // seksi 0

      // pengenal sinop (AAXX)
      result.push("AAXX");

      // YYGGiw
      let YY = this.tanggal.substr(-2, 2);
      let GG = this.jam.substr(0, 2);
      let iw = this.input_data.wind_indicator_iw;
      result.push(YY + GG + iw); // AN kok ada z? + "Z");

      // WMO ID
      let t_wmoid = this.t_wmoid;
      result.push("\n"+t_wmoid);

      // seksi 1

      // iRixhVV

      let iR = this.rainfall_indicator_ir;
      let iX = this.input_data.weather_indicator_ix;
      // let awan_terendah = this.awan_rendah.tinggi_dasar[0]; // AN salah
      const tinggi_awan_terendah = get_lowest_cloud(this.awan_rendah, 
        this.awan_menengah, 
        this.awan_tinggi);
      let jarak_pandang = this.input_data.visibility_vv; // km
      // if (awan_terendah && jarak_pandang !== "" && jarak_pandang != 9999 && jarak_pandang != 8888) {
      
      let h = null;
      if (this.input_data.cloud_cover_oktas_m == "9" 
        || this.input_data.cloud_cover_oktas_m == "/"
        || this.input_data.cloud_cover_oktas_m == "0"
        || tinggi_awan_terendah == null) {
          if (this.input_data.cloud_cover_oktas_m == "0") {
            h = "9";
          } else {
            h = "/";
          }
      } else {
        if (tinggi_awan_terendah < 50) {
          h = "0";
        } else if (tinggi_awan_terendah < 100) {
          h = "1";
        } else if (tinggi_awan_terendah < 200) {
          h = "2";
        } else if (tinggi_awan_terendah < 300) {
          h = "3";
        } else if (tinggi_awan_terendah < 600) {
          h = "4";
        } else if (tinggi_awan_terendah < 1000) {
          h = "5";
        } else if (tinggi_awan_terendah < 1500) {
          h = "6";
        } else if (tinggi_awan_terendah < 2000) {
          h = "7";
        } else if (tinggi_awan_terendah < 2500) {
          h = "8";
        } else {
          h = "9";
        }
      }

      let VV = "";
      // pembulatan kebawah
      if (jarak_pandang <= 5) {
        jarak_pandang = this.floorTo(jarak_pandang * 10, 1) / 10;
      } else if (jarak_pandang <= 30) {
        jarak_pandang = this.floorTo(jarak_pandang, 1);
      } else if (jarak_pandang <= 70) {
        jarak_pandang = this.floorTo(jarak_pandang, 5);
      }
      // penyandian VV
      if (jarak_pandang <= 5) {
        VV = this.padZero(jarak_pandang * 10, 2);
      } else if (jarak_pandang <= 30) {
        VV = this.padZero(50 + jarak_pandang, 2);
      } else if (jarak_pandang <= 70) {
        VV = this.padZero(80 + (jarak_pandang - 30) / 5, 2);
      } else {
        VV = "89";
      }

      result.push("" + iR + iX + h + VV);
      // }

      // Nddff (00fff)
      let N = this.input_data.cloud_cover_oktas_m;
      let dd = this.input_data.wind_dir_deg_dd;
      let ff = this.input_data.wind_speed_ff;

      // if (N && dd && ff && dd != 9999 && ff != 9999) {
      //   dd = Math.round((dd + 360) / 10);
      //   if (dd > 36) dd -= 36;
      //   dd = this.padZero(dd, 2);

      //   let fff99 = "";
      //   if (ff > 99) {
      //     fff99 = ` 00${ff}`;
      //     ff = 99;
      //   }
      //   ff = this.padZero(ff, 2);

      //   result.push(N + dd + ff + fff99);
      // }

      // AN: dd ff seharusnya tidak bisa 9999 karena bisa dicover dengan manual
      if (GenHelper.isValidValue(N) && dd != 9999 && ff != 9999) {
        if (dd == 0) {
          dd == 0;
        } else {
          dd = Math.round((dd + 360) / 10);
          if (dd > 36) dd -= 36;
        }
        dd = this.padZero(dd, 2);

        let fff99 = "";
        if (ff > 99) {
          fff99 = ` 00${ff}`;
          ff = 99;
        } else if (ff == 0) {
          ff = 0;
        }

        ff = this.padZero(ff, 2);

        result.push(N + dd + ff + fff99);
      }

      // 1SnTTT
      let TTT = this.input_data.temp_drybulb_c_tttttt;
      if (GenHelper.isValidValue(TTT)) {
        TTT = TTT * 10;
        let Sn = "0";
        if (TTT < 0) {
          TTT = -TTT;
          Sn = "1";
        }
        TTT = this.padZero(Math.round(TTT), 3);
        result.push("1" + Sn + TTT);
      } else {
        result.push("1////");
      }

      // 2SnTdTdTd or 29UUU
      let TdTd = this.temp_dewpoint_c_tdtdtd;
      let UUU = this.relative_humidity_pc;
      if (GenHelper.isValidValue(TdTd)) {
        TdTd = TdTd * 10;
        let Sn = "0";
        if (TdTd < 0) {
          TdTd = -TdTd;
          Sn = "1";
        }
        TdTd = this.padZero(Math.round(TdTd), 3);
        result.push("2" + Sn + TdTd);
      } else if (GenHelper.isValidValue(UUU)) {
        UUU = this.padZero(Math.round(UUU), 3);
        result.push("29" + UUU);
      } else {
        result.push("2////");
      }

      // 3PoPoPoPo
      let PoPo = this.input_data.pressure_qfe_mb_derived;
      if (PoPo && PoPo != 9999) {
        PoPo = this.padZero(PoPo * 10, 4);
        result.push("3" + PoPo);
      } else {
        result.push("3////");
      }

      // 4PPPP
      let PPPP = this.input_data.pressure_qff_mb_derived;
      if (PPPP && PPPP != 9999) {
        PPPP = this.padZero(PPPP * 10, 4);
        result.push("4" + PPPP);
      } else {
        result.push("4////");
      }

      // 5appp
      if (is_3hourly_obs(this.jam)) {
        const appp = get_pressure_difference_and_tendency(
          this.input_data.pressure_qff_mb_derived,
          this.pressure_tend_1h_mb_www,
          this.pressure_tend_2h_mb_www,
          this.pressure_tend_3h_mb_www,
          this.t_operating_hours,
          this.t_start_hour,
          this.jam
        )

        result.push(appp)
      }

      // 6RRRt , untuk sesi 1
      const RRRtr_1 = get_RRRtr_section1(this.jam, 
        this.rainfall_jumlah_24h, 
        this.rainfall_jumlah_6h)
      if(RRRtr_1) {
        result.push(RRRtr_1);
      }

      // 7wwW1W2
      // Sandi sudah dihandle oleh ix
      // tidak dilaporkan : ww 0-3 w1w2 0-2 (ix=2) 
      // dilaporkan : w1w2 3-9 atau / (ix=1)
      let ww = this.input_data.present_weather_ww;
      let w1 = this.input_data.past_weather_w1;
      let w2 = this.input_data.past_weather_w2;
      if (ww !== "" && w1 !== "" && w2 !== "" && this.input_data.weather_indicator_ix == 1) {
        result.push("7" + this.padZero(ww, 2) + (w1 != "/"? this.padZero(w1, 1): w1) + (w2 != "/"? this.padZero(w2, 1): w2));
      }

      // 8NhCLCMCH
      if (!this.disabled.is_cloud_cover_block) {
        // let Nh = this.awan_rendah.NCL;
        // if (Nh == "" || Nh == "0" || Nh == "-" || Nh == "/") {
        //   Nh = this.awan_menengah.NCM;
        //   if (Nh == "" || Nh == "0" || Nh == "-" || Nh == "/") {
        //     Nh = "0";
        //   }
        // }
        const Nh = get_source_nh(this.awan_rendah.NCL, this.awan_menengah.NCM)

        // NCL/NCM 9 atau /
        if (Nh !== "9" && Nh !== "/") {
          let CL = this.awan_rendah.CL;
          let CM = this.awan_menengah.CM;
          let CH = this.awan_tinggi.CH;
          if (CL && CM && CH) {
            result.push("8" + Nh + CL + CM + CH);
          }
        }
      }

      // 9GGgg -> katanya gaada sandi ini
      // if (this.jam) {
      //   let GG = this.jam.split(":")[0];
      //   const NOW = new Date();
      //   const [, gg] = NOW.toLocaleTimeString([], {
      //     hour12: false,
      //     hour: "2-digit",
      //     minute: "2-digit",
      //   }).split(":");
      //   if (+gg > 10) {
      //     result.push("9" + GG + gg);
      //   }
      // }

      // seksi 3
      // (333) pengenal seksi 3
      result.push("\n  333");

      // 1SnTxTxTx
      let Tx = this.input_data.temp_max_c_txtxtx;
      // AN fix : tx bisa 0 sedangkan "if" tidak bisa
      if (GenHelper.isValidValue(Tx)) {
        let Sn = "0";
        if (Tx < 0) {
          Tx = -Tx;
          Sn = "1";
        }
        Tx = this.padZero(Math.round(Tx * 10), 3);
        result.push("1" + Sn + Tx);
      } else if (Tx == 9999){
        result.push("1////"); // To Be confirmed
      }

      // 2SnTnTnTn
      let Tn = this.input_data.temp_min_c_tntntn;
      // AN fix : tn bisa 0 sedangkan "if" tidak bisa
      if (GenHelper.isValidValue(Tn)) {
        let Sn = "0";
        if (Tn < 0) {
          Tn = -Tn;
          Sn = "1";
        }
        Tn = this.padZero(Math.round(Tn * 10), 3);
        result.push("2" + Sn + Tn);
      } else if (Tn == 9999){
        result.push("2////");  // To Be confirmed
      }

      // 5EEEiE
      let EEE = this.input_data.evaporation_24hours_mm_eee;
      let iE = this.input_data.evaporation_eq_indicator_ie;
      if (GenHelper.isValidValue(EEE, [9999, 8888, "9999", "8888"]) && 
        GenHelper.isValidValue(iE)) {
        EEE = this.padZero(Math.round(EEE * 10), 3);
        result.push("5" + EEE + iE);
      } else if(EEE == 9999 || EEE == 8888) {
        result.push("5///" + iE);
      }

      // 55SSS
      let SSS = this.input_data.sunshine_h_sss;
      if (GenHelper.isValidValue(SSS)) {
        SSS = this.padZero(Math.round(SSS * 10), 3);
        result.push("55" + SSS);
      } else if(SSS == 9999) {
        result.push("55///");
      }

      // 55508 5F24F24F24F24
      let F24 = this.input_data.solar_rad_24h_jcm2_f24;
      if (GenHelper.isValidValue(F24)) {
        F24 = this.padZero(Math.round(F24), 4);
        result.push("55508");
        result.push("5" + F24);
      } else if(F24 == 9999) {
        result.push("55508");
        result.push("5////");
      }

      // 56DLDMDH
      let DL = this.awan_rendah.arah[0];
      let DM = this.awan_menengah.arah[0];
      let DH = this.awan_tinggi.arah[0];
      DL = (DL == "/" || DL === "") ? 9 : DL
      DM = (DM == "/" || DM === "") ? 9 : DM
      DH = (DH == "/" || DH === "") ? 9 : DH

      /* tambahan bowo*/
      DL = (this.awan_rendah.CL === "0" ? "0" : DL)
      DM = (this.awan_menengah.CM === "0" ? "0" : DM)
      DH = (this.awan_tinggi.CH === "0" ? "0" : DH)

      // console.log('56DLDMDH', DL, DM, DH)
      if (GenHelper.isValidValue(DL) && 
        GenHelper.isValidValue(DM) && 
        GenHelper.isValidValue(DH)) {
        result.push("56" + DL + DM + DH);
      }

      // 57CDaec
      let awan_rendah = this.awan_rendah;
      for (let i = 0; i < 2; i++) {
        if (awan_rendah.jenis[i] == "8" || awan_rendah.jenis[i] == "9") {
          // if (awan_rendah.arah[i] != "-" && awan_rendah.sudut[i] != "-") {
          //   result.push("57" + awan_rendah.jenis[i] + awan_rendah.arah[i] + (awan_rendah.sudut[i] ? awan_rendah.sudut[i] : '/'));
          // }

          // AN - Aturan metbang terbaru
          // awan_rendah.sudut[i]  -> dicek karena ketika di clear jadi undefined bukan null
          // console.log("awan rendah", i, awan_rendah.sudut[i])
          if (awan_rendah.arah[i] !== "" && awan_rendah.arah[i] !== null
            && awan_rendah.sudut[i] !== "" && awan_rendah.sudut[i] !== null
            && awan_rendah.da[i] !== "" && awan_rendah.da[i] !== null
            && awan_rendah.sudut[i] !== undefined && awan_rendah.da[i] !== undefined) {
            result.push("57" + awan_rendah.jenis[i] + awan_rendah.da[i] + (awan_rendah.tinggi_puncak[i] ? awan_rendah.sudut[i] : '0'));
          }
        }
      }

      // (58P24P24P24) or (59P24P24P24)
      let difQFF24h = this.selisih_QFF_24h;
      if (difQFF24h || difQFF24h == 0) {
        if (this.jam === "00:00" || this.jam === "12:00") {
          let perubahan = difQFF24h;
          let N = "58"; // jika perubahan positif
          // if (perubahan < 0) {
          //   // jika perubahan negatif
          //   N = "59";
          //   perubahan = -perubahan;
          // }
          // perubahan = this.padZero(perubahan * 10, 3);
          // console.log("58P24P24P24", perubahan);

          // AN add 9999
          if (!this.pressure_tend_24h_mb_ppp || this.pressure_tend_24h_mb_ppp === 9999) {
            perubahan = "-"
          }

          if (perubahan != "-" && perubahan < 0) {
            // jika perubahan negatif
            N = "59";
            perubahan = -perubahan;
          }
          perubahan = perubahan != "-" ? this.padZero(perubahan * 10, 3) : "///";

          result.push(N + perubahan);
        }
      }

      // 6RRRtR (per 3 jam)
      const [RRRtr_3, sandi_tr_3] = get_RRRtr_section3(this.jam, 
                                      this.t_operating_hours, 
                                      this.t_start_hour,
                                      this.t_end_hour,
                                      this.input_data.rainfall_last_mm)
      this.sandi_tr = sandi_tr_3
      if(RRRtr_3) {
        result.push(RRRtr_3);
      }

      //// AN : baru dari wmo
      //// 7R24R24R24R24
      //if (this.jam === "00:00") {
      //  const rain24 = this.rainfall_jumlah_24h;
      //  let r24 = ''
      //  if(rain24 !== null && rain24 > 0) {
      //    if(rain24 == 9999) {
      //      r24 = "////";
      //    } else if(rain24 == 8888) {
      //      r24 = "9999";
      //    } else {
      //      r24 = this.padZero(+((+rain24).toFixed(1)) * 10, 4)
      //    }
      //    result.push("7" + r24);
      //  }
      //}

      if(this.input_data.cloud_cover_oktas_m != "/" || this.input_data.cloud_cover_oktas_m != "0") {

        // 8NsChshs
        if(this.input_data.cloud_cover_oktas_m == "9") {
          result.push("89/" + this.sandi_awan_hs(this.input_data.cloud_vertical_vis));
        } else {
          for (let i = 1; i <= 4; i++) {
            let type_c = this[`cloud_layer_${i}_type_c`];
            let oktas = this[`cloud_layer_${i}_amt_oktas_ns`];
            let height = this[`cloud_layer_${i}_height_m_hshs`];

            // AN edit : cirrus tidak terbawa karena jenis = 0
            if ((type_c || type_c == '0') && oktas != "0") {
              result.push("8" + oktas + type_c + this.sandi_awan_hs(height));
            }
          }
        }
        
        // 80Chshs
        for (let i = 0; i < 2; i++) {
          // AN - aturan metbang terbaru
          if (awan_rendah.tinggi_puncak[i] && (awan_rendah.jenis[i] == "8" || awan_rendah.jenis[i] == "9") 
              && (awan_rendah.sudut[i] !== "-" && awan_rendah.sudut[i] != "0" && !awan_rendah.sudut[i] !== true)) {
              result.push("80" + awan_rendah.jenis[i] + this.sandi_awan_hs(awan_rendah.tinggi_puncak[i]));
          }
          // else if (awan_rendah.jenis[i] && (awan_rendah.tinggi_puncak[i] === null || awan_rendah.tinggi_puncak[i] === "")) {
          //   result.push("80"+ awan_rendah.jenis[i] +"//");
          // }
        }
      }

      this.sandi = result.join(" ") + "=";

      let header = ""
      
      let dataid = this.t_data_id.split("T");
      let jam = dataid[1];
      let date = dataid[0].split("-")
      
      if (jam == "00" || jam == "06" || jam == "12" || jam == "18") {
        header = this.gts_main_header + " " +date[2] + jam +"00"
      }
      else if ( jam == "03" || jam == "09" || jam == "15" || jam == "21"){
        header = this.gts_intermediate_header + " " +date[2] + jam +"00"
      }
      else if ( jam == "01" || jam == "02" || jam == "04" || jam == "05" || jam == "07" || jam == "08" || jam == "10" || jam == "11" 
              || jam == "13" || jam == "14" || jam == "16" || jam == "17" || jam == "19" || jam == "20" || jam == "22" || jam == "23"){
        header = this.gts_non_standard_hour_header + " " +date[2] + jam +"00"
      }

      this.sandi_with_header = header + "\n" + this.sandi
    },
    setCuacaIX() {
      if (this.input_data.present_weather_ww !== "" && this.input_data.past_weather_w1 !== "" && this.input_data.past_weather_w2 !== "") {
        const WW_bermakna = this.cuaca_bermakna_WW.indexOf(+this.input_data.present_weather_ww);
        const W1_bermakna = this.cuaca_bermakna.indexOf(+this.input_data.past_weather_w1);
        const W2_bermakna = this.cuaca_bermakna.indexOf(+this.input_data.past_weather_w2);
        if (WW_bermakna !== -1 
          || W1_bermakna !== -1 
          || W2_bermakna !== -1 
          || this.input_data.past_weather_w1 == "/"
          || this.input_data.past_weather_w2 == "/") {
          this.input_data.weather_indicator_ix = 1;
        } else {
          this.input_data.weather_indicator_ix = 2;
        }
      }
    },
    hitung_QFF_QFE() {
      // console.log('hitung_QFF_QFE')
      if (this.input_data.pressure_temp_c && this.input_data.pressure_reading_mb) {
        if (!this.QFF_QFE || Object.keys(this.QFF_QFE).length === 0) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Tidak ada data referensi QFF dan QFE. Periksa data referensi QFF dan QFE pada 'QC Configuration'",
              icon: "BellIcon",
              variant: "warning",
            },
          });
          return;
        }
        // console.log('hitung_QFF_QFE', this.input_data.pressure_temp_c, this.input_data.pressure_reading_mb)
        let derajat = Math.round(this.input_data.pressure_temp_c / 0.5) * 0.5;

        // this.koreksi_pressure_qfe_mb_derived = this.QFF_QFE?.[derajat]?.QFE || 0;
        this.koreksi_pressure_qfe_mb_derived = this.QFF_QFE?.[derajat]?.pc_qfe || 0;
        let hasil_QFE = this.input_data.pressure_reading_mb + this.koreksi_pressure_qfe_mb_derived;
        this.input_data.pressure_qfe_mb_derived = parseFloat(hasil_QFE.toFixed(1));

        // this.koreksi_pressure_qff_mb_derived = this.QFF_QFE?.[derajat]?.QFF || 0;
        this.koreksi_pressure_qff_mb_derived = this.QFF_QFE?.[derajat]?.pc_qff || 0;
        let hasil_QFF = this.input_data.pressure_reading_mb + this.koreksi_pressure_qff_mb_derived;
        this.input_data.pressure_qff_mb_derived = parseFloat(hasil_QFF.toFixed(1));
      }
    },

    // Handling disable/enable cloud layer based on cloud cover
    cloud_cover_handler(value) {
      if (value == "0" || value == "9" || value == "/") {
        this.resetCloud();
        if (value == "0") {
          this.awan_rendah.CL = "0";
          this.awan_menengah.CM = "0";
          this.awan_tinggi.CH = "0";
          this.awan_rendah.NCL = "0";
          this.awan_menengah.NCM = "0";
          this.awan_tinggi.NCH = "0";
          this.awan_rendah.arah[0] = 0;
          this.awan_menengah.arah[0] = 0;
          this.awan_tinggi.arah[0] = 0;
          this.awan_rendah.jumlah[0] = "0";
          this.awan_menengah.jumlah[0] = "0";
          this.awan_tinggi.jumlah[0] = "0";
        } else if (value == "9") {
          this.awan_rendah.CL = "/";
          this.awan_menengah.CM = "/";
          this.awan_tinggi.CH = "/";
          this.awan_rendah.NCL = "9";
          this.awan_menengah.NCM = "9";
          this.awan_tinggi.NCH = "9";
          this.awan_rendah.jenis[0] = "/";
          this.awan_menengah.jenis[0] = "/";
          this.awan_tinggi.jenis[0] = "/";
          this.awan_rendah.jumlah[0] = "9";
          this.awan_menengah.jumlah[0] = "9";
          this.awan_tinggi.jumlah[0] = "9";
          this.awan_rendah.arah[0] = 9;
          this.awan_menengah.arah[0] = 9;
          this.awan_tinggi.arah[0] = 9;
        } else {
          this.awan_rendah.CL = "/";
          this.awan_menengah.CM = "/";
          this.awan_tinggi.CH = "/";
          this.awan_rendah.NCL = "/";
          this.awan_menengah.NCM = "/";
          this.awan_tinggi.NCH = "/";
          this.awan_rendah.jenis[0] = "/";
          this.awan_menengah.jenis[0] = "/";
          this.awan_tinggi.jenis[0] = "/";
          this.awan_rendah.jumlah[0] = "/";
          this.awan_menengah.jumlah[0] = "/";
          this.awan_tinggi.jumlah[0] = "/";
          this.awan_rendah.arah[0] = 9;
          this.awan_menengah.arah[0] = 9;
          this.awan_tinggi.arah[0] = 9;
        }
      }
      
      if(value != "9") {
        this.input_data.cloud_vertical_vis = ""
      }
    },

    sendGtsMessage() {
      this.sandi_gts_message = this.sandi_with_header
      this.$refs['send-gts-message'].show()
    }, 

    onCancel() {
      this.$refs['send-gts-message'].hide()
    },

    checkAndSanitizeSandi(value) {
      if (value) {
				const clean_sandi = general_helper.removeLeadingTrailingSpaces(value)
				if (!clean_sandi) { 
          return [false, value]
        }

				let vsandi = clean_sandi.split(/[,\s]+/)
				if (vsandi.length > 3) {
					for(let i = 0; i < vsandi.length; i++) {
						if (vsandi[i] == '') {
							vsandi.splice(i, 1)
							if (i >= 0) {
								i = (i-1)
							}
						}
						else {
							if (vsandi[i].indexOf('\n') > -1) {
								vsandi[i] = String(vsandi[i]).replace('\n', ' ')
							}
						}
					}
          
          // Get configured header
          const dataid = this.t_data_id.split("T");
          const jam = dataid[1];
          let configured_header = null
          if (jam == "00" || jam == "06" || jam == "12" || jam == "18") {
            configured_header = this.gts_main_header
          } else if ( jam == "03" || jam == "09" || jam == "15" || jam == "21"){
            configured_header = this.gts_intermediate_header
          } else if ( jam == "01" || jam == "02" || jam == "04" || jam == "05" || jam == "07" || jam == "08" || jam == "10" || jam == "11" 
                  || jam == "13" || jam == "14" || jam == "16" || jam == "17" || jam == "19" || jam == "20" || jam == "22" || jam == "23"){
            configured_header = this.gts_non_standard_hour_header
          }

					const ttaaii = vsandi[0]
					const cccc = vsandi[1]
					const date = vsandi[2]
					// const dd = date.charAt(0)+date.charAt(1)
					// const hh = date.charAt(2)+date.charAt(3)
					// const mm = date.charAt(4)+date.charAt(5)

					// Not valid header
					let error_text = ''
					if (ttaaii?.length != 6 
						|| cccc?.length != 4 
						|| date?.length != 6
						|| !general_helper.isValidTTAAiiString(ttaaii)
						|| !general_helper.isValidCCCCString(cccc)
						|| !general_helper.isValidYYGGggString(date)) {
						error_text = "Mohon sesuaikan format header TTAAii CCCC YYGGgg"
					} else if(`${ttaaii} ${cccc}` != configured_header) {
						error_text = `Header yang digunakan tidak sesuai konfigurasi metadata ${configured_header}`
					} 
          // else if(!general_helper.isValidDateYYGGgg(date)) {
					// 	error_text = `Mohon sesuaikan format header YYGGgg tidak terlalu melampaui waktu UTC sekarang ${new Date().toISOString()}`
					// }

					// Error found
					if (error_text != '') {
						this.$toast({
							component: ToastificationContent,
							props: {
								title: "Sandi tidak valid",
								text: error_text,
								icon: "BellIcon",
								variant: "danger"
							},
						});
						return [false, value]
					}
          return [true, clean_sandi]
          
				} else {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: "Sandi tidak valid",
							text: "Mohon sesuaikan format sandi sesuai aturan sandi terkait",
							icon: "BellIcon",
							variant: "danger",
						},
					});
          return [false, value]
				}
			}
    },

    onSendBmkgSwitching() {
      this.submitSendBmkgSwitching()
    },

    async submitSendBmkgSwitching() {

			this.showLoading = true
      const [status, sandi] = this.checkAndSanitizeSandi(this.sandi_gts_message)
      if (!status) {
        return
      }
      this.sandi_gts_message = sandi

      // this.tanggal.substr(-2, 2);
      // let GG = this.jam.substr(0, 2);
			// let id = "1" + this.t_wmoid + this.tanggal.toString().replace(/-/g,'') + this.jam.toString().replace(/:/g,'')
      let mdate = new Date()
      let id = ""+
              this.padZero(1, 2) + 
              this.t_wmoid + mdate.getFullYear() +
              this.padZero((mdate.getMonth()+1),2) +
              this.padZero(mdate.getDate(),2) +
              this.padZero(mdate.getHours(),2) +
              this.padZero(mdate.getMinutes(),2) +
              this.padZero(mdate.getSeconds(),2)
      // console.log("submitSendBmkgSwitching - id", id, this.t_station)
			
			let tmp_sandi = this.sandi_gts_message.split(/[,\s]+/)
			const post_data = {
				"@type": "GTSMessage", 
				"id": id,
				"type_message": 1,
				"sandi_gts": this.sandi_gts_message,
				// "timestamp_data": this.tanggal + "T" + this.jam,
        "timestamp_sent_data": mdate.getUTCFullYear() + "-" + this.padZero(mdate.getUTCMonth() + 1, 2) + "-" + 
          this.padZero(mdate.getUTCDate(), 2) +  "T" + this.padZero(mdate.getUTCHours(), 2) + ":" + 
          this.padZero(mdate.getUTCMinutes(), 2) + ":" + this.padZero(mdate.getUTCSeconds(), 2),
				"timestamp_data": this.tanggal + "T" + this.jam,
				"wmoid": this.t_wmoid,
				"ttaaii": tmp_sandi.length > 0 ? tmp_sandi[0] : "",
				"cccc": tmp_sandi.length > 1 ? tmp_sandi[1] : ""
			}

			let url_gts = Helper.getBaseUrl() + this.t_station.path + "/gts";
			// console.log('post_data', post_data, url_gts)
			await axios
				.post(url_gts, post_data, Helper.getConfig())
				.then((response) => {
					if (response.status == 201 || response.status == 200 || response.status == 204) {
						this.$swal({
              title: "Send GTS Messages",
              text: "Input Data GTS Message Berhasil",
							icon: "success",
							customClass: {
							confirmButton: "btn btn-primary",
							},
							buttonsStyling: false,
						});
						this.showLoading = false;
						this.$refs['send-gts-message'].hide()
					}
          this.showLoading = false
				})
				.catch((error) => {
					this.showLoading = false;
          this.$swal({
						title: "Send GTS Messages",
						text: "Input Data GTS Message Tidak Berhasil",
						icon: "error",
						customClass: {
						confirmButton: "btn btn-primary",
						},
						buttonsStyling: false,
					});
					// // error_handler(this, error, "Sinoptik", "Input Data Error");
          // // console.log('error', error, error.response.status, )
          // if (error.response.status == 409) {
          //   // this.updateFormComfirm(id, post_data)
          // }
          // else {
            
          // }
				});

			// this.clearData()
			// this.showLoading = false
		},

		async updateFormComfirm(mid, post) {
			// console.log('submitFormComfirm', this.t_tanggal, this.t_station, this.t_type_message, this.t_jam, this.t_menit)
			let id = mid //this.t_type_message.value + this.t_station.value + this.t_tanggal.toString().replace(/-/g,'') + this.t_jam + this.t_menit
			// let date = new Date()
			// let tmp_sandi = this.sandi.split(" ")
			const post_data = post
      // {
			// 	"@type": "GTSMessage",
			// 	"sandi_gts": this.sandi_gts_message,
			// 	"ttaaii": post.ttaaii,
			// 	"cccc": post.cccc
			// }
			

			let url_gts = Helper.getBaseUrl() + this.t_station.path + "/gts/" +id;
			// console.log('updateFormComfirm - post_data', post_data, url_gts)
			this.showLoading = true
			await axios
				.patch(url_gts, post_data, Helper.getConfig())
				.then((response) => {
					if (response.status == 201 || response.status == 200 || response.status == 204) {
						this.$swal({
							title: "Update Data GTS Messages Berhasil",
							text: "Update Data GTS Message",
							icon: "success",
							customClass: {
							confirmButton: "btn btn-primary",
							},
							buttonsStyling: false,
						});
						this.showLoading = false;
						// this.clearData();
            this.$refs['send-gts-message'].hide()
					}
				})
				.catch((error) => {
					this.showLoading = false;
					error_handler(this, error, "Sinoptik", "Update Data Error");
				});

			// this.clearData()
			// this.showLoading = false
		},

  },
  watch: {
    // AN edit : optimize fetch
    async t_station(value) {
      if (value?.value) {
        this.showLoading = true;
  
        this.t_station_id = value.value;
        this.t_wmoid = value.wmoid;
        this.fetching = true

        // Digi
        const idstationProm = awsdigi.getIdStationDigi(this.t_wmoid)
        
        this.t_observer = null;
        const [sincollect, t_operating_hours, t_start_hour, 
          t_end_hour, t_mid_start_hour, t_mid_end_hour, t_barometer_air_raksa, t_barometer_digital, 
          first_date_enable, fdate, t_observer_opt, ref_data] 
            = await Promise.all([metadata.getPath("SinoptikCollect", this.t_station.value, this.t_station.path),
          metadata.getStationOperatingHours(this.t_station.path),
          metadata.getStationStartHours(this.t_station.path),
          metadata.getStationEndHours(this.t_station.path),
          metadata.getStationMidStartHours(this.t_station.path),
          metadata.getStationMidEndHours(this.t_station.path),
          metadata.getBarometerAirRaksa(this.t_station.path),
          metadata.getBarometerDigital(this.t_station.path),
          metadata.getEntryFirstEnable(this.t_station.path),
          metadata.getEntryFirstDate(this.t_station.path),
          metadata.getObserverStation(this.t_station_id),
          metadata.getReferensiData(this.t_station.path)])
        const data = [...sincollect.data]
        this.t_operating_hours = t_operating_hours;
        this.t_start_hour = t_start_hour;
        this.t_end_hour = t_end_hour;
        this.t_mid_start_hour = t_mid_start_hour;
        this.t_mid_end_hour = t_mid_end_hour;
        this.t_barometer_air_raksa = t_barometer_air_raksa;
        this.t_barometer_digital = t_barometer_digital;
        this.t_observer_opt = t_observer_opt;
        // console.log('t_start_hour', t_start_hour, 't_end_hour', t_end_hour)

        // baca url query
        if (this.$route.query.observer_id) {
          this.t_observer = this.$route.query.observer_id;
        }

        if (first_date_enable) {
          // let fdate = await metadata.getEntryFirstDate(this.t_station.path);
          this.t_entry_first_date = !fdate || fdate == {} ? null : fdate;
        } else {
          this.t_entry_first_date = null;
        }

        this.t_path = data[0].path;
        
        this.setQCData(ref_data);

        try {
          const resdigi = await idstationProm;
          if(resdigi.data.id_station) {
            this.hasDigi = true;
          } else {
            this.hasDigi = false
          }
        } catch {
          this.hasDigi = false
        }

        this.fetching = false
        this.fetchData();
        
        this.showLoading = false;
      } else {
        this.t_station_id = "";
        this.t_wmoid = "";
        this.t_barometer_air_raksa = true;
        this.t_barometer_digital = false;
        this.t_entry_first_date = null;
        this.resetForm();
      }
    },

    // async t_station(value) {
    //   if (value?.value) {
    //     this.t_station_id = value.value;
    //     this.t_wmoid = value.wmoid;
    //     this.fetching = true
    //     const { data } = await metadata.getPath("SinoptikCollect", this.t_station.value, this.t_station.path);
    //     this.t_operating_hours = await metadata.getStationOperatingHours(this.t_station.path);
    //     this.t_start_hour = await metadata.getStationStartHours(this.t_station.path);
    //     this.t_end_hour = await metadata.getStationEndHours(this.t_station.path);
    //     this.t_mid_start_hour = await metadata.getStationMidStartHours(this.t_station.path);
    //     this.t_mid_end_hour = await metadata.getStationMidEndHours(this.t_station.path);
    //     this.t_barometer_air_raksa = await metadata.getBarometerAirRaksa(this.t_station.path);
    //     this.t_barometer_digital = await metadata.getBarometerDigital(this.t_station.path);

    //     let first_date_enable = await metadata.getEntryFirstEnable(this.t_station.path);
    //     if (first_date_enable) {
    //       let fdate = await metadata.getEntryFirstDate(this.t_station.path);
    //       this.t_entry_first_date = !fdate || fdate == {} ? null : fdate;
    //       // console.log('t_entry_first_date', this.t_entry_first_date, fdate, this.t_barometer_digital, first_date_enable)
    //     } else {
    //       // console.log('first_date_enable', first_date_enable)
    //       this.t_entry_first_date = null;
    //     }

    //     this.t_path = data[0].path;

    //     this.t_observer = null;
    //     await this.listObserver();
    //     // baca url query
    //     if (this.$route.query.observer_id) {
    //       this.t_observer = this.$route.query.observer_id;
    //     }
    //     await this.setQCData();
    //     this.fetching = false
    //     this.fetchData();
    //   } else {
    //     this.t_station_id = "";
    //     this.t_wmoid = "";
    //     this.t_barometer_air_raksa = true;
    //     this.t_barometer_digital = false;
    //     this.t_entry_first_date = null;
    //     this.resetForm();
    //   }
    // },
    "input_data.pressure_qff_mb_derived"() {
      this.checkValidate({ target: { value: this.input_data.pressure_qff_mb_derived } }, "pressure_qff_mb_derived");
    },
    "input_data.pressure_qfe_mb_derived"() {
      this.checkValidate({ target: { value: this.input_data.pressure_qfe_mb_derived } }, "pressure_qfe_mb_derived");
    },
    "input_data.wind_dir_deg_dd"() {
      this.checkValidate({ target: { value: this.input_data.wind_speed_ff } }, "wind_speed_ff");
      this.checkValidate({ target: { value: this.input_data.wind_dir_deg_dd } }, "wind_dir_deg_dd");
    },
    "input_data.wind_speed_ff"() {
      this.checkValidate({ target: { value: this.input_data.wind_dir_deg_dd } }, "wind_dir_deg_dd");
      this.checkValidate({ target: { value: this.input_data.wind_speed_ff } }, "wind_speed_ff");
    },
    "input_data.visibility_vv"() {
      this.checkValidate({ target: { value: this.input_data.visibility_vv } }, "visibility_vv");
      // AN karena VV memvalidate cloud cover jg
      this.checkValidate({ target: { value: this.input_data.cloud_cover_oktas_m } }, "cloud_cover_oktas_m");
    },
    "input_data.present_weather_ww"() {
      this.deriveDewPointTempAndRelativeHumidity();
      this.checkValidate({ target: { value: this.input_data.present_weather_ww } }, "present_weather_ww");
      this.checkValidate({ target: { value: this.temp_dewpoint_c_tdtdtd } }, "temp_dewpoint_c_tdtdtd");
      this.setCuacaIX();
    },
    "input_data.past_weather_w1"() {
      this.checkValidate({ target: { value: this.input_data.past_weather_w2 } }, "past_weather_w2");
      this.checkValidate({ target: { value: this.input_data.past_weather_w1 } }, "past_weather_w1");
      this.setCuacaIX();
    },
    "input_data.past_weather_w2"() {
      this.checkValidate({ target: { value: this.input_data.past_weather_w1 } }, "past_weather_w1");
      this.checkValidate({ target: { value: this.input_data.past_weather_w2 } }, "past_weather_w2");
      this.setCuacaIX();
    },
    "input_data.temp_drybulb_c_tttttt"() {
      this.deriveDewPointTempAndRelativeHumidity();
      this.checkValidate({ target: { value: this.input_data.present_weather_ww } }, "present_weather_ww");
      this.checkValidate({ target: { value: this.input_data.temp_drybulb_c_tttttt } }, "temp_drybulb_c_tttttt");
      this.checkValidate({ target: { value: this.input_data.temp_wetbulb_c } }, "temp_wetbulb_c");
      this.checkValidate({ target: { value: this.input_data.temp_max_c_txtxtx } }, "temp_max_c_txtxtx");
      this.checkValidate({ target: { value: this.input_data.temp_min_c_tntntn } }, "temp_min_c_tntntn");
    },
    "input_data.temp_wetbulb_c"() {
      this.deriveDewPointTempAndRelativeHumidity();
      this.checkValidate({ target: { value: this.input_data.present_weather_ww } }, "present_weather_ww");
      this.checkValidate({ target: { value: this.input_data.temp_drybulb_c_tttttt } }, "temp_drybulb_c_tttttt");
      this.checkValidate({ target: { value: this.input_data.temp_wetbulb_c } }, "temp_wetbulb_c");
    },
    "input_data.temp_max_c_txtxtx"() {
      this.checkValidate({ target: { value: this.input_data.temp_drybulb_c_tttttt } }, "temp_drybulb_c_tttttt");
      this.checkValidate({ target: { value: this.input_data.temp_max_c_txtxtx } }, "temp_max_c_txtxtx");
      this.checkValidate({ target: { value: this.input_data.temp_min_c_tntntn } }, "temp_min_c_tntntn");
    },
    "input_data.temp_min_c_tntntn"() {
      this.checkValidate({ target: { value: this.input_data.temp_drybulb_c_tttttt } }, "temp_drybulb_c_tttttt");
      this.checkValidate({ target: { value: this.input_data.temp_max_c_txtxtx } }, "temp_max_c_txtxtx");
      this.checkValidate({ target: { value: this.input_data.temp_min_c_tntntn } }, "temp_min_c_tntntn");
    },
    "input_data.cloud_cover_oktas_m"() { 
      this.checkValidate({ target: { value: this.input_data.present_weather_ww } }, "present_weather_ww");
      this.checkValidate({ target: { value: this.input_data.cloud_cover_oktas_m } }, "cloud_cover_oktas_m");
      this.checkValidate({ target: { value: this.awan_rendah.CL } }, "cloud_low_type_cl");
      this.checkValidate({ target: { value: this.awan_rendah.NCL } }, "cloud_low_cover_oktas");
      this.checkValidate({ target: { value: this.awan_menengah.CM } }, "cloud_med_type_cm");
      this.checkValidate({ target: { value: this.awan_menengah.NCM } }, "cloud_med_cover_oktas");
      this.checkValidate({ target: { value: this.awan_tinggi.CH } }, "cloud_high_type_ch");
      this.checkValidate({ target: { value: this.awan_tinggi.NCH } }, "cloud_high_cover_oktas");
      this.cloud_cover_handler(this.input_data.cloud_cover_oktas_m);
    },
    "awan_rendah.cloud_2"(value) {
      if (!value) {
        this.awan_rendah.jenis[1] = "";
        this.awan_rendah.jumlah[1] = "";
        this.awan_rendah.tinggi_dasar[1] = "";
        this.awan_rendah.tinggi_puncak[1] = "";
        (this.awan_rendah.arah[1] = null), //"";
          (this.awan_rendah.sudut[1] = "");
      }
    },
    "awan_rendah.cloud_3"(value) {
      if (!value) {
        this.awan_rendah.jenis[2] = "";
        this.awan_rendah.jumlah[2] = "";
        this.awan_rendah.tinggi_dasar[2] = "";
        this.awan_rendah.arah[2] = "";
      }
    },
    "awan_rendah.CL"(value) {
      if (value === "0") {
        this.awan_rendah.NCL = value;
        this.awan_rendah.jenis[0] = "";
        this.awan_rendah.jumlah[0] = "0";
        this.awan_rendah.tinggi_dasar[0] = "";
        this.awan_rendah.tinggi_puncak[0] = "";
        this.awan_rendah.arah[0] = 0;
        this.awan_rendah.sudut[0] = "";
        this.awan_rendah.cloud_2 = false;
        this.awan_rendah.cloud_3 = false;
      } else if (value === "/") {
        if (this.input_data.cloud_cover_oktas_m == '9') {
          this.awan_rendah.NCL = '9';
          this.awan_rendah.jumlah[0] = '9';
        } else {
          this.awan_rendah.NCL = value;
          this.awan_rendah.jumlah[0] = value;
        }
        this.awan_rendah.jenis[0] = value;
        this.awan_rendah.tinggi_dasar[0] = "";
        this.awan_rendah.tinggi_puncak[0] = "";
        this.awan_rendah.arah[0] = 9;
        this.awan_rendah.sudut[0] = "";
        this.awan_rendah.cloud_2 = false;
        this.awan_rendah.cloud_3 = false;
      }
      this.checkValidate({ target: { value: this.input_data.cloud_cover_oktas_m } }, "cloud_cover_oktas_m");
      this.checkValidate({ target: { value: this.awan_rendah.CL } }, "cloud_low_type_cl");
    },
    "awan_rendah.NCL"() {
      this.checkValidate({ target: { value: this.input_data.cloud_cover_oktas_m } }, "cloud_cover_oktas_m");
      this.checkValidate({ target: { value: this.awan_rendah.NCL } }, "cloud_low_cover_oktas");
    },
    "awan_rendah.tinggi_dasar": {
      deep: true,
      handler: function (after, before) {
        if (after) {
          if (after[0] != null) {
            this.checkValidate({ target: { value: after[0] } }, "cloud_low_base_1");
          }
          if (after[1] != null) {
            this.checkValidate({ target: { value: after[1] } }, "cloud_low_base_2");
          }
          if (after[2] != null) {
            this.checkValidate({ target: { value: after[2] } }, "cloud_low_base_3");
          }
        }
      },
    },
    "awan_rendah.tinggi_puncak": {
      deep: true,
      handler: function (after, before) {
        if (after) {
          if (after[0] != null) {
            this.checkValidate({ target: { value: after[0] } }, "cloud_low_peak_1");
            this.checkValidate({ target: { value: 0 } }, "cloud_elevation_1_angle_ec");
          }
          if (after[1] != null) {
            // this.checkValidate({ target: { value: after[0] } }, "cloud_low_peak_2"); // AN should be after[1]
            this.checkValidate({ target: { value: after[1] } }, "cloud_low_peak_2");
            this.checkValidate({ target: { value: 0 } }, "cloud_elevation_2_angle_ec");
          }
        }
      },
    },
    "awan_rendah.sudut": {
      deep: true,
      handler: function (after, before) {
        if (after) {
          // AN karena after sudut bisa undefined (clear)
          // if (after[0] != null) {
            this.checkValidate({ target: { value: after[0] } }, "cloud_elevation_1_angle_ec");
          // }
          // if (after[1] != null) {
            this.checkValidate({ target: { value: after[1] } }, "cloud_elevation_2_angle_ec");
          // }
        }
      },
    },
    "awan_menengah.cloud_2"(value) {
      if (!value) {
        this.awan_menengah.jenis[1] = "";
        this.awan_menengah.jumlah[1] = "";
        this.awan_menengah.tinggi_dasar[1] = "";
        this.awan_menengah.arah[1] = "";
      }
    },
    "awan_menengah.CM"(value) {
      if (value === "0") {
        this.awan_menengah.NCM = value;
        this.awan_menengah.jenis[0] = "";
        this.awan_menengah.jumlah[0] = "0";
        this.awan_menengah.tinggi_dasar[0] = "";
        this.awan_menengah.arah[0] = 0;
        this.awan_menengah.cloud_2 = false;
      } else if (value === "/") {
         if (this.input_data.cloud_cover_oktas_m == '9') {
          this.awan_menengah.NCM = '9';
          this.awan_menengah.jumlah[0] = '9';
        } else {
          this.awan_menengah.NCM = value;
          this.awan_menengah.jumlah[0] = value;
        }

        this.awan_menengah.jenis[0] = value;
        this.awan_menengah.tinggi_dasar[0] = "";
        this.awan_menengah.arah[0] = 9;
        this.awan_menengah.cloud_2 = false;
      }
      this.checkValidate({ target: { value: this.input_data.cloud_cover_oktas_m } }, "cloud_cover_oktas_m");
      this.checkValidate({ target: { value: this.awan_menengah.CM } }, "cloud_med_type_cm");
    },
    "awan_menengah.NCM"() {
      this.checkValidate({ target: { value: this.input_data.cloud_cover_oktas_m } }, "cloud_cover_oktas_m");
      this.checkValidate({ target: { value: this.awan_menengah.NCM } }, "cloud_med_cover_oktas");
    },
    "awan_menengah.tinggi_dasar": {
      deep: true,
      handler: function (after, before) {
        if (after) {
          if (after[0] != null) {
            this.checkValidate({ target: { value: after[0] } }, "cloud_med_base_1");
          }
          if (after[1] != null) {
            this.checkValidate({ target: { value: after[1] } }, "cloud_med_base_2");
          }
        }
      },
    },
    "awan_tinggi.cloud_2"(value) {
      if (!value) {
        this.awan_tinggi.jenis[1] = "";
        this.awan_tinggi.jumlah[1] = "";
        this.awan_tinggi.tinggi_dasar[1] = "";
        this.awan_tinggi.arah[1] = "";
      }
    },
    "awan_tinggi.CH"(value) {
      if (value === "0") {
        this.awan_tinggi.NCH = value;
        this.awan_tinggi.jenis[0] = "";
        this.awan_tinggi.jumlah[0] = "0";
        this.awan_tinggi.tinggi_dasar[0] = "";
        this.awan_tinggi.arah[0] = 0;
        this.awan_tinggi.cloud_2 = false;
      } else if (value === "/") {
         if (this.input_data.cloud_cover_oktas_m == '9') {
          this.awan_tinggi.NCH = '9';
          this.awan_tinggi.jumlah[0] = '9';
        } else {
          this.awan_tinggi.NCH = value;
          this.awan_tinggi.jumlah[0] = value;
        }

        this.awan_tinggi.jenis[0] = value;
        this.awan_tinggi.tinggi_dasar[0] = "";
        this.awan_tinggi.arah[0] = 9;
        this.awan_tinggi.cloud_2 = false;
      }
      this.checkValidate({ target: { value: this.input_data.cloud_cover_oktas_m } }, "cloud_cover_oktas_m");
      this.checkValidate({ target: { value: this.awan_tinggi.CH } }, "cloud_high_type_ch");
    },
    "awan_tinggi.NCH"() {
      this.checkValidate({ target: { value: this.input_data.cloud_cover_oktas_m } }, "cloud_cover_oktas_m");
      this.checkValidate({ target: { value: this.awan_tinggi.NCH } }, "cloud_high_cover_oktas");
    },
    "awan_tinggi.tinggi_dasar": {
      deep: true,
      handler: function (after, before) {
        if (after) {
          if (after[0] != null) {
            this.checkValidate({ target: { value: after[0] } }, "cloud_high_base_1");
          }
          if (after[1] != null) {
            this.checkValidate({ target: { value: after[1] } }, "cloud_high_base_2");
          }
        }
      },
    },
    rainfall_indicator_ir() {
      this.checkValidate({ target: { value: this.input_data.present_weather_ww } }, "present_weather_ww");
      this.checkValidate({ target: { value: this.input_data.past_weather_w1 } }, "past_weather_w1");
      this.checkValidate({ target: { value: this.input_data.past_weather_w2 } }, "past_weather_w2");
    },
    tanggal(value) {
      this.fetchData();
    },
    jam(value) {
      this.fetchData();
    },
    "input_data.pressure_temp_c": function (value) {
      this.hitung_QFF_QFE();
    },
    "input_data.pressure_reading_mb": function (value) {
      this.hitung_QFF_QFE();
    },
    // AN : Every change need to re-preview
    "input_data": {
      deep: true,
      handler: function (after, before) {
        this.donePreview = false;
      }
    },
    "awan_rendah": {
      deep: true,
      handler: function (after, before) {
        this.donePreview = false;
      }
    },
    "awan_menengah": {
      deep: true,
      handler: function (after, before) {
        this.donePreview = false;
      }
    },
    "awan_tinggi": {
      deep: true,
      handler: function (after, before) {
        this.donePreview = false;
      }
    },
  },
};
</script>

<style>
.rangecheck>* {
  border-color: rgb(248, 228, 72) !important;
  background-color: rgb(237, 252, 109) !important;
}

.stepcheck>* {
  border-color: rgb(249, 227, 60) !important;
  background-color: rgb(236, 252, 88) !important;
}

.std, .std > .ant-select-selection {
  height: 36px !important;
}

.std > #input-datepicker__value_.form-control-sm {
  padding: 0.3rem 0.857rem !important
}
</style>

<style scoped lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";

.rangecheck {
  border-color: rgb(248, 228, 72) !important;
  background-color: rgb(237, 252, 109) !important;
}

.stepcheck {
  border-color: rgb(249, 227, 60) !important;
  background-color: rgb(236, 252, 88) !important;
}

.pad2 {
  padding-left: 2px;
  padding-right: 2px;
}

.pad3 {
  padding-left: 12px;
  padding-right: 2px;
}

.pad4 {
  padding-right: 0px;
}

.header-padding {
  padding: 1rem 2.25rem !important;
}

.add-margin-bottom {
  margin-bottom: 10px;
}

.add-font-size {
  font-size: smaller;
}

.wrap {
  overflow: hidden;
  width: 100%;
  flex-direction: row;
  white-space: nowrap;
  display: flex;
  overflow-x: auto;
}

.text-vertical {
  writing-mode: vertical-lr;
  -webkit-writing-mode: vertical-lr;
  -ms-writing-mode: vertical-lr;
  transform: rotate(180deg);
}

#table-preview td.text-sandi {
  text-align: left !important;
  font-size: 0.9rem !important;
  padding: 1rem !important;
  color: #000000 !important;
  line-height: 0 !important;
  font-weight: 700;
}

#table-preview th,
#table-preview td {
  font-size: 0.75rem !important;
  padding: 0.15rem;
  min-width: 6rem;
  text-align: center;
  vertical-align: middle;
  color: #000000;
  border: solid 1px #000000;
}

.table-preview-label td {
  background-color: #e0e4e7 !important;
}

#table-preview td>label {
  font-size: 0.75rem !important;
  padding: 0;
  margin: 0.1rem;
}

</style>